<template>
  <vs-select
      placeholder="Products"
      v-model="product"
      :label="label"
      filter
      :multiple="multiple"
      id="products"
  >
    <vs-option label="All" value="alls">
      All
    </vs-option>
    <vs-option v-for="(p, index) in products" :key="index" :value="p.product ? p.product: p.value" :label="p.product ? p.product : p.value">
      {{ p.text }}
    </vs-option>
  </vs-select>
</template>

<script>
import fsg from "@/api/base";
export default {
  name: "products",
  data: () => ({
    products: [],
    product: ''
  }),
  props: {
    label: String,
    multiple: Boolean
  },
  created() {
    fsg.get("/products?scope=all").then(resp => {this.products = resp.data.message})
  },
  mounted() {
  },
  watch: {
    product: function (){
      this.$emit('change', this.product)
    }
  },
}
</script>

<style scoped>

</style>