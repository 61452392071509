<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 100px;background-size: cover; background-position: center top; background-color: #2dce89">
    </base-header>
    <b-container fluid class="mt--5">
      <b-row>
        <b-col xl="3">
          <vs-input
              primary
              v-model="block"
              placeholder="Block tag" />
        </b-col>
        <b-col xl="3">
          <vs-input
              success
              v-model="remove"
              placeholder="Remove tag" />

        </b-col>
        <b-col xl="3">
          <vs-input
              danger
              v-model="author"
              placeholder="Block author" />

        </b-col>
        <b-col xl="3">
          <vs-input
              v-model="image_id"
              placeholder="Block image" />

        </b-col>
      </b-row>
      <div class="bt-container">
        <vs-button
            success
            gradient
            @click="keyword_add"
        >
          Add
        </vs-button>
        <vs-button
            danger
            gradient
            @click="keyword_remove"
        >
          Remove
        </vs-button>
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th>
                Author
              </vs-th>
              <vs-th>
                Remove
              </vs-th>
              <vs-th>
                Block
              </vs-th>
              <vs-th>
                Image ID
              </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr
              :key="i"
              v-for="(tr, i) in keywords"
              :data="tr"
            >
              <vs-td>
                {{ tr[0] }}
              </vs-td>
              <vs-td>
               {{ tr[1] }}
              </vs-td>
              <vs-td>
               {{ tr[2] }}
              </vs-td>
              <vs-td>
                {{ tr[3] }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

      </div>
    </b-container>
  </div>
</template>

<script>
import fsg from "@/api/base";
export default {
  name: "Keyword",
  data: () => ({
    keywords: [],
    type: '',
    block: '',
    remove: '',
    author: '',
    image_id: '',
  }),
  methods: {
    keyword_add: function (){
      fsg.post(`/keywords?type=${this.type}`, {
        'block': this.block,
        'remove': this.remove,
        'author': this.author,
        'image_id': this.image_id
      }).then(r => this.$vs.notification({
        title: 'updated',
        color: 'success',
      }))
    },
    keyword_remove: function (){
      fsg.put(`/keywords?type=${this.type}`, {
        'block': this.block,
        'remove': this.remove,
        'author': this.author,
        'image_id': this.image_id
      }).then(r => this.$vs.notification({
        title: 'updated',
        color: 'success'
      }))
    },
  },
  created() {
    const loading = this.$vs.loading({
      target: this.$refs.orders,
      color: '#d5397b'})
    fsg.get('/keywords')
      .then(r => {this.keywords = r.data.msg, loading.close()})
      .error(r => loading.close())
  }
}
</script>

<style scoped>
.bt-container{
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>