<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 400px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top; background-color: #2dce89">
      <b-row>
        <b-col v-if="scopes.includes(role)" xl="6" @click="addSeller()">
          <stats-card title="Controlls"
                      type="gradient-red"
                      sub-title="Add sellers"
                      icon="bx bx-user"
                      class="mb-4">

          </stats-card>
          <add-seller-form :show="popup_seller"></add-seller-form>
        </b-col>
        <b-col v-if="scopes.includes(role)" xl="3" @click="addProfile">
          <stats-card title="Profile"
                      type="gradient-red"
                      sub-title="Add profile here"
                      icon="bx bx-file"
                      class="mb-4">

          </stats-card>
          <profile :show="popup_profile"></profile>
        </b-col>
        <b-col v-if="scopes.includes(role)" xl="3" @click="motp">
          <stats-card title=""
                      type="gradient-red"
                      sub-title="MOTP"
                      icon="bx bx-file"
                      class="mb-4">

          </stats-card>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--7">
      <b-row>
        <b-col xl="12" class="order-xl-1">
          <div>
            <b-card header-class="fixed-table">
              <slot name="header">
                <b-row>
                  <b-col xl="2">
                    <vs-select
                      placeholder="Filter project"
                      v-model="projects"
                      state="dark"
                      class="con-selects"
                    >
                      <vs-option label="HTV" value="htv">
                        HTV
                      </vs-option>
                      <vs-option label="FSG" value="fsg">
                        FSG
                      </vs-option>
                      <vs-option label="JSC" value="jsc">
                        JSC
                      </vs-option>
                    </vs-select>
                  </b-col>
                  <b-col xl="2">
                    <vs-select
                        placeholder="Issue"
                        v-model="issue"
                        state="dark"
                        class="con-selects"
                    >
                      <vs-option label="Report high" value="intellectual_property">
                        Report high
                      </vs-option>
                      <vs-option label="No sale" value="no_sale">
                        No sale
                      </vs-option>
                      <vs-option label="Credit card invalid" value="credit_card_invalid">
                        Credit card invalid
                      </vs-option>
                      <vs-option label="Negative Balance" value="negative_balance">
                        Balance Down
                      </vs-option>
                      <vs-option label="At risk" value="is_risk">
                        At risk
                      </vs-option>
                      <vs-option label="Buyer messages" value="buyer_messages">
                        Buyer messages
                      </vs-option>
                      <vs-option label="Order defect rate" value="order_defect_rate">
                        Order defect rate
                      </vs-option>
                      <vs-option label="Late shipment rate" value="late_shipment_rate">
                        Late shipment rate
                      </vs-option>
                      <vs-option label="Pre fulfillment" value="pre_fulfillment">
                        Pre fulfillment
                      </vs-option>
                      <vs-option label="Valid tracking rate" value="valid_tracking_rate">
                        Valid tracking rate
                      </vs-option>
                    </vs-select>
                  </b-col>
                  <b-col xl="2">
                    <vs-button
                      success
                      flat
                      :active="region == 'NA'"
                      @click="region = 'NA', status = 'active'"
                      style="width: 100%"
                    >
                      United state
                    </vs-button>
                  </b-col>
                  <b-col xl="2">
                    <vs-button
                        primary
                        flat
                        :active="region == 'EU'"
                        @click="region = 'EU', status = 'active'"
                        style="width: 100%"
                    >
                      Euro
                    </vs-button>
                  </b-col>
                  <b-col xl="2">
                    <vs-button
                        danger
                        flat
                        :active="status == 'closed'"
                        @click="region = '', status = 'closed'"
                        style="width: 100%"
                    >
                      Deactive
                    </vs-button>
                  </b-col>
                  <b-col xl="2">
                    <vs-button
                        warn
                        flat
                        :active="warn == true"
                        @click="region = '', warn = true, status = 'active'"
                        style="width: 100%"
                    >
                      Warning
                    </vs-button>
                  </b-col>
                </b-row>
              </slot>
              <hr>
              <seller-table :region="region" :status="status" :projects="projects" :warn="warn" :issue="issue"></seller-table>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import SellerTable from "@/views/Pages/Seller/SellerTable";
import AddSellerForm from "@/views/Pages/Seller/AddSellerForm";
import Uploads from "@/components/Uploads";
import Profile from "@/views/Pages/Seller/Profile";
import {get_role} from "@/util";
export default {
  name: "Seller",
  components: {
    SellerTable,
    AddSellerForm,
    Uploads,
    Profile,
  },
  methods: {
    addSeller: function (){
      this.popup_seller = !this.popup_seller
    },
    motp: function (){
      this.popup_motp = !this.popup_motp
    },
    addProfile: function (){
      this.popup_profile = !this.popup_profile
    },
    uploadSku: function (){
      this.popup_file = !this.popup_file
    }
  },
  props: {
  },
  data: () => ({
    issue: '',
    popup_seller: false,
    popup_profile: false,
    popup_motp: false,
    popup_file: false,
    status: 'active',
    warn: false,
    region: 'NA',
    projects: ['htv'],
    dropzoneOptions: {
          url: 'https://httpbin.org/post',
          thumbnailWidth: 150,
          maxFilesize: 30,
          headers: { "My-Awesome-Header": "header value" }
      },
    scopes: ['admin', 'mod'],
    role: get_role()
  })
}
</script>

<style scoped lang="scss">
.fixed-table {
  position: sticky;
  top: 0;
}

</style>