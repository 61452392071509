<template>
  <div>
    <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
         style="min-height: 500px; background-size: cover; background-position: center top;">
      <b-container fluid>
        <!-- Mask -->
        <span class="mask bg-gradient-success opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center">
          <b-row >
            <b-col lg="7" md="10">
              <h1 class="display-2 text-white">Hello {{ user }}</h1>
              <p class="text-white mt-0 mb-5">This is keywords manage. You can find any old keywords or make tag for products</p>
            </b-col>
            <b-col lg="7" md="10">
              <vs-checkbox v-model="renew">
                Renew
              </vs-checkbox>
            </b-col>
            <b-col xl="12">
              <b-row>
                <b-col xl="10">
                  <base-input v-model="keyword"></base-input>
                </b-col>
                <b-col xl="2">
                  <b-col>
                    <base-button @click="make_tag" v-model="keyword">Make tag</base-button>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <vs-dialog v-model="active">
            <template #header>
              <h4 @click="doCopy({'value': seller})" class="not-margin">
                Tag for <b>{{ keyword }}</b>
              </h4>
            </template>


            <div>
              <h5 v-clipboard:copy="result.value">{{ result.value }}</h5>
            </div>
          </vs-dialog>
        </b-container>
      </b-container>
    </div>
    <b-container fluid class="justify-content-center mt--7">
      <b-card>
        <b-row>
          <b-col xl="12" lg="7" md="10">
            <vs-table>
              <template #header>
                <vs-input v-model="search" border placeholder="Search" />
              </template>
              <template #thead>
                <vs-tr>
                  <vs-th>
                    Keyword
                  </vs-th>
                  <vs-th>
                    Tags
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                    :key="i"
                    v-for="(tr, i) in $vs.getSearch(keywords, search)"
                >
                  <vs-td style="width: 30%">
                    {{ tr.key }}
                  </vs-td>
                  <vs-td @click="doCopy(tr)">
                    {{ tr.value }}
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <vs-pagination v-model="page" :length="$vs.getLength(keywords, max)" />
              </template>
            </vs-table>
          </b-col>
        </b-row>

      </b-card>
    </b-container>
  </div>
</template>

<script>
import fsg from "@/api/base";
import VueClipboard from 'vue-clipboard2'
export default {
  name: "Helium",
  data: () => ({
    'user': localStorage.getItem('name'),
    keyword: '',
    renew: true,
    keywords: '',
    result: Object,
    active: false,
    page: 1,
    max: 100,
    search: ''
  }),
  methods: {
    make_tag: function (){
      const loading = this.$vs.loading()
      fsg.get(`/helium?keyword=${this.keyword}&renew=${this.renew}`)
        .then(r => {
          this.result = r.data.message
          loading.close()
          this.active = !this.active
          this.keywords.unshift(this.result)
        })
    },
    doCopy: function (tr){
      this.$copyText(tr.value)
      this.$vs.notification({
        title: "Copied",
        color: "success"
      })
    }
  },
  mounted() {
    fsg.get("/helium?scope=all")
      .then(r => {
        this.keywords = r.data.message
      })
  }
}
</script>

<style scoped>

</style>