<template>
  <card>
    <b-row align-v="center" slot="header" >
      <b-col cols="8">
        <h3 class="mb-0">Edit profile </h3>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-button @click="updateProfile" type="submit">Update</b-button>
      </b-col>
    </b-row>

    <b-form>
      <h6 class="heading-small text-muted mb-4">User information</h6>

      <div class="pl-lg-4">
        <b-row>
        </b-row>
        <b-row >
          <b-col lg="6">
            <base-input
              type="text"
              label="Full name"
              placeholder="Full name"
              v-model="user.name"
            >
            </base-input>
          </b-col>
          <b-col lg="6">
            <base-input label="Birth day">
              <flat-picker slot-scope="{focus, blur}"
                          @on-open="focus"
                          @on-close="blur"
                          :config="{allowInput: true}"
                          class="form-control datepicker"
                          v-model="user.birthday">
              </flat-picker>
            </base-input>
          </b-col>
        </b-row>
      </div>
      <hr class="my-4">

      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">Contact information</h6>

      <div class="pl-lg-4">
        <b-row>
          <b-col md="12">
            <base-input
              type="text"
              label="Address"
              placeholder="Home Address"
              v-model="user.address"
            >
            </base-input>
          </b-col>
          <b-col md="12">
            <base-input
              type="text"
              label="Relationship"
              placeholder="Relationship"
              v-model="user.relationship"
            >
            </base-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <base-input
              type="text"
              label="University"
              placeholder="University"
              v-model="user.university"
            >
            </base-input>
          </b-col>
          <b-col lg="4">
            <base-input
              label="Phone"
              placeholder="Phone"
              v-model="user.phone"
            >
            </base-input>
          </b-col>
          <b-col lg="4">
            <base-input
              label="Country"
              placeholder="Country"
              v-model="user.country"
            >
            </base-input>
          </b-col>
        </b-row>
      </div>

      <hr class="my-4">
      <!-- Description -->
      <h6 class="heading-small text-muted mb-4">About me</h6>
      <div class="pl-lg-4">
            <base-input
              placeholder="About me"
              v-model="user.about_me"
            >
            </base-input>
      </div>

    </b-form>
  </card>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import fsg from "@/api/base";
import { locale } from 'd3';
export default {
  components: {
    flatPicker
  },
  data() {
    return {
      user: {
      },
    };
  },
  methods: {
    updateProfile() {
      alert(this.user.about_me)
      fsg.put(`/userinfo/${localStorage.getItem("id")}`, this.user)
        .then(r => {
          this.$vs.notification({
            title: "updated",
            color: "success"
          })
        })
    }
  },
  created() {
    fsg.get(`/userinfo/${localStorage.getItem("id")}`)
      .then(r => {
        this.user = r.data
      })
  }
};
</script>
<style></style>
