<template>
  <div>
    <div>
      <b-container fluid class="fixed-header">
        <vs-dialog prevent-close v-model="modal.add_order" title="Dialog Form">
          <h3>Add order</h3>
          <b-container>
            <b-row class="justify-content-center">
              <b-col xl="12" lg="5" md="1">
                <b-card no-body class="bg-secondary border-0 mb-0">
                  <b-form>
                    <b-row>
                      <b-col cols="4"
                            v-for="field in ['AmazonOrderId', 'Zip', 'StateOrRegion']"
                            :key="field">
                        <base-input alternative
                                    class="mb-3"
                                    :name="field"
                                    :rules="{required: true}"
                                    :placeholder="field"
                                    v-model="new_order[field]">
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4"
                            v-for="field in ['seller_id', 'Phone', 'CountryCode']"
                            :key="field">
                        <base-input alternative
                                    class="mb-3"
                                    :name="field"
                                    :rules="{required: true}"
                                    :placeholder="field"
                                    v-model="new_order[field]">
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4"
                            v-for="field in ['Name', 'City', 'AddressLine1']"
                            :key="field">
                        <base-input alternative
                                    class="mb-3"
                                    :name="field"
                                    :rules="{required: true}"
                                    :placeholder="field"
                                    v-model="new_order[field]">
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4"
                            v-for="field in ['AddressLine2', 'sku', 'quantity']"
                            :key="field">
                        <base-input alternative
                                    class="mb-3"
                                    :name="field"
                                    :rules="{required: true}"
                                    :placeholder="field"
                                    v-model="new_order[field]">
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4"
                            v-for="field in ['asin', 'type', 'item_id']"
                            :key="field">
                        <base-input alternative
                                    class="mb-3"
                                    :name="field"
                                    :rules="{required: true}"
                                    :placeholder="field"
                                    v-model="new_order[field]">
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4"
                            v-for="field in ['total', 'options']"
                            :key="field">
                        <base-input alternative
                                    class="mb-3"
                                    :name="field"
                                    :rules="{required: true}"
                                    :placeholder="field"
                                    v-model="new_order[field]">
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col xl="6">
                        <vs-button @click="addOrder" transparent>
                          SUBMIT
                        </vs-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card>
              </b-col>
            </b-row>
          </b-container>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload">
          <b-container fluid="">
            <h3 align="center">Upload design</h3>
            <products label="Select product" @change="update_dropzone" class="flex-item"></products>
            <div v-if="allow_dropzone">
              <p style="color: darkred">Chú ý upload tối đa 1 lượt 20 file, file dưới 50Mb</p>
              <hr size="3">
              <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>

        <vs-dialog not-center width="600px" v-model="update_tracking">
          <b-container fluid="">
            <h3 align="center">Update order manually</h3>
            <div>
              <p style="color: darkred">Column 1: Order fulfill id, Column2: Tracking</p>
              <hr size="3">
              <vue2Dropzone class="upload" ref="myVueDropzone" id="duplicate" :options="duplicate"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>

        <vs-dialog not-center width="600px" v-model="order_import">
          <b-container fluid="">
            <h3 align="center">Update order manually</h3>
            <div>
              <p style="color: darkred">Report order txt</p>
              <hr size="3">
              <vue2Dropzone class="upload" ref="myVueDropzone" id="order_import" :options="AmazonImport"></vue2Dropzone>
              <hr size="3">
              <vue2Dropzone class="upload" ref="myVueDropzone" id="order_import" :options="orderImport"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>

        <vs-dialog not-center width="600px" v-model="vertify_product">
          <b-container fluid="">
            <h3 align="center">Confirm product</h3>
            <h1>Did you just choose the <strong class="a-red">{{ this.selected_product }}</strong></h1>
            <vs-button @click="allow_dropzone = !allow_dropzone, vertify_product = !vertify_product">Oke</vs-button>
          </b-container>
        </vs-dialog>
        <b-card>
          <slot name="header">
            <div class="flex-container" v-if="scopes.includes(role)">
              <div id="MarketPlace" class="con-switch flex-item">
                <vs-select
                    color="danger"
                    v-model="marketplace"
                >
                  <vs-option label="NA" value="NA">
                    NA
                  </vs-option>
                  <vs-option label="EU" value="EU">
                    EU
                  </vs-option>
                  <vs-option label="UK" value="UK">
                    UK
                  </vs-option>
                </vs-select>
              </div>
              <products @change="select_product" class="flex-item"></products>
              <vs-select
                  color="warn"
                  v-model="printer"
              >
                <vs-option label="Auto" value="auto">
                  Auto
                </vs-option>
                <vs-option label="Printify" value="printify">
                  Printify
                </vs-option>
                <vs-option label="Scalable" value="scalable">
                  Scalable
                </vs-option>
                <vs-option label="Customcat" value="customcat">
                  Customcat
                </vs-option>
                <vs-option label="Merchize" value="merchize">
                  Merchize
                </vs-option>
              </vs-select>
              <vs-select placeholder="Employee" v-model="seleted_employee">
                <vs-option v-for="(e, idx) in employees" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                  {{ e.name }}
                </vs-option>
              </vs-select>
              <div class="flex-item-right">
                <vs-button
                    danger
                    active
                    border
                    @click="modal.add_order = true"
                    class="flex-item"
                >
                  Add Order
                </vs-button>
                <vs-button
                    danger
                    active
                    border
                    @click="active = true"
                    class="flex-item"
                >
                  Place
                </vs-button>
                <vs-button
                    danger
                    active
                    border
                    @click="upload = true"
                    class="flex-item"
                >
                  Upload design
                </vs-button>
                <vs-button
                    warn
                    active
                    border
                    @click="update_tracking = true"
                    class="flex-item"
                >
                  Update tracking
                </vs-button>
                <vs-button
                    warn
                    active
                    border
                    @click="order_import = true"
                    class="flex-item"
                >
                  Import Orders
                </vs-button>
                <vs-button
                    success
                    active
                    border
                    @click="export_ship_info"
                    class="flex-item"
                >
                  Export 11.3 x 17
                </vs-button>
                <vs-button
                    success
                    active
                    border
                    @click="buy_shipping"
                    class="flex-item"
                >
                  Buy shipping
                </vs-button>
                <vs-button
                    success
                    active
                    border
                    @click="export_image"
                    class="flex-item"
                >
                  Export image
                </vs-button>
                <vs-button
                    active
                    border
                    @click="export_black"
                    class="flex-item"
                >
                  Export black
                </vs-button>
                <vs-button
                    active
                    success
                    border
                    @click="export_gearment"
                    class="flex-item"
                >
                  Export Gearment
                </vs-button>
                <!-- <vs-button
                    active
                    border
                    @click="export_gearment"
                    class="flex-item"
                >
                  Export normal
                </vs-button> -->
                <vs-button
                    active
                    border
                    @click="process_design"
                    class="flex-item"
                >
                  Process design
                </vs-button>
                <vs-button
                    active
                    border
                    @click="process_design"
                    class="flex-item"
                    success
                >
                  Processed
                </vs-button>
              </div>
            </div>
            <hr>
            <vs-dialog width="300px" not-center v-model="active">
            <template #header>
              <h4 class="not-margin">
                <b>Place confirm</b>
              </h4>
            </template>


            <div class="con-content">
              <h5 v-if="selected.length > 0">
                Continue place order <b style="color: red">{{ selected.length }}</b> orders with printer <b style="color: red">{{ printer }}</b>
              </h5>
              <h5 v-else>
                No orders have been selected yet
              </h5>
            </div>

            <template #footer>
              <div class="con-footer">
                <vs-button @click="place_orders" transparent>
                  Ok
                </vs-button>
                <vs-button @click="active = false" dark transparent>
                  Cancel
                </vs-button>
              </div>
            </template>
          </vs-dialog>
          </slot>
          <div align="center" class="place-table" ref="orders">
            <vs-table>
              <template #header class="table-header">
                <vs-input v-model="search" border placeholder="Search" />
              </template>
              <template #thead class="table-header">
                <vs-tr>
                  <vs-th class="vs-td-basic">
                    Date
                  </vs-th>
                  <vs-th>
                    AmazonOrderID
                  </vs-th>
                  <vs-th>
                    Seller
                  </vs-th>
                  <vs-th>
                    Author
                  </vs-th>
                  <vs-th>
                    Sku
                  </vs-th>
                  <vs-th>
                    Product
                  </vs-th>
                  <vs-th>
                    Asin
                  </vs-th>
                  <vs-th>
                    Type
                  </vs-th>
                  <vs-th class="vs-td-sx">
                    Designer
                  </vs-th>
                  <vs-th class="vs-td-sx">
                    Options
                  </vs-th>
                  <vs-th sort @click="orders = $vs.sortData($event ,orders, 'note')">
                    Note
                  </vs-th>
                  <vs-th class="vs-td-sx">
                    Quantity
                  </vs-th>
                  <vs-th class="vs-td-sx">
                    Total
                  </vs-th>
                  <vs-th>
                    <vs-checkbox
                      :indeterminate="selected.length == orders.length" v-model="allCheck"
                      @change="select_all"
                    />
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                  :key="i"
                  v-for="(o, i) in $vs.getSearch(orders, search)"
                  :data="o"
                >
                  <vs-td class="vs-td-basic">
                    <h5>{{ o.date }}</h5>
                  </vs-td>
                  <vs-td @click="doCopy(o.AmazonOrderId)" class="vs-td-nm-fixed" :class="{'background-red': o.ShipmentServiceLevelCategory == 'Expedited', 'background-warn': o.many_option_in_sku == true}">
                    <h5 :class="{'background-red': o.AddressLine1 == null}" @click="AmazonOrderId = o.AmazonOrderId, edit = o, editProp = 'AmazonOrderId', editActive = true">{{ o.AmazonOrderId }}</h5>
                  </vs-td>
                  <vs-td :class="{'background-green': o.is_hijack == true}" @click="doCopy(o.seller_id)">
                    <h5>{{ o.seller_name }}</h5>
                  </vs-td>
                  <vs-td >
                    <h5>{{ o.author }}</h5>
                  </vs-td>
                  <vs-td @click="doCopy(o.sku)">
                    <h5 @click="upload = true">{{ o.sku }}</h5>
                  </vs-td>
                  <vs-td class="vs-td-nm-fixed">
<!--                    <vs-card type="2">-->
<!--                      <template #img>-->
                        <div v-if="o.MarketplaceId == 'ATVPDKIKX0DER'">
                          <a :href="`https://www.amazon.com/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto" :src="o.secondary_url" alt="None"></a>
                        </div>
                        <div v-else>
                          <a :href="o.original_url" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
                        </div>
<!--                      </template>-->
<!--                    </vs-card>-->
                  </vs-td>
                  <vs-td class="vs-td-nm-fixed no-radius">
                    <div>
                      <router-link  target="_blank" :to="{path: 'designs', query: {sku: o.sku, AmazonOrderID: o.AmazonOrderId}}">
                        <img style="width: 100px; height: auto" :src="o.thumbnail" alt="None">
                      </router-link>
                    </div>
                  </vs-td>
                  <vs-td class="vs-td-sx">
                    <div class="row-content">
                      <h4>{{ o.type }}</h4>
                      <p>{{ o.is_oke }}</p>
                      <p>{{ o.size }}</p>
                    </div>
                  </vs-td>
                  <vs-td edit @click="edit = o, editProp = 'employee', editActive = true" class="vs-td-sx">
                    {{ o.employee }}
                  </vs-td>
                  <vs-td class="vs-td-min">
                    <div>
                      <p v-for="(opt, id_opt) in o.options" :key="id_opt">
                        {{ opt }}
                      </p>
                    </div>
                  </vs-td>
                  <vs-td edit @click="edit = o, editProp = 'note', editActive = true">
                    <h5>
                      {{ o.note }}
                    </h5>
                  </vs-td>
                  <vs-td class="vs-td-sx">
                    {{ o.quantity }}
                  </vs-td>
                  <vs-td class="vs-td-sx">
                    {{ o.total }}
                  </vs-td>
                  <vs-td>
                    <vs-checkbox :val="o" v-model="selected" />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-dialog not-center scroll width="600px" v-model="editActive">
              <template #header>
                  Change Prop {{ editProp }}
              </template>
              <vs-input @keypress.enter="update_note" v-if="editProp == 'note' && scopes.includes(role)" v-model="edit[editProp]" />
              <vs-input @keypress.enter="editActive = false" v-if="editProp == 'option' && scopes.includes(role)" v-model="edit[editProp]" />
              <vs-select @change="editActive = false, employee = edit.employee" block v-if="editProp == 'employee' && scopes.includes(role)" placeholder="Select" v-model="edit[editProp]">
                <vs-option v-for="(e, idx) in employees" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                  {{ e.name }}
                </vs-option>
              </vs-select>
              <div v-if="editProp == 'AmazonOrderId' && scopes.includes(role)" class="con-content">
                <b-container class="address-dialog">
                  <b-row class="justify-content-center">
                    <b-col class="fix-col" xl="12" lg="5" md="1">
                      <b-card no-body class="bg-secondary border-0 mb-1">
                        <vs-select placeholder="Select" v-model="detail.Status">
                          <vs-option label="Unshipped" value="Unshipped">
                            Unshipped
                          </vs-option>
                          <vs-option label="Processed" value="Processed">
                            Processed
                          </vs-option>
                          <vs-option label="error" value="error">
                            error
                          </vs-option>
                          <vs-option label="buy_shipping" value="buy_shipping">
                            Buy shipping
                          </vs-option>
                          <vs-option label="in_queue" value="in_queue">
                            in_queue
                          </vs-option>
                          <vs-option label="manually" value="manually">
                            manually
                          </vs-option>
                          <vs-option label="cancel" value="cancel">
                            cancel
                          </vs-option>
                          <vs-option label="deactive" value="deactive">
                            deactive
                          </vs-option>
                        </vs-select>
                        <hr>
                        <vs-select placeholder="Printer" v-model="detail.Printer">
                          <vs-option label="Printify" value="printify">
                            Printify
                          </vs-option>
                          <vs-option label="Customcat" value="customcat">
                            Customcat
                          </vs-option>
                          <vs-option label="Scalable" value="scalable">
                            Scalable
                          </vs-option>
                          <vs-option label="None" value="">
                            None
                          </vs-option>
                        </vs-select>
                        <base-input alternative
                                    name="Name"
                                    v-model="detail.Name"
                                    label="Name"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.order_fulfill_id"
                                    label="Order fulfill id"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.Tracking"
                                    label="Tracking"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.AddressLine1"
                                    label="Address1"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.AddressLine2"
                                    label="Address2"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.City"
                                    label="City"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.StateOrRegion"
                                    label="State Or Region"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.Phone"
                                    label="Phone"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.Zip"
                                    label="Zip code"
                        >
                        </base-input>
                        <base-input alternative
                                    v-model="detail.CountryCode"
                                    label="Country code"
                        >
                        </base-input>
                        <base-button @click="update_order">Update</base-button>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </vs-dialog>
          </div>
        </b-card>
      </b-container>
    </div>

  </div>
</template>

<script>
import fsg from "@/api/base";
import {BaseSelect} from "@/components";
import {BaseButton} from "@/components";
import Products from "@/components/Products";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
import XLSX from 'xlsx';

export default {
  name: "Place",
  components: {
    BaseSelect,
    BaseButton,
    Products,
    vue2Dropzone,
  },
  data: () => ({
    modal: {
      add_order: false
    },
    new_order: {
        AmazonOrderId: '',
        Zip: '',
        StateOrRegion: '',
        seller_id: '',
        Phone: '',
        CountryCode: '',
        Name: '',
        City: '',
        AddressLine1: '',
        AddressLine2: '',
        sku: '',
        quantity: '',
        asin: '',
        type: '',
        item_id: '',
        options: ''
      },
    prints: [],
    marketplace: 'NA',
    update_tracking: false,
    order_import: false,
    vertify_product: false,
    search: '',
    selected_product: '',
    product: '',
    printer: 'auto',
    selected: [],
    allCheck: [],
    alls: [],
    orders: [],
    editActive: false,
    edit: {},
    employees: [],
    editProp: null,
    AmazonOrderId: "",
    detail: Object,
    role: 'admin',
    scopes: ['admin', 'mod', 'designer'],
    active: false,
    employee: null,
    note: null,
    upload: false,
    seleted_employee: '',
    allow_dropzone: false,
    temp: [],
    preview: false,
    dropzoneOptions: {
      url: `https://api.fsggroup.io/designs`,
      thumbnailWidth: 150,
      maxFilesize: 50,
      acceptedFiles: 'image/*',
      headers: {
        "fsg-token": localStorage.getItem("fsg-token"),
        "Access-Control-Allow-Origin": "*",
      },
      dictCancelUpload: false,
      dictUploadCanceled: false,
      dictInvalidFileType: false,
      maxFiles: 20,
    },
    duplicate: {
      url: 'https://api.fsggroup.io/update_tracking',
      // url: 'http://0.0.0.0:5001/update_tracking',
      thumbnailWidth: 150,
      maxFilesize: 30,
      acceptedFiles: '*',
      headers: {
        "fsg-token": localStorage.getItem("fsg-token"),
        // "type": "sku-asin",
        "Access-Control-Allow-Origin": "*",
      },
      dictCancelUpload: false,
      dictUploadCanceled: false,
      dictInvalidFileType: false,
      maxFiles: 1,
      dictDefaultMessage: 'Update tracking'
    },
    AmazonImport: {
      url: 'https://api.fsggroup.io/amazon_orders',
      thumbnailWidth: 150,
      maxFilesize: 30,
      acceptedFiles: '*',
      headers: {
        "fsg-token": localStorage.getItem("fsg-token"),
        // "type": "sku-asin",
        "Access-Control-Allow-Origin": "*",
      },
      dictCancelUpload: false,
      dictUploadCanceled: false,
      dictInvalidFileType: false,
      maxFiles: 1000,
      dictDefaultMessage: 'Import Amazon Orders'
    },
    orderImport: {
      url: 'https://api.fsggroup.io/import_orders',
      // url: 'http://0.0.0.0:5001/import_orders',
      thumbnailWidth: 150,
      maxFilesize: 30,
      acceptedFiles: '*',
      headers: {
        "fsg-token": localStorage.getItem("fsg-token"),
        // "type": "sku-asin",
        "Access-Control-Allow-Origin": "*",
      },
      dictCancelUpload: false,
      dictUploadCanceled: false,
      dictInvalidFileType: false,
      maxFiles: 1000,
      dictDefaultMessage: 'Import Orders'
    },
  }),
  created() {
    this.sockets.subscribe('new_order', (orders) => {
      this.orders= this.orders.concat(orders)
      // orders.forEach((o) => {
      //   this.$vs.notification({
      //     title: `New order ${o.AmazonOrderId} - ${o.seller_name}`,
      //     color: 'success',
      //     duration: 10000,
      //     progress: 'auto',
      //   })
      // })
    })
    const loading = this.$vs.loading({
      target: this.$refs.orders,
      color: '#d5397b'})
    fsg.get(`/place?marketplace=${this.marketplace}`)
      .then(r => {
        this.alls = r.data.msg, console.log(this.orders)
        // if(!['admin', 'mod'].includes(this.role)){
        //   this.seleted_employee = localStorage.getItem('name')
        // }else{
        //   this.orders = this.alls.filter(e => e.artwork_error == false)
        // }
          this.orders = this.alls.filter(e => e.artwork_error == false)
        loading.close()
      })
      .catch(error => loading.close())
    // fsg.get('/employees?role=designer')
    //   .then(r => {this.employees = r.data.message})
    fsg.get('/employees?team=all')
      .then(r => {this.employees = r.data.message})
  },
  methods: {
    addOrder() {
      let missingKeys = [];

      Object.keys(this.new_order).forEach((key) => {
        if (key !== 'options' && key !== 'type' && !this.new_order[key] && key !== 'AddressLine2') {
          missingKeys.push(key);
        }
      });

      if (missingKeys.length > 0) {
        this.$vs.notification({title: `Missing keys: ${missingKeys.join(', ')}`});
      }
      fsg.post('/add_order', this.new_order)
      .then(r => {this.$vs.notification({title: r.data.msg})})
    },
    process_design (){
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o)
      })
      if (order_ids.length == 0){
        order_ids = this.orders.filter(o => o.url == null && o.type == null)
      }
      fsg.post('/ideas?process_design=True', order_ids)
          .then(r => {
              this.$vs.notification({
                  title: "Succes",
                  color: "success"
              })
          })
          .catch(error => {
              this.$vs.notification({
                  title: "Something went wront!",
                  color: "danger"
              })
              this.downloading = false
          })
    },
    export_ship_info: function(val){
      this.orders = this.alls.filter(e => e.artwork_error === true)
      var ids = []
      this.orders.forEach(e => ids.push(e.AmazonOrderId))
      fsg.post('/get_113_17', {'orders': ids})
        .then(r => {
          const data = XLSX.utils.aoa_to_sheet(r.data.orders)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, 'Sheet')
          XLSX.writeFile(wb, `113 x 17.xlsx`)
      })
    },
    download_image: function(o) {
        fetch(o.url)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                if (o.options.includes('Matte Poster')) {
                  a.download = o.AmazonOrderId + '_x' + o.quantity + '_matte' + '.jpg';
                } else if (o.options.includes('Gloss Poster')){
                  a.download = o.AmazonOrderId + '_x' + o.quantity + '_gloss' + '.jpg';
                }
                if (o.options.length == 0) {
                  a.download = o.AmazonOrderId + '_x' + o.quantity + '_matte' + '.jpg';
                }
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    },
    export_black: function () {
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o.AmazonOrderId)
      })

      fsg.post('/export_black', {'orders': order_ids})
        .then(r => {this.prints = r.data.orders, this.download()})
    },
    export_gearment: function () {
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o.AmazonOrderId)
      })

      fsg.post('/export_gearment', {'orders': order_ids})
        .then(r => {this.prints = r.data.orders, this.download()})
    },
    export_normal: function () {
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o.AmazonOrderId)
      })

      fsg.post('/export_normal', {'orders': order_ids})
        .then(r => {this.prints = r.data.orders, this.download()})
    },
    download: function () {
        const data = XLSX.utils.aoa_to_sheet(this.prints)
        const wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, data, 'Sheet')
        XLSX.writeFile(wb, `prints.xlsx`)
    },
    export_image: function () {
      this.orders = this.alls.filter(e => e.artwork_error == true)
      this.orders.forEach(o => this.download_image(o))
    },
    select_product: function (val){
      this.product = val
      if(this.product == "alls"){
        this.orders = this.alls
      }else{
        this.orders = this.alls.filter(o => o.type == val && o.artwork_error == false)

      }
    },
    update_note: function (){
      fsg.put(`/orders/${this.edit.AmazonOrderId}`, {'note': this.edit.note, 'sku': this.edit.sku})
          .then(r => this.$vs.notification({
            title: "Updated"
          }))
      this.editActive = false
    },
    buy_shipping: function (){
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o.AmazonOrderId)
      })
    this.selected = []
      fsg.post('/orders/buy_shipping', {order_ids: order_ids})
        .then(r => {
          this.$vs.notification({
          title: 'Orders in queue',
          color: 'success'
        })
        location.reload()
        }
      )
    },
    place_orders: function (){
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o.AmazonOrderId)
        console.log(o.AmazonOrderId)
        this.orders.splice(this.orders.findIndex(order => order.AmazonOrderId == o), 1)
        this.alls.splice(this.alls.findIndex(order => order.AmazonOrderId == o), 1)
      })
      this.selected = []
      fsg.post('/orders/place', {order_ids: order_ids, printer: this.printer})
        .then(r => {
          this.$vs.notification({
          title: 'Orders in queue',
          color: 'success'
        })
        location.reload()
        }
      )
      this.active = false
    },
    select_all: function (){
      var temp = this.$vs.getSearch(this.orders, this.search)
      if (this.product){
        temp.filter(o => o.type == this.product && o.artwork_error == false)
      }
      this.selected = this.$vs.checkAll(this.selected, temp)
    },
    update_order: function (){
      fsg.put(`/orders/${this.AmazonOrderId}`, this.detail)
        .then(r => this.$vs.notification({
          title: "Updated"
        }))
    },
    doCopy: function (val){
      this.$copyText(val)
      this.$vs.notification({
        title: `Copied ${val}`,
        color: "success",
      })
    },
    update_dropzone: function (val){
      this.vertify_product = !this.vertify_product
      this.selected_product = val
      this.dropzoneOptions.url = `https://api.fsggroup.io/designs?type=${val}`
      // this.allow_dropzone = true
    }
  },
  mounted() {
    this.role = localStorage.getItem('role')
  },
  beforeDestroy() {
    this.sockets.unsubscribe('new_order')
  },
  watch: {
    AmazonOrderId: function () {
      fsg.get(`/orders/${this.AmazonOrderId}`)
          .then(r => {
            this.detail = r.data.msg
          })
    },
    marketplace: function (){
      fsg.get(`/place?marketplace=${this.marketplace}`)
          .then(r => {this.orders = r.data.msg, this.alls = this.orders})
    },
    employee: function (){
      if (this.editProp == "employee"){
        var AmazonOrderId_params = ""
        if (this.selected.length){
          this.selected.forEach(e => AmazonOrderId_params = AmazonOrderId_params + `&AmazonOrderId=${e.AmazonOrderId}`)
          this.selected = []
        }else {
          AmazonOrderId_params = `&AmazonOrderId=${this.edit.AmazonOrderId}`
        }
        fsg.post(`/assignment/${this.edit.sku}?employee=${this.edit.employee}` + AmazonOrderId_params)
          .then(r => this.$vs.notification({
            title: `Assignment success`,
            color: 'success'
          }))
      }
    },
    upload: function (){
      if(this.upload == false){
        this.allow_dropzone = false
      }
    },
    seleted_employee: function (){
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o.AmazonOrderId)
      })
      if (order_ids.length == 0) {
        if (this.seleted_employee == "all"){
          if(this.product){
            this.orders = this.alls.filter(o => o.type == this.product && o.artwork_error == false)
          }else{
            this.orders = this.alls
          }
        }else{
          var AmazonOrderId_params = ""
          this.selected.forEach(e => AmazonOrderId_params = AmazonOrderId_params + `&AmazonOrderId=${e.AmazonOrderId}`)
          fsg.post(`/assign_orders?employee=${this.selected_employee}` + AmazonOrderId_params)
            .then(r => this.$vs.notification({
              title: `Assignment success`,
              color: 'success'
            }))
        }
      }
    },
    preview: function (){
      $('#img-zoom').ezPlus({
        zoomWindowFadeIn: 500,
        zoomWindowFadeOut: 500,
        lensFadeIn: 500,
        lensFadeOut: 500
      })
    }
  },
}

</script>

<style scope lang="scss">
.con-switch{
  display: flex;
  align-items: center;
  justify-content: left;
}
.vs-select__options__content {
    max-height: 400px !important;
    overflow: auto;
    height: auto;
    z-index: 100;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    position: relative;
    scroll-behavior: smooth;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
}
.vs-table__tr .vs-table__td:last-child {
  border-right: none;
}
.vs-table__td:first-child {
    width: 5%;
    border-radius: 15px 0px 0px 15px;
}
.vs-table__td:last-child {
  width: 4%;
  border-radius: 15px 0px 0px 15px;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
  width: 100%;
  margin-left: 0px;
}
.fix-col {
  padding-right: -15px;
  margin-right: 0px;
}
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.place-table {
  overflow: scroll;
  padding-bottom: 400px;
  height: 1000px;
}
.vs-card__img img {
    width: auto;
    max-width: 100%;
    border-radius: 20px;
    display: block;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    height:150px;
}
.no-radius {
  border-radius: 0px !important;
  background-image: url('https://fsg-products.s3-ap-southeast-1.amazonaws.com/png-transparent-background-1-1.png') !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  background-color: transparent !important;
}
.vs-checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
    outline: none;
    list-style: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.vs-checkbox-con {
  width: 50px;
  height: 50px;
  border-radius: 9px;
  position: relative;
  z-index: 1;
  border-color: #FFE53B;
}
</style>
