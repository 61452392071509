<template>
  <div>
    <b-card>
      <b-container fluid>
          <div class="imgs-container">
            <div class='img-flex' v-for="index in indexs" :key="index" v-if="index>=2">
              <vs-card type="6" v-if="crawlData[index]">
                <template #title>
                  <!-- <Title 
                    :title_text="crawlData[index].name" 
                  >
                  </Title> -->
                  <vs-input v-model="crawlData[index].name" />
                </template>
                <template #img>
                  <a :href="crawlData[index].main_image_url" target="_blank">
                    <img :src="crawlData[index].main_image_url" alt="">
                  </a>
                </template>
                <template #text>
                  <a target="_blank" :href="crawlData[index].product_url">
                    In redbubble
                  </a>
                  <vs-checkbox
                    v-model="crawlData[index]['checked']"
                  />
                </template>
                <template #interactions>
                  <vs-button
                    icon
                    color="danger"
                    @click="saveEtsy(index)"
                  >
                    <i class='bx bxs-heart'></i>
                  </vs-button>
                  <!-- <vs-button
                    @click="removeProduct(index)"
                    icon
                    color="warn"
                  >
                    <i class='bx bxs-trash'></i>
                  </vs-button> -->
                </template>
              </vs-card>
            </div>
          </div>
      </b-container>
    </b-card>
  </div>

</template>

<script>
import Title from './Pages/Redbubble/Title.vue';
import fsg from "@/api/base";
export default {
  components: {
    Title,
  },
  name: "ImageView",
  data() {
    return {
      images: [1, 2, 3],
      workds: []
    }
  },
  props: {
    crawlData: {
      type: Array,
    },
    main_url_key: String,
    title_key: String,
    item_sku_key: String,
    indexs: Array,
    product: String,
    keep_all: Boolean,
    token: String,
  },
  methods: {
    saveEtsy: function (index){
      if (this.crawlData[index].selected){
          this.$vs.notification({
            title: "Saved before",
            color: "success"
          })
          return
      }
      var p = this.crawlData[index]
      fsg.post(`/save_etsy?product=${this.product}&keep_all=${this.keep_all}`, {asins: [p], token: this.token})
        .then(r => {
          this.$vs.notification({
            title: "Saved product",
            color: "success"
          })
        })
    },
    saveProduct: function (index){
      if (this.crawlData[index].selected){
          this.$vs.notification({
            title: "Saved before",
            color: "success"
          })
          return
      }
      var p = this.crawlData[index]
      fsg.post(`/save_product?product=${this.product}&keep_all=${this.keep_all}`, {asins: [p], token: this.token})
        .then(r => {
          this.$vs.notification({
            title: "Saved product",
            color: "success"
          })
        })
    },
    removeProduct: function (index) {
      this.crawlData.splice(index, 1)
      this.$vs.notification({
        title: 'Removed product',
        color: "success"
      })
    },
    doCopy: function (val){
      this.$copyText(val)
      this.$vs.notification({
        title: `Copied ${val}`,
        color: "success",
      })
    },
  },
  created: function (){
  }
}
</script>
<style scoped>
.imgs-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.vs-card__img img{
  height: auto !important;
}
.img-flex {
  padding: 20px;
}
</style>