<template>
    <div>
        <vs-dialog width="1000px" v-model="modal.product_attribute">
            <div class="variation-container">
                <div>
                    <div v-for="(attribute, index) in product_attributes" :key="index">
                        <div class="variation-row">
                            <vs-input
                                v-model="attribute.name"
                                placeholder="Attribute name"
                            />
                            <vs-input
                                v-model="attribute.value"
                                placeholder="Attribute value"
                            />

                        </div>
                    </div>

                </div>
                <vs-button @click="addAttribute">Add</vs-button>
                <vs-button @click="saveAttributes">Save</vs-button>
            </div>
        </vs-dialog>
        <vs-dialog width="1200" v-model="modal.promotion" class="variation-row">
            <div style="width: 100%">
                <div>
                    <vs-select
                        placeholder="Select Product"
                        v-model="promotion_type"
                    >
                        <vs-option label="Flash sale" value="flash_sale">
                            Flash sale
                        </vs-option>
                    </vs-select>
                    <br>
                    <vs-select placeholder="Select" v-model="etsy_selected" multiple>
                        <vs-option v-for="s in seller_etsy" :label="s.name" :value="s.seller_id" :key="s.seller_id">
                            {{ s.name }}
                        </vs-option>
                    </vs-select>
                    <br>
                    <div class="variation-container">
                        <div style="margin-bottom: 10px;">
                            <vs-input
                            v-model="flash_sale.flash_name"
                            placeholder="Flash name"
                            class="input-name"
                            />
                        </div>
                        <div class="variation-row">
                            <vs-input
                            v-model="flash_sale.product_ids"
                            placeholder="Product IDs"
                            class="input-name"
                            />

                        </div>
                        <div class="variation-row">
                            <vs-input
                            v-model="flash_sale.discount"
                            placeholder="Discount %"
                            class="input-name"
                            />
                            <vs-input
                            v-model="flash_sale.limit"
                            placeholder="Limit"
                            class="input-name"
                            />
                            <vs-input
                            v-model="flash_sale.num_products"
                            placeholder="Num products"
                            class="input-name"
                            />

                        </div>
                        <br>
                    </div>
                    <br>

                </div>
                <div class="variation-row">
                    <vs-button
                        @click="setup_flash_sale"
                        color="success"
                        icon
                        :loading="exporting"
                    >
                        Flash sale now
                    </vs-button>
                </div>

            </div>
        </vs-dialog>
        <vs-dialog width="1000px" v-model="modal.new_seller">
            <div class="variation-container">
                <div style="margin: 10px;">
                    <vs-input
                    v-model="new_seller.name"
                    placeholder="Enter seller name"
                    class="input-name"
                    />
                    <br>
                    <vs-input
                    v-model="new_seller.profile_id"
                    placeholder="Enter profile_id"
                    class="input-name"
                    />

                </div>
                <vs-button @click="add_seller" danger>
                    <i class='bx bxs-save'>Save</i>
                </vs-button>
                <vs-button @click="close_seller" danger>
                    <i class='bx bxs-close'>Close</i>
                </vs-button>

            </div>
        </vs-dialog>
        <vs-dialog width="1000px" v-model="selectedMain">
            <vs-card-group>
                <vs-card v-for="(img, index) in edit.mockups" :key="index" type="">
                    <template #title>
                        <h3>Image {{ index }}</h3>
                    </template>
                    <template #img>
                        <img class="img-mockup" :src="img" alt="">
                    </template>
                    <template #text>
                    </template>
                    <template #interactions>
                        <vs-button @click="save_main(img)" danger>
                            <i class='bx bxs-save'></i>
                        </vs-button>
                        <vs-button @click="delete_image(img)" danger>
                            <i class='bx bx-trash-alt' ></i>
                        </vs-button>
                    </template>
                </vs-card>
            </vs-card-group>
        </vs-dialog>
        <vs-dialog width="1200" v-model="modal.config_tiktok" class="variation-row">
            <div style="width: 100%">
                <div>
                    <vs-select
                        placeholder="Select Product"
                        v-model="product_type"
                    >
                        <vs-option label="Mug" value="mug">
                            Mug
                        </vs-option>
                        <vs-option label="Poster" value="poster">
                            Poster
                        </vs-option>
                        <vs-option label="Phone Case" value="phone_case">
                            Phone case
                        </vs-option>
                        <vs-option label="Ugly sweater" value="ugly_sweater">
                            Ugly sweater
                        </vs-option>
                        <vs-option label="Sweatshirt" value="sweatshirt">
                            Sweatshirt
                        </vs-option>
                        <vs-option label="Tshirt" value="tshirt">
                            Tshirt
                        </vs-option>
                        <vs-option label="Hoodie" value="hoodie">
                            Hoodie
                        </vs-option>
                        <vs-option label="Tumbler" value="tumbler">
                            Tumbler
                        </vs-option>
                    </vs-select>
                    <vs-button color="danger" @click="addContainer">Create variation</vs-button>
                    <br>
                    <h3>Add variation theo thứ tự Loại áo -> Size -> Color</h3>
                    <v-row>
                        <b-col>
                            <div class="variation-container">
                                <div style="margin-bottom: 10px;">
                                    <vs-input
                                    v-model="product_data.draft_url"
                                    placeholder="Enter draft url"
                                    class="input-name"
                                    />

                                </div>
                                <div class="variation-row">
                                    <vs-input
                                    v-model="product_data.brand"
                                    placeholder="Enter brand"
                                    class="input-name"
                                    />

                                </div>
                                <div class="variation-row">
                                    <vs-input
                                    v-model="product_data.weight"
                                    placeholder="Shipping weight"
                                    class="input-name"
                                    />
                                    <vs-input
                                    v-model="product_data.height"
                                    placeholder="Shipping height"
                                    class="input-name"
                                    />
                                    <vs-input
                                    v-model="product_data.width"
                                    placeholder="Shipping width"
                                    class="input-name"
                                    />
                                    <vs-input
                                    v-model="product_data.length"
                                    placeholder="Shipping length"
                                    class="input-name"
                                    />

                                </div>
                                <br>
                                <div class="variation-row">
                                    <vs-input
                                    v-model="product_data.price"
                                    placeholder="Genral price(if not variation)"
                                    class="input-name"
                                    />

                                </div>

                            </div>
                        </b-col>
                    </v-row>
                    <br>

                </div>
                <div class="container-row limit-height">
                    <div class="limit-height">
                        <div v-for="(container, index) in variation_containers" :key="index">
                            <div class="variation-container" :key="container.length">
                                <vs-input
                                v-model="container.type"
                                placeholder="Size or Color or other name"
                                class="input-name"
                                />
                                <br>
                                <div v-for="(variation, index) in container.variations" :key="index" class="variation-row">
                                    <vs-input
                                    v-model="variation.name"
                                    placeholder="Enter variant name"
                                    class="input-name"
                                    />
                                    <!-- <vs-input
                                    v-model="variation.price"
                                    class="input-price"
                                    placeholder="Enter variant price"
                                    type="number"
                                    /> -->
                                    <vs-button @click="removeVariationName(container, index)">Remove</vs-button>
                                </div>

                                <!-- Add Variation Button -->
                                <div class="variation-row">
                                    <vs-button @click="addVariation(container)">Add variation</vs-button>
                                    <vs-button @click="saveVariations()">Save</vs-button>
                                    <vs-button color="danger" @click="removeVariation(index)">Delete</vs-button>
                                </div>

                            </div>

                    </div>
                    </div>
                    <div class="variation-container limit-height" v-if="combined_variation_containers.length > 0">
                        <div v-for="(variation, index) in combined_variation_containers" :key="index">
                            <div class="variation-row">
                                <strong>{{ variation.combined.join(' - ') }}</strong>
                                <br>
                                <vs-input
                                    v-model="combined_variation_containers[index].price"
                                    placeholder="Enter price"
                                    class="input-name"
                                    />
                            </div>

                        </div>
                        <div>
                            <vs-button @click="save_combined">Done</vs-button>
                        </div>
                    </div>
                    <br>

                </div>
                <div class="variation-row">
                    <vs-button
                        @click="modal.product_attribute = true"
                        color="success"
                        icon
                    >
                        PRODUCT ATTRIBUTES
                    </vs-button>
                    <vs-button
                        @click="list_etsy"
                        color="success"
                        icon
                        :loading="exporting"
                    >
                        START LISTING
                    </vs-button>
                </div>

            </div>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="editActive">
            <template #header>
            <h4 class="not-margin">
                <b>Edit note</b>
            </h4>
            </template>
            <vs-input @keypress.enter="update_note" v-if="editProp == 'note'" v-model="edit_note" />
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="modal.tags">
            <template #header>
            <h4 class="not-margin">
                <b>Add tag</b>
                <base-input @keypress.enter="update_tags" v-model="edit.tags_text" />
            </h4>
            </template>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="viewContent">
            <div>
                {{ edit.title }}
            </div>
            <br>
            <div>
                {{ edit.Description }}
            </div>
            <br>
            <div>
                {{ edit.tag_text }}
            </div>
            <br>
            <!-- <div>
                {{ edit.bullet_point1 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point2 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point3 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point4 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point5 }}
            </div> -->
        </vs-dialog>
        <vs-dialog width="600px" v-model="editing">
            <h3>Edit</h3>
            <hr>
            <vs-input v-model="edit.title" :label-placeholder="`Title: ${edit.title.length}`">
                {{ edit.title }}
            </vs-input>
            <!-- <vs-input v-model="edit.title">
                {{ edit.title }}
            </vs-input> -->
            <hr>
            <vs-input v-model="edit.Description" label-placeholder="Description">
                {{ edit.Description }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.tags_text" label-placeholder="tags">
                {{ edit.tags_text }}
            </vs-input>
            <hr>
            <hr>
            <vs-button
                success
                gradient
                @click="update_product"
            >
                confirm
            </vs-button>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="delete_confirm">
            <template #header>
            <h4 class="not-margin">
                <b>Do you want delete it {{ edit.asin }}?</b>
            </h4>
            </template>

            <template #footer>
            <div>
                <vs-button @click="delete_asin" transparent>
                    Ok
                </vs-button>
                <vs-button @click="delete_confirm = false" dark transparent>
                    Cancel
                </vs-button>
            </div>
            </template>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload_design">
          <b-container fluid="">
            <h3 align="center">Upload mockups</h3>
            <b-row>
                <b-col>
                    <products label="Select product" @change="update_dropzone_design" class="flex-item"></products>
                </b-col>
            </b-row>
            <div v-if="allow_dropzone">
              <p style="color: darkred">Vứt ảnh vào đây</p>
              <hr size="3">
              <vue2Dropzone id="dropzone" :options="dropzonedesign"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload">
          <b-container fluid="">
            <h3 align="center">Upload mockups</h3>
            <b-row>
                <!-- <b-col>
                    <products label="Select product" @change="update_dropzone_mockups" class="flex-item"></products>
                </b-col> -->
                <!-- <b-col>
                    <vs-input v-model="sku" placeholder="Sku" />
                </b-col> -->
            </b-row>
            <div>
              <p style="color: darkred">Vứt ảnh vào đây</p>
              <hr size="3">
              <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload_content">
          <b-container fluid="">
            <h3 align="center">Upload content</h3>
            <vue2Dropzone id="dropzonecontent" :options="dropzonecontent"></vue2Dropzone>
          </b-container>
        </vs-dialog>
        <b-container fluid>
            <b-card>
                <b-row>
                    <b-col xl="1">
                        <vs-button
                            @click="upload_metadata"
                            color="danger"
                            icon
                        >
                           Upload content
                        </vs-button>
                    </b-col>
                    <b-col xl="1">
                        <vs-button
                            @click="download_skus"
                            color="warning"
                            icon
                        >
                           Download skus
                        </vs-button>
                    </b-col>
                    <b-col xl="1">
                        <vs-button
                            @click="delete_skus"
                            color="warning"
                            icon
                        >
                           DELETE
                        </vs-button>
                    </b-col>
                    <b-col xl="1">
                        <vs-button
                            @click="download_designs"
                            icon
                        >
                           Download designs
                        </vs-button>
                    </b-col>
                    <b-col xl="1">
                        <vs-button
                            warn
                            gradient
                            @click="upload_design = true"
                            icon
                        >
                            Uploads
                        </vs-button>
                    </b-col>
                    <b-col xl="2">
                        <vs-button
                            @click="upload_mockups"
                            color="success"
                            icon
                        >
                           Upload mockups
                        </vs-button>
                    </b-col>
                    <!-- <b-col xl="2">
                        <vs-select placeholder="Niche" v-model="niche">
                            <vs-option label="Redesign" value="redegisn">
                                Redesign
                            </vs-option>
                            <vs-option label="Hijack" value="hijack">
                                Hijack
                            </vs-option>
                        </vs-select>
                    </b-col> -->
                    <b-col xl="2">
                        <vs-select placeholder="Select" v-model="etsy_selected">
                            <vs-option v-for="s in seller_etsy" :label="s.name" :value="s.seller_id" :key="s.seller_id">
                                {{ s.name }}
                            </vs-option>
                        </vs-select>
                    </b-col>
                    <b-col xl="2">
                        <vs-select placeholder="Mockups" v-model="mockup_selected">
                            <vs-option value="manhblue1">
                                Mạnh 1
                            </vs-option>
                            <vs-option value="loc1">
                                Lộc 1
                            </vs-option>
                            <vs-option value="loc2">
                                Lokdenvau
                            </vs-option>
                            <vs-option value="dat1">
                                A Đạt 1
                            </vs-option>
                            <vs-option value="ChristmasLight">
                                Christmas Light
                            </vs-option>
                            <vs-option value="ChristmasDark">
                                Christmas Dark
                            </vs-option>
                            <vs-option value="PTFESOGO1">
                                Poster FESOGO 1
                            </vs-option>
                            <vs-option value="PTFESOGO2">
                                Poster FESOGO 2
                            </vs-option>
                            <vs-option value="PTFESOGO3">
                                Poster FESOGO 3
                            </vs-option>
                            <vs-option value="PTFESOGO4">
                                Poster FESOGO 4
                            </vs-option>
                        </vs-select>
                    </b-col>
                    <!-- <b-col>
                        <vs-select placeholder="Employee" v-model="selected_employee">
                            <vs-option value="all">
                                all
                            </vs-option>
                            <vs-option v-for="(e, idx) in employees" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                            {{ e.name }}
                            </vs-option>
                        </vs-select>

                    </b-col> -->
                    <!-- <b-col>
                        <vs-select placeholder="Select template" v-model="selected_template">
                            <vs-option label="Wall Art Wood" value="wallart_wood">
                                Wall Art Wood
                            </vs-option>
                            <vs-option label="Wall Art Wood" value="wallart_black">
                                Wall Art Black
                            </vs-option>
                            <vs-option value="mug_christmas">
                                Mug Christmas
                            </vs-option>
                            <vs-option label="Mug" value="mug">
                                Mug
                            </vs-option>
                            <vs-option label="Canvas" value="canvas">
                                Poster + Canvas Dọc
                            </vs-option>
                            <vs-option label="Canvas Ngang" value="canvas_horizontal">
                                Poster + Canvas Ngang
                            </vs-option>
                        </vs-select>
                    </b-col> -->
                    <b-col>
                        <vs-button
                            @click="modal.config_tiktok = true"
                            color="danger"
                            icon
                        >
                           Listing
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-button
                            @click="modal.promotion = true"
                            color="danger"
                            icon
                        >
                           Promotion
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-button
                            @click="gen_mockup_etsy"
                            color="danger"
                            icon
                        >
                           MakeMockup
                        </vs-button>
                    </b-col>
                    <b-col xl="2">
                        <vs-button
                            @click="make_sku"
                            color="danger"
                            icon
                        >
                           Random sku
                        </vs-button>
                    </b-col>
                    <b-col xl="2">
                        <vs-button
                            @click="modal.new_seller = true"
                            color="danger"
                            icon
                        >
                           Add seller
                        </vs-button>
                    </b-col>
                    <b-col xl="10">
                        <vs-input @keypress.enter="search_asin" v-model="search" placeholder="Search sku" />
                    </b-col>
                    <!-- <b-col>
                        <vs-input @keypress.enter="search_tags" v-model="tag_search" placeholder="Search by tags" />
                    </b-col> -->
                    <b-col>
                        <vs-button
                            @click="download_mockup"
                            color="danger"
                            icon
                            :loading="exporting"
                            v-if="exported"
                        >
                           Download mockup
                        </vs-button>
                    </b-col>
                    <hr>
                    <b-col xl="12">
                        <!-- <vs-pagination color="success" v-model="page" :length="Math.ceil(all.length/perpage)"/> -->
                        <h3 v-if="this.asins.filter(item => item.selected == true).length > 0">Selected: {{ this.asins.filter(item => item.selected == true).length }}</h3>
                        <vs-pagination color="success" v-model="page" :length="$vs.getLength(asins, perpage)"/>
                        <vs-table>
                            <template #thead>
                            <vs-tr>
                                <vs-th class="vs-td-basic">
                                    <b-form-checkbox
                                        v-model="check_all"
                                        @change="check_all_page"
                                        >
                                    </b-form-checkbox>
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Product
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Image
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Main mockup
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Nick
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Tags
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Note
                                </vs-th>
                                <vs-th class="vs-td-lg">
                                    Content
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                Employee
                                </vs-th>
                            </vs-tr>
                            </template>
                            <template #tbody>
                            <vs-tr
                                :key="i"
                                v-for="(tr, i) in $vs.getPage(asins, page, perpage)"
                                :data="tr"
                            >
                                <vs-td class="vs-td-basic">
                                    <b-form-checkbox
                                        v-model="tr.selected"
                                        >
                                    </b-form-checkbox>
                                </vs-td>
                                <vs-td class='vs-td-sx'>
                                    <strong>{{ tr.type }}</strong>
                                    <vs-button
                                        danger
                                        gradient
                                        @click="edit = tr, delete_confirm = true"
                                        icon
                                    >
                                        <i class='bx bx-trash-alt' ></i>
                                    </vs-button>
                                </vs-td>
                                <vs-td class='vs-td-sx'>
                                    <div>
                                        <router-link  target="_blank" :to="{path: 'designs', query: {sku: tr.asin}}">
                                            <img style="width: 100px; height: auto" :src="tr.url" alt="">
                                        </router-link>
                                        <a v-if="tr.is_amazon == true" :href="`https://www.amazon.com/gp/product/${tr.origin_asin}`" target="_blank">
                                            <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                        </a>
                                        <div v-else-if="tr.origin_url">
                                            <a :href="tr.origin_url" target="_blank">
                                                <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <a :href="tr.secondary_url" target="_blank">
                                                <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                            </a>
                                        </div>
                                    </div>
                                </vs-td>
                                <vs-td>
                                    <img @click="selectedMain=true; edit=tr" style="width: 100px; height: auto" :src="tr.mockups[0]" alt="">
                                </vs-td>
                                <vs-td>
                                    {{ tr.seller }}
                                </vs-td>
                                <vs-td @click="edit=tr, modal.tags = !modal.tags" class="vs-td-sx">
                                    <div v-for="(tag, index) in tr.tags.slice(0, 5)" :key="(tag, index)" style="margin: 10px 10px 10px 10px;">
                                        <span @click="doCopy(tag)" class="tags">{{ tag }}</span>
                                    </div>
                                </vs-td>
                                <vs-td edit @click="edit = tr, editActive = true, edit_note = edit.note, editProp = 'note'" class='vs-td-sx'>
                                    <strong @click="doCopy(tr.note)">{{ tr.note }}</strong>
                                </vs-td>
                                <vs-td class="vs-td-lg">
                                    <b-row>
                                        <b-col xl="11" :class="{'a-green': tr.Description}">
                                            <p @click="edit = tr, viewContent = !viewContent">
                                                {{ tr.title }}
                                            </p>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                warn
                                                gradient
                                                @click="editing = !editing, edit = tr"
                                                icon
                                            >
                                                <i class='bx bxs-edit-alt'></i>
                                            </vs-button>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                danger
                                                gradient
                                                @click="push_to_redesign(tr)"
                                                icon
                                            >
                                                <i class='bx bx-left-arrow-alt'></i>
                                            </vs-button>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                danger
                                                gradient
                                                @click="push_to_exported(tr)"
                                                icon
                                            >
                                                <i class='bx bx-right-arrow-alt'></i>
                                            </vs-button>
                                        </b-col>
                                    </b-row>
                                </vs-td>
                                <vs-td>
                                    <strong>{{ tr.seller }}</strong>
                                </vs-td>
                            </vs-tr>
                            </template>
                        </vs-table>
                    </b-col>
                <hr>
                </b-row>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import fsg from "@/api/base";
import { BASE_URL } from "@/api";
import Products from '../../../components/Products.vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
import XLSX from 'xlsx';
export default {
  name: "tiktoklisting",
  components: {
    Products,
    vue2Dropzone
  },
    data: () => ({
        modal: {
            tags: false,
            mockups: false,
            config_tiktok: false,
            new_seller: false,
            promotion: false,
            product_attribute: false
        },
        product_attributes: [],
        new_seller: {
            name: '',
            profile_id: '',
        },
        flash_sale: {
            flash_name: '',
            product_ids: '',
            discount: '',
            limit: '',
            profile_id: '',
            num_products: 5,
        },
        promotion_type: 'flash_sale',
        product_type: 'mug',
        variation_containers: JSON.parse(localStorage.getItem('mug')),
        product_data: {},
        combined_variation_containers: [],
        // variations: localStorage.getItem('tiktokconfig'),
        variation_type: '',
        perpage: 48,
        page: 1,
        slide: 0,
        selectedMain: false,
        selected_mockups: [],
        sku: '',
        tag_search: '',
        images: [],
        all: [],
        filter_product: 'poster',
        data: [],
        search: '',
        edit_note: '',
        product: '',
        exported: false,
        viewContent: false,
        random_sku: false,
        exporting: false,
        header: [],
        editing: false,
        etsy_selected: '',
        seller_etsy: [],
        selected_employee: '',
        selected_template: '',
        check_all: false,
        scopes: ['mod', 'admin'],
        upload_content: false,
        mockup_selected: '',
        asin: '',
        niche: '',
        employees: [],
        editActive: false,
        editProp: '',
        asins: [],
        ads: [],
        delete_confirm: false,
        edit: {title: ''},
        downloading: false,
        allow_dropzone: false,
        upload: false,
        upload_design: false,
        dropzonedesign: {
            url: `${BASE_URL}/designs?redesign=oke`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'image/*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: true,
            dictUploadCanceled: true,
            dictInvalidFileType: true,
            uploadMultiple: true,
            parallelUploads: 10000,
        },
        dropzoneOptions: {
            url: `${BASE_URL}/mockups`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'image/*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            uploadMultiple: true,
            parallelUploads: 10000,
        },
        dropzonecontent: {
            url: `${BASE_URL}/redesign/upload_content?platform=tiktok`,
            // url: `http://0.0.0.0:5001/redesign/upload_content`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'text/csv',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            maxFiles: 1,
        },

    }),
    methods: {
        addAttribute() {
            this.product_attributes.push({ name: '', value: '' });
        },
        saveAttributes() {
            // Filter out empty attributes
            let nonEmptyAttributes = this.product_attributes.filter(attribute => attribute.name !== '' && attribute.value !== '');

            localStorage.setItem('product_attributes' + this.product_type, JSON.stringify(nonEmptyAttributes));
            console.log(nonEmptyAttributes)
            this.$vs.notification({
                title: 'Saved attributes'
            })
        },
        loadAttributes() {
            var attributes = localStorage.getItem('product_attributes' + this.product_type);
            this.product_attributes = attributes ? JSON.parse(attributes) : []
        },
        add_seller(){
            fsg.post('/add_tiktok_seller', this.new_seller)
            .then(r => {
                this.$vs.notification({
                    title: r.data.msg
                })
            })
        },
        close_seller(){
            fsg.post('/update_sellers_tiktok', this.new_seller)
            .then(r => {
                this.$vs.notification({
                    title: r.data.msg
                })
            })
        },
        delete_template(){

        },
        check_all_page: function(){
            this.$vs.getPage(this.asins, this.page, this.perpage).forEach(e => {e.selected = !e.selected, console.log(e.selected)})
            this.check_all = false
            // alert('hihi')
        },
        zipVariations() {
            // Return an empty array if there are no variation containers.
            if (!this.variation_containers.length) return [];

            if (this.variation_containers.length == 1){
                combinedVariations  = []
                this.variation_containers[0].variations.forEach(v => combinedVariations.push({
                    'combined': [v.name],
                    'price': ''
                }))
                return combinedVariations
            }

            // Initialize with the first container's variations as starting combinations.
            let combinedVariations = this.variation_containers[0].variations.map(variation => ({
                'combined': [variation.name],
                'price': '' // Placeholder for price, assuming each variation can have a separate price.
            }));

            // Iterate over the rest of the variation_containers, starting from the second one.
            for (let i = 1; i < this.variation_containers.length - 1; i++) {
                const newCombinations = [];

                // For each existing combination...
                combinedVariations.forEach(combination => {
                    // ...combine it with each variation of the current container.
                    this.variation_containers[i].variations.forEach(variation => {
                        newCombinations.push({
                            'combined': [...combination.combined, variation.name],
                            'price': '' // Placeholder for price, update logic here if necessary.
                        });
                    });
                });

                // Update combinedVariations with the new combinations from this iteration.
                combinedVariations = newCombinations;
            }

            return combinedVariations;
        },
        delay: function(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        download_image: async function(url, fn) {
            const resp = await fetch(url);
            const blob = await resp.blob();
            const blobUrl = window.URL.createObjectURL(blob); // Đổi thành blobUrl để tránh trùng lặp
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blobUrl; // Sử dụng blobUrl ở đây
            a.download = fn;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(blobUrl); // Sử dụng blobUrl ở đây
            console.log(fn + " - " + blobUrl); // Cập nhật log tương ứng
            await this.delay(100);
        },

        download_designs: async function() {
            for (const asin of this.asins) {
                if(asin.selected && asin.design_url) {
                    await this.download_image(asin.design_url, asin.asin + '.png');
                }
            }
        },

        delete_skus (){
            var ideas = []
            this.asins.filter(e => {
                if(e.selected) {
                    ideas.push([e.asin])
                }
            })
            fsg.delete(`/redesign?asin=${ideas.join(',')}`)
                .then(r => {
                    this.$vs.notification({
                        title: 'Deleted',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Failed',
                        color: 'danger'
                    })

                })

        },
        delete_image(image) {
        fsg.post('/delete_mockup', {sku: this.edit.asin, url: image})
        .then(r => {
          this.$vs.notification({
            title: r.data.msg,
            color: 'success'
          })

          // Find the index of the image in this.edit.mockup_urls
          let index = this.edit.mockups.indexOf(image);

          // If the image is found, remove it
          if (index !== -1) {
            this.edit.mockups.splice(index, 1);
          }

          // setTimeout(function() {
          //     location.reload();
          // }, 1000);

        })
        },
        save_main(image) {
            fsg.post('/save_main_mockup', {sku: this.edit.asin, url: image})
            .then(r => {
                    this.$vs.notification({
                        title: r.data.msg,
                        color: 'success'
                    })
                    // setTimeout(function() {
                    //     location.reload();
                    // }, 1000);

            })
        },
        download_skus: function () {
            var ideas = [['sku', 'title', 'URL1', 'URL2', 'URL3', 'URL4', 'URL5', 'URL6', 'URL7', 'URL8', 'URL9', 'URL10']]
            this.asins.filter(e => {
                var temp = [e.asin, e.title]
                temp.push(...e.mockups)
                if(e.selected) {
                    ideas.push(temp)
                }
            })
            // var ideas = [['sku', 'URL1', 'URL2', 'URL3', 'URL4', 'URL5', 'URL6', 'URL7', 'URL8', 'URL9', 'URL10']]
            // this.asins.filter(e => {
            //     var temp = [e.asin]
            //     temp.push(...e.mockups)
            //     if(e.selected) {
            //         ideas.push(temp)
            //     }
            // })
            let content = ''
            ideas.forEach((e) => {
                content += e.join(",")
                content += "\n"
            })
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
            anchor.target = '_blank';
            anchor.download = 'result.csv';
            anchor.click();
        },
        make_sku: function () {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 8; i++ ) {
                result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
            }
            this.doCopy(result)
        },
        gen_mockup_etsy: function () {
            var ideas = []
            this.asins.filter(e => {
                if(e.selected) {
                    ideas.push(e.asin)
                }
            })
            fsg.post(`/sellers_tiktok?mockup=True`, {'skus': ideas, 'mockup_name': this.mockup_selected})
            .then(r => this.$vs.notification({
                        title: r.data.msg,
                        color: "success",
                        })
            )
        },
        setup_flash_sale: function () {
            this.flash_sale.profile_ids = this.etsy_selected
            fsg.post(`/flash_sale_tiktok?seller_id=${this.etsy_selected}`, this.flash_sale)
            .then(r => this.$vs.notification({
                title: r.data.msg,
                color: "success",

            }))
        },
        list_etsy: function () {
            // if (this.product_attributes.length == 0){
            //     this.$vs.notification({
            //         title: 'Please add product attributes'
            //     })
            //     return
            // }
            // if (this.product_type.length == 0) {
            //     this.$vs.notification({
            //         title: 'Select product type'
            //     })
            //     return
            // }
            var ideas = []
            this.asins.filter(e => {
                if(e.selected) {
                    ideas.push(e.asin)
                }
            })
            fsg.post(`/sellers_tiktok?listing=True&seller_id=${this.etsy_selected}`, {'skus': ideas, 'mockup_name': this.mockup_selected, 'tiktok_config': this.variation_containers, 'combined': this.combined_variation_containers, 'product_type': this.product_type, 'product_data': this.product_data, 'product_attributes': this.product_attributes})
            .then(r => this.$vs.notification({
                        title: r.data.msg,
                        color: "success",
                        })
            )
        },
        search_tags: function () {
            this.$vs.notification({
                'title': 'Searching tag',
                'color': 'warning'
            })
            fsg.get(`/redesign?exported=oke&tags=${this.tag_search}`)
                .then(r => { this.asins = r.data.msg; if (this.role == 'crawler'){this.asins = this.asins.filter(e => e.employee_id == this.employee_id)}})
        },
        update_tags: function () {
            this.modal.tags = false
            fsg.post("/product_tags", {'tags': this.edit.tags_text.split(","), 'sku': this.edit.asin})
                .then(r => {
                    this.$vs.notification({
                        title: "Updated",
                        color: "warning"
                    })
                    this.edit.tags = this.edit.tags_text.split(",")
                })
        },
        push_to_redesign: function(tr){
            fsg.put(`redesign?asin=${tr.asin}&push_to_redesign=true`)
                .then(r => {
                    this.$vs.notification({
                        title: "Pushed to redesign"
                    })
                })
        },
        push_to_exported: function(tr){
            fsg.put(`redesign?asin=${tr.asin}&push_to_exported=true`)
                .then(r => {
                    this.$vs.notification({
                        title: "Pushed to redesign"
                    })
                })
        },
        search_asin: function(){
            fsg.get(`redesign?asin=${this.search}`)
                .then(r => {this.asins = r.data.msg})
        },
        download_mockup: function() {
            this.exporting = true
            if (this.selected_template == 'ornamets'){
                let selected = this.asins.filter((as) => as.selected == true)
                var data = []
                selected.forEach(as => data.push(as.asin))
                fsg.post('/ornament_redesign?only_image=true', {'asins': data, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false, alert(`${this.images}`)})
            }else if (this.selected_template.includes('poster') || this.selected_template.includes('wallart')){
                fsg.post('/poster_redesign?only_image=true', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false})
            } else if (['mug', 'mug_christmas'].includes(this.selected_template)) {
                fsg.post('/mug_redesign?only_image=true', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false})
            } else if (['canvas', 'canvas_horizontal'].includes(this.selected_template)) {
                fsg.post('/canvas_redesign?only_image=true', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false})
            }
        },
        download: function (){
            if(this.selected_template == 'ornaments'){
                const data = XLSX.utils.aoa_to_sheet(this.data)
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()

                XLSX.utils.book_append_sheet(wb, data, 'Sheet')
                XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.writeFile(wb, `ornaments.xlsx`)
            }
            else if (this.selected_template.includes('poster') || this.selected_template.includes('wallart')) {
                const hello = XLSX.utils.json_to_sheet(this.data, { header: this.header })
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
                XLSX.writeFile(wb, 'poster.xlsx')
                this.data = null
            }
            else if (['canvas', 'canvas_horizontal'].includes(this.selected_template)){
                const hello = XLSX.utils.json_to_sheet(this.data, {header: this.header})
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()
                // XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
                XLSX.writeFile(wb, 'canvas.xlsx')
                this.data = null

            } else {
                const hello = XLSX.utils.json_to_sheet(this.data, {header: this.header})
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()
                // XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
                XLSX.writeFile(wb, 'mug.xlsx')
                this.data = null
            }
        },
        export_csv: function(){
            if (this.selected_template == 'ornamets'){
                let selected = this.asins.filter((as) => as.selected == true)
                var data = []
                selected.forEach(as => data.push(as.asin))
                fsg.post('/ornament_redesign', {'asins': data, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.exporting = false, this.ads = r.data.ads})
            }else if (this.selected_template.includes('poster') || this.selected_template.includes('wallart')){
                fsg.post('/poster_redesign', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false})
            } else if (this.selected_template.includes('mug')) {
                fsg.post('/mug_redesign', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false})
            } else if (['canvas', 'canvas_horizontal'].includes(this.selected_template)) {
                fsg.post('/canvas_redesign', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false})
            }
        },
        upload_metadata: function(){
            this.upload_content = !this.upload_content
        },
        upload_mockups: function (){
            this.upload = !this.upload
        },
        update_product: function(){
            fsg.put('/update_product', this.edit)
                .then(r => {
                    this.$vs.notification({
                        title: "updated",
                        color: "success"
                    })
                })
        },
        add_asin: function(){
            this.downloading = true
            fsg.post(`/redesign?asin=${this.asin}`)
                .then(r => {
                    this.asins.unshift.apply(this.asins, r.data.msg),
                    this.downloading = false
                    this.upload = false
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        update_dropzone_mockups: function (val){
            this.dropzoneOptions.url = `${BASE_URL}/mockups?type=${val}&sku=${this.sku}`
            this.allow_dropzone = !this.allow_dropzone
        },
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })
        },
        delete_asin: function(){
            fsg.delete(`/redesign?asin=${this.edit.asin}`)
                .then(r => {
                    this.delete_confirm = !this.delete_confirm
                    this.$vs.notification({
                        title: 'Deleted',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Failed',
                        color: 'danger'
                    })

                })
        },
        update_note: function(){
            this.edit.note = this.edit_note
            fsg.put(`/redesign?asin=${this.edit.asin}&note=${this.edit.note}`)
                .then(r => {
                    this.$vs.notification({
                        title: 'Note updated',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Note update failed',
                        color: 'success'
                    })
                })
        },
        update_dropzone_design: function (val){
            this.product = val
            this.dropzonedesign.url = `${BASE_URL}/designs?type=${val}&redesign=oke&platform=etsy`
            this.allow_dropzone = true
        },
        update_dropzone_design_url: function (){
            this.dropzonedesign.url = this.dropzonedesign.url + '&random_sku=true'
            this.allow_dropzone = true
        },
        addVariation(container) {
            // Push a new variation object into the variations array
            container.variations.push({ name: '', price: '' });
            // this.saveVariations()
        },
        addContainer() {
            // Push a new variation object into the variations array
            this.variation_containers.push({
                type: '',
                variations: [],
            });
            this.saveVariations()
        },
        loadVariations() {
            // Fetch variations from localStorage or set to an empty array if not found
            const tiktokConfig = localStorage.getItem(this.product_type);
            this.variation_containers = tiktokConfig ? JSON.parse(tiktokConfig) : [];
            this.combined_variation_containers = this.zipVariations()
            // this.loadCombined();
        },
        loadCombined() {
            // Fetch variations from localStorage or set to an empty array if not found
            const tiktokConfig = localStorage.getItem(this.product_type + "_combined");
            this.combined_variation_containers = tiktokConfig ? JSON.parse(tiktokConfig) : this.zipVariations();
        },
        saveVariations() {
            const variationsJSON = JSON.stringify(this.variation_containers);
            localStorage.setItem(this.product_type, variationsJSON);
            this.combined_variation_containers = this.zipVariations()
            this.save_combined()
        },
        save_combined(){
            const variationsJSON = JSON.stringify(this.combined_variation_containers);
            localStorage.setItem(this.product_type + "_combined", variationsJSON);
            this.$vs.notification({
                title: 'Saved'
            })
        },
        removeVariationName(container, index) {
            container.variations.splice(index, 1);
            this.saveVariations(); // Save the updated variations array to localStorage after removal
        },
        removeVariation(index) {
            this.variation_containers.splice(index, 1);
            this.saveVariations(); // Save the updated variations array to localStorage after removal
        },
    },
    created() {
        fsg.get("/products?scope=all").then(resp => {this.products = resp.data.message})
        fsg.get('/redesign?platform=tiktok')
            .then(r => { this.all = r.data.msg, this.asins = this.all, this.page = 1;  if(this.all.length < this.perpage){this.perpage = this.all.length}})
        fsg.get('/employees?team=content')
            .then(r => {this.employees = r.data.message})
        fsg.get('/sellers_tiktok')
            .then(r => {this.seller_etsy = r.data.data})

        this.loadVariations();
        this.loadCombined();
        this.loadAttributes()
    },
    watch: {
        product_type() {
            this.loadVariations()
            this.loadCombined()
            this.loadAttributes()
        },
        // page() {
        //     let cached = []
        //     const start = (this.page - 1) * this.perpage
        //     const end = this.page * this.perpage
        //     for (let i = start; i < end; i++) {
        //         cached.push(this.all[i])
        //     }
        //     this.asins = cached
        // },
        random_sku: function(){
            this.update_dropzone_design_url()
        },
        filter_product: function() {
            if (this.filter_product == 'poster'){
                this.asins = this.all.filter(e => e.is_wallart == false && e.type == 'poster')
            } else if (this.filter_product == 'mug') {
                this.asins = this.all.filter(e => e.type == 'mug')
            }
            else if (this.filter_product == 'wall_art') {
                this.asins = this.all.filter(e => e.is_wallart == true && e.type == 'poster')
            }
            else {
                this.asins = this.all.filter(e => e.type == this.filter_product)
            }
        },
        niche: function () {
            if (this.niche == 'hijack') {
                fsg.get(`/redesign?niche=${this.niche}`)
                    .then(r => {this.all = r.data.msg, this.asins = this.all})
            } else {
            fsg.get('/redesign?is_uploaded=oke')
                .then(r => {this.all = r.data.msg, this.asins = this.all.filter(e => e.type == this.filter_product)})
            }
        },
        data: function(){
            this.download()
        },
        images: function(){
            this.images.forEach(obj => this.download_image(obj.url, obj.name))
        },
        selected_employee: function(){
            if (this.selected_employee == 'all'){
                fsg.get('/redesign?exported=oke')
                    .then(r => {this.asins = r.data.msg})
            }
            let selected = this.asins.filter((e) => e.selected == true)
            let data = []
            selected.forEach(e => data.push(e.asin))
            if (!data.length == 0){
                fsg.put(`/redesign?action=update_employee&content=true`, {'asins': data, 'employee': this.selected_employee})
                    .then(r => {
                        this.$vs.notification({
                            title: 'Updated employee',
                            color: 'success'
                        })
                    })
            }else {
                this.asins = this.asins.filter((e) => e.employee == this.selected_employee)
            }
        },
        exported: function(){
            if (this.exported) {
                fsg.get('/redesign?exported=oke')
                    .then(r => {this.asins = r.data.msg})
            }else {
                fsg.get('/redesign?is_uploaded=oke')
                    .then(r => {this.asins = r.data.msg})
            }
        }
    }
}
</script>
<style scoped>

</style>
<style lang="scss" scope>
.tags {
    background-color: #ffd600;
    padding: 2px 5px 2px 5px;
    border-radius: 2px;
    box-shadow: #777;
    border: 1px;
    margin-block: 5px;
}
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
  align-content: center;
}
.vs-table__tr .vs-table__td:last-child {
  width: 5%;
  border-right: none;
}
.vs-table__td:first-child {
    border-radius: 15px 0px 0px 15px;
    width: 10%;
}
.vs-table__td:last-child {
  width: 5%;
  border-radius: 15px 0px 0px 15px;
}
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 500px;
  padding: 40px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.img-mockup {
    width: 300px;
    height: 400px;
}
.carousel {
  width: 1000;
  height: 1200;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
  display: none;
}

.slide:first-child {
  display: block;
}
.variation-row {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.container-row {
  display: flex;
  padding: 10px;
  margin: 10px;
  justify-content: space-between;
  align-items: top;
  height: 100%;
//   max-width: 600px;
}

.input-name, .input-price {
  margin-right: 12px;
}
.variation-container {
    margin-bottom: 10px;
    background-color: #cccccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    // width: 100%;
}
.limit-height {
    max-height: 600px;
    overflow-y: auto
}
</style>
