<template>
  <vs-dialog width="300px" not-center v-model="active">
    <template #header>
      <h4 class="not-margin" align="center">
        Add seller to <b>Project</b>
      </h4>
    </template>

    <div class="con-content">
      <b-container>
        <b-row>
          <b-col xl="12" lg="5" md="1">
            <b-card no-body class="bg-secondary border-0 mb-0">
              <b-form>
                <base-input alternative
                            class="mb-3"
                            name="Name"
                            :rules="{required: true}"
                            prepend-icon="ni ni-single-02"
                            placeholder="Name"
                            v-model="seller_name">
                </base-input>

                <base-input alternative
                            class="mb-3"
                            name="Seller email"
                            :rules="{required: true}"
                            prepend-icon="ni NI-EMAIL-83"
                            PLACEHOLDER="Email"
                            v-model="email">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="Seller password"
                            :rules="{required: true}"
                            prepend-icon="ni ni-lock-circle-open"
                            PLACEHOLDER="Password"
                            v-model="password">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="SECRET OTP"
                            :rules="{required: true}"
                            prepend-icon="ni ni-key-25"
                            placeholder="SECRET OTP"
                            v-model="secret_otp">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="Seller id"
                            :rules="{required: true}"
                            prepend-icon="ni ni-lock-circle-open"
                            PLACEHOLDER="Seller ID"
                            v-model="seller_id">
                </base-input>

                <base-input alternative
                            class="mb-3"
                            name="URL"
                            :rules="{required: true}"
                            prepend-icon="ni ni-world-2"
                            placeholder="URL"
                            v-model="health_url">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="LWA APP ID"
                            :rules="{required: true}"
                            prepend-icon="ni ni-key-25"
                            placeholder="LWA APP ID"
                            v-model="lwa_app_id">
                </base-input>

                <base-input alternative
                            class="mb-3"
                            name="LWA CLIENT SECRET"
                            :rules="{required: true}"
                            prepend-icon="ni ni-key-25"
                            placeholder="LWA CLIENT SECRET"
                            v-model="lwa_client_secret">
                </base-input>

                <base-input alternative
                            class="mb-3"
                            name="REFRESH TOKEN"
                            :rules="{required: true}"
                            prepend-icon="ni ni-key-25"
                            placeholder="REFRESH TOKEN"
                            v-model="refresh_token">
                </base-input>
                <b-row>
                  <b-col xl="6">
                    <projects @change="selectedProject"></projects>
                  </b-col>
                  <b-col xl="3">
                    <vs-button @click="addSeller" transparent>
                      Oke
                    </vs-button>
                  </b-col>
                  <b-col xl="3">
                    <vs-button @click="active = false" transparent>
                      Cancel
                    </vs-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </vs-dialog>
</template>

<script>
import Card from "@/components/Cards/Card";
import Products from "@/components/Products";
import Projects from "@/components/Projects";
import fsg from "@/api/base";
export default {
  name: "add-seller-form",
  components: {
    Card,
    Products,
    Projects
  },
  data: () => ({
    email: '',
    password: '',
    seller_name: '',
    seller_id: '',
    lwa_app_id: '',
    refresh_token: '',
    lwa_client_secret: '',
    health_url: '',
    secret_otp: '',
    active: false,
    product: 'poster',
    project: '',
  }),
  props: {
    show: Boolean,
  },
  watch: {
    show: function (){
      this.active = true
    }
  },
  methods: {
    selectedProject: function (val){
      this.project = val
    },
    addSeller: function (){
      const body = {
        "seller_name": this.seller_name,
        "seller_id": this.seller_id,
        "lwa_app_id": this.lwa_app_id,
        "lwa_client_secret": this.lwa_client_secret,
        "refresh_token": this.refresh_token,
        "health_url": this.health_url,
        "secret_otp": this.secret_otp,
        "project": this.project,
        "email": this.email,
        "password": this.password
      }
      fsg.post("/sellers", body)
        .then(resp => {
          this.$vs.notification({
            title: "Add seller to database",
            text: resp.data.message,
            color: "success"
          })
        })
      .catch(err =>{
        console.log(err.response.data.message)
        this.$vs.notification({
          title: "Add seller to database",
          text: err.response.data.message,
          color: 'danger',
        })
      })
    }
  },
}
</script>

<style scoped>


</style>