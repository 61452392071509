<template>
<div>
  <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 50px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top; background-color: #2dce89">
    <h1>Nicks</h1>
  </base-header>

  <b-container fluid class="mt--7">
    <b-row>
      <b-col xl="4" class="order-xl-1">
        <b-card class="fixed-list-ideas">
          <b-list-group>
            <b-list-group-item v-for="(nk, index) in nicks" :key=index class="d-flex justify-content-between align-items-center">
              <a data-toggle="tooltip" :title="nk.name" :href="`#/fsg-ideas/${nk.id}`">
                <vs-tooltip>
                  <h5 class="truncate">{{ nk.name }}</h5>
                  <template #tooltip>
                    <div>
                      {{ nk.name }}
                    </div>
                  </template>
                </vs-tooltip>
              </a>
              <b-badge pill>
                <div>
                  <b-dropdown size="sm" id="actions" class="m-1">
                    <template v-slot:button-content>
                      {{ nk.total_ideas }}
                    </template>
                    <b-dropdown-item @click="nick = nk">
                      <i class='bx bxs-edit text-green' ></i>
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove_nick(index)">
                      <i class='bx bx-trash text-green' ></i>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col xl="8" class="order-xl-1">
        <b-card>
          <b-form>
            <h6 class="heading-small text-muted mb-4">Add nick</h6>

            <div class="pl-lg-4">
              <b-row>
                <b-col lg="12">
                  <base-input
                    type="text"
                    label="Name"
                    v-model="nick.name"
                  >
                  </base-input>
                </b-col>
                <b-col lg="12">
                  <base-input
                    type="text"
                    label="Title"
                    v-model="nick.title"
                  >
                  </base-input>
                </b-col>
                <b-col lg="12">
                  <b-form-textarea
                    id="description"
                    v-model="nick.description"
                    placeholder="Enter something..."
                    rows="10"
                    max-rows="50"
                  >
                  </b-form-textarea>
                </b-col>
              </b-row>
              <hr>
              <base-button
                @click="add_nick"
                class="btn-wd"
              >
                Submit
              </base-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import fsg from "@/api/base";
export default {
  name: "Gmail",
  components: {
  },
  data: () => ({
    nick: {
      'title': '',
      'description': '',
      'name': '',
    },
    nicks: [],
    edit: Object
  }),
  created() {
    fsg.get("/nick")
      .then(r => {
        this.nicks = r.data.msg
      })
  },
  methods: {
    add_nick: function(){
      if (!this.nick.id){
        this.nicks.push(this.nick)
      }
      fsg.post("/nick", {'nick': this.nick})
        .then(r => {
          this.$vs.notification({
            title: "added",
            color: "success"
          })
          this.nick.id = r.data.msg.id
          this.nick = {
            'title': '',
            'description': '',
            'name': ''
          }
        })
    },
    remove_nick: function(index){
      var nk = this.nicks[index]
      fsg.delete(`/nick?id=${nk.id}`)
        .then(r => {
          this.$vs.notification({
            title: "Removed",
            color: "success"
          })
          this.nicks.splice(index, 1)
        })
    }
  },
  watch: {
    forward_selected: function (){
      this.forwards = this.resp[this.forward_selected]
      this.issues = this.forwards[Object.keys(this.forwards)[0]]
      this.issues = Object.values()[0]
      this.selected = Object.keys(this.forwards)[0]
    }
  }
}
</script>

<style scope lang="scss">
.list-group-item {
    position: relative;
    display: block;
    padding: 1rem 1rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #e9ecef;
    height: 60px !important;
}
.fixed-list-ideas {
  height: 634px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.truncate {
  width: auto;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>