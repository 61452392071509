<template>
    <div>
      <edit-seller :show="editSeller" :seller="edit"></edit-seller>
      <sku-sale :show="skuActive" :seller="edit"></sku-sale>
      <inventory :show="inventoryActive" :seller="edit"></inventory>
      <vs-table>
        <template #header>
          <vs-input v-model="search" border :placeholder="total" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'name')">
              Name
            </vs-th>
            <vs-th>
              Product
            </vs-th>
            <vs-th>
              Keywords
            </vs-th>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'inventory')">
              Inventories
            </vs-th>
            <vs-th v-if="scopes.includes(role)" sort @click="sellers = $vs.sortData($event ,sellers, 'total_balance')">
              {{ '$' + total_balance }}
            </vs-th>
            <vs-th style="max-width: 30%" edit sort @click="sellers = $vs.sortData($event ,sellers, 'note')">
              Notes
            </vs-th>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'policies_brand_protection')">
              Rp1
            </vs-th>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'intellectual_property')">
              Rp2
            </vs-th>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'switch')">
              On/off
            </vs-th>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'sale_current_month')">
              CM
            </vs-th>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'sale_7_day')">
              W
            </vs-th>
            <vs-th sort @click="sellers = $vs.sortData($event ,sellers, 'sale_30_day')">
              M
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage($vs.getSearch(sellers, search), page, max)"
            :data="tr"
            not-click-selected
            open-expand-only-td
          >
            <vs-td class="vs-td-sm" :class="{'vs-clicked': selected.seller_id == tr.seller_id}">
              <div>
                <vs-tooltip>
                  <span v-if="tr.credit_card_invalid">❌ </span>
                  <b-link @click="selected = tr" :class="{ 'a-green': tr.warn == false, 'a-yellow': tr.warn == true || tr.credit_card_invalid == true, 'a-red': tr.status == 'closed'}" :href="tr.cskh_url" target="_blank">{{ tr.name }}</b-link>
                  <template #tooltip>
                    <div v-if="tr._created">
                      {{ tr._created }}
                    </div>
                    <div v-else>
                      Add seller to profile and update cskh url
                    </div>
                  </template>
                </vs-tooltip>
              </div>
              <div>
                <vs-tooltip danger>
                  <i v-if="tr.warn" class="ni ni-bell-55 text-danger"></i>
                  <template #tooltip>
                    <div>
                      <h5 v-for="(mess, mid) in tr.message" :key="mid">
                        {{ mess }}
                      </h5>
                    </div>
                  </template>
                </vs-tooltip>
              </div>
            </vs-td>
            <vs-td class="vs-td-sx">
              <vs-tooltip warn>
                <h5>
                  {{ tr.product.join(", ")}}
                </h5>
                <template #tooltip>
                  <h5 v-for="(p, pid) in tr.product" :key="pid">
                    {{ products[p] }}
                  </h5>
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td class="vs-td-nm">
              <h5 v-if="tr.keywords" @click="editSeller = !editSeller, edit=tr" class="a-orange">{{ tr.keywords }}</h5>
            </vs-td>
            <vs-td @click="inventoryActive = !inventoryActive, edit=tr" class="vs-td-sx">
              <h5>{{ tr.inventory }}</h5>
            </vs-td>
            <vs-td v-if="scopes.includes(role)" class="vs-td-sx" :class="{'red-bg': tr.US && parseFloat(tr.US.replace('$', '').replace(',', '')) < 100}">
              <vs-tooltip shadow top>
                <h5 :class="{'a-red': tr.negative_balance == true, 'a-green': tr.negative_balance == false}">{{ tr.total_balance }}</h5>
                <template #tooltip>
<!--                  <b-form class="body">-->
                    <div v-if="tr.US">
                      <h3><flag iso="us" /> US: {{ tr.US }}</h3>
                    </div>
                    <div v-if="tr.CA">
                      <h3><flag iso="ca" /> CA: {{ tr.CA }}</h3>
                    </div>
                    <div v-if="tr.MX">
                      <h3><flag iso="mx" /> MX: {{ tr.MX }}</h3>
                    </div>
                    <div v-if="tr.DE">
                      <h3><flag iso="de" /> DE: {{ tr.DE }}</h3>
                    </div>
                    <div v-if="tr.ES">
                      <h3><flag iso="es" /> ES: {{ tr.ES }}</h3>
                    </div>
                    <div v-if="tr.UK">
                      <h3><flag iso="uk" /> UK {{ tr.UK }}</h3>
                    </div>
                    <div v-if="tr.FR">
                      <h3><flag iso="fr" /> FR {{ tr.FR }}</h3>
                    </div>
                    <div v-if="tr.IT">
                      <h3><flag iso="it" /> IT {{ tr.IT }}</h3>
                    </div>
<!--                  </b-form>-->
                </template>
              </vs-tooltip>
            </vs-td>
            <vs-td class="vs-td-lg">
              <h5 v-if="tr.note" @click="editSeller = !editSeller, edit=tr">{{ tr.note }}</h5>
            </vs-td>
            <vs-td class="vs-td-sx">
              <h5>{{ tr.policies_brand_protection }}</h5>
            </vs-td>
            <vs-td class="vs-td-sx">
              <h5>{{ tr.intellectual_property }}</h5>
            </vs-td>
            <vs-td class="vs-td-sx">
              <vs-switch v-model="tr.switch" color="#2dce89">
                <template #off>
                    Off
                </template>
                <template #on>
                    On
                </template>
              </vs-switch>
            </vs-td>
            <vs-td @click="show_sku(tr)" class="vs-td-sx">
              <h5>{{ tr.sale_current_month }}</h5>
            </vs-td>
            <vs-td class="vs-td-sx">
              <h5>{{ tr.sale_7_day }}</h5>
            </vs-td>
            <vs-td class="vs-td-sx">
              <h5>{{ tr.sale_30_day }}</h5>
            </vs-td>

            <template #expand>
              <div class="float-right">
                <div class="con-content">
                  <div class="justify-content-lg-start">
                    <div>
                      <div class="justify-content-start">
                        <vs-input
                            label="Seller id"
                            v-model="tr.seller_id"
                            state="dark"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="small-input left">
                        <vs-input
                            label="Buyer messages"
                            v-model="tr.buyer_messages"
                            state='dark'
                        />
                      </div>
                    </div>

                  </div>
                    <div>
                      <div>
                        <div class="center">
                          <vs-input
                              label="Order defect"
                              v-model="tr.order_defect_rate"
                              state="dark"
                          />
                        </div>
                      </div>
                      <div class="center j">
                        <vs-input
                            label="Late shipment"
                            v-model="tr.late_shipment_rate"
                            state="dark"
                        />
                      </div>
                      <div>
                        <div class="center justify-content-end">
                          <vs-input
                              label="Fulfillment"
                              v-model="tr.pre_fulfillment"
                              state="dark"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="center">
                          <vs-input
                              label="Valid tracking"
                              v-model="tr.valid_tracking_rate"
                              state="dark"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="justify-content-end">
                          <vs-button
                            warn
                            gradient
                            active
                            @click="editSeller = !editSeller, edit=tr"
                          >
                            Edit
                          </vs-button>
                        </div>
                      </div>
                      <div>
                        <div>
                          <vs-button
                              danger
                              gradient
                              active
                              @click="health_seller(tr)"
                          >
                            Update
                          </vs-button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </template>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(sellers, search), max)" />
        </template>
      </vs-table>

      <vs-dialog v-model="editActive">
        <template #header>
            Change Prop {{ editProp }}
        </template>
        <div>
          <b-row>
            <b-col xl="12">
              <vs-input @keypress.enter="updateSeller" v-if="editProp == 'note'" v-model="edit[editProp]" />
              <vs-input @keypress.enter="updateSeller" v-if="editProp == 'seller_id'" v-model="edit[editProp]" />
              <vs-input @keypress.enter="updateSeller" v-if="editProp == 'name'" v-model="edit[editProp]" />
              <vs-select @change="updateSeller" v-if="editProp == 'status'" placeholder="Select" v-model="edit[editProp]">
                <vs-option label="active" value="active">
                  active
                </vs-option>
                <vs-option label="closed" value="closed">
                  closed
                </vs-option>
              </vs-select>

            </b-col>
          </b-row>
        </div>
      </vs-dialog>
    </div>
</template>

<script>
  import fsg from "@/api/base";
  import Balances from "@/views/Pages/Seller/Balances";
  import EditSeller from "@/views/Pages/Seller/EditSeller";
  import SkuSale from "@/views/Pages/Seller/SkuSale";
  import Inventory from "@/views/Pages/Seller/Inventory";
  import {get_role} from "@/util";
    export default {
      name: "seller-table",
      components: {
        Balances,
        EditSeller,
        SkuSale,
        Inventory
      },
      data:() => ({
        total_balance: 0,
        inventoryActive: false,
        editActive: false,
        editSeller: false,
        skuActive: false,
        edit: null,
        editProp: {},
        allCheck: false,
        search: '',
        page: 1,
        max: 100,
        active: 0,
        sellers: [],
        selected: Object,
        all: [],
        total: 'Search',
        seller: null,
        role: get_role(),
        scopes: ['admin', 'mod', 'cskh'],
        products: {},
        link_clicked: false
      }),
      props: {
        status: '',
        projects: '',
        region: '',
        warn: false,
        issue: ''
      },
      created() {

        const loading = this.$vs.loading({
          background: "#fffff",
          color: "#fff"
        })
        fsg.get(`/sellers`)
            .then(resp => {
                           loading.close()
                           this.all = resp.data.message,
                           this.sellers = this.all.filter(seller => seller.status == "active" && this.projects.includes(seller.project) && seller._type == this.region),
                           this.total = `Search in ${this.sellers.length} seller`
                           this.sellers.forEach(s => {
                               this.total_balance = this.total_balance + parseFloat(s.total_balance.replace('$', '').replace(',', '')) 
                               this.total_balance = Math.ceil(this.total_balance)
                           })
                           })
        fsg.get('/products?scope=all')
            .then(resp => {
              resp.data.message.forEach(e => {this.products[e.label] = e.text})
            })
      },
      methods: {
        get_seller: function (){
          this.page = 1
          this.issue = ''
          const loading = this.$vs.loading({
            background: "#fffff",
            color: "#fff"
          })
          this.total_balance = 0
          fsg.get(`/sellers?region=${this.region}&project=${this.projects}&status=${this.status}`)
              .then(resp => {
                loading.close()
                this.all = resp.data.message,
                    // this.sellers = this.all.filter(seller => seller.status == "active" && this.projects.includes(seller.project) && seller._type == this.region),
                    this.sellers = this.all,
                    this.total = `Search in ${this.sellers.length} seller`
                this.sellers.forEach(s => {
                    this.total_balance = this.total_balance + parseFloat(s.total_balance.replace('$', '').replace(',', '')) 
                    this.total_balance = Math.ceil(this.total_balance)
                })

              })
        }
        ,
        show_sku: function (seller){
          this.skuActive = !this.skuActive
          this.edit = seller
        },
        health_seller: function (seller){
          fsg.put(`/health?health_id=${seller.health_id}`)
            .then(resp => {
              this.$vs.notification({
                title: `Sent task health check ${seller.health_id}`,
                text: resp.data.message,
                color: "success"
              })
            })
        },
        filter_issue(){
          if(this.issue == "intellectual_property"){
            this.sellers = this.all.filter(seller => seller.status == "active" && seller.intellectual_property > 9)
          }
          else if(this.issue == "credit_card_invalid"){
            this.sellers = this.all.filter(seller => seller.status == "active" && seller.credit_card_invalid == true)
          }
          else if(this.issue == "no_sale"){
            this.sellers = this.all.filter(seller => seller.status == "active" && seller.sale_7_day == 0)
          }
          else if(this.issue == "negative_balance"){
            this.sellers = this.all.filter(seller => seller.status == "active" && seller.negative_balance == true)
          }
          else if(this.issue == "is_risk"){
            this.sellers = this.all.filter(seller => seller.status == "active" && seller.is_risk == true)
          }
          else if(this.issue == "buyer_messages"){
            this.sellers = this.all.filter(seller => seller.status == "active" && parseFloat(seller.buyer_messages) > 0)
          }
          else if(this.issue == "order_defect_rate"){
            this.sellers = this.all.filter(seller => seller.status == "active" && parseFloat(seller.order_defect_rate) > 1)
          }
          else if(this.issue == "late_shipment_rate"){
            this.sellers = this.all.filter(seller => seller.status == "active" && parseFloat(seller.late_shipment_rate) > 4)
          }
          else if(this.issue == "pre_fulfillment"){
            this.sellers = this.all.filter(seller => seller.status == "active" && parseFloat(seller.pre_fulfillment) > 2.5)
          }
          else if(this.issue == "valid_tracking_rate"){
            this.sellers = this.all.filter(seller => seller.status == "active" && parseFloat(seller.valid_tracking_rate) < 95)
          }
          else if(this.issue == ""){
            this.sellers = this.all.filter(seller => seller.status == "active" && seller._type == this.region && this.projects.includes(seller.project))
          }
        },
        filter_status: function (){
          this.get_seller()
          this.total = `Search in ${this.sellers.length} seller active`
        },
        filter_warn: function (){
          this.sellers = this.all.filter(seller => seller.warn == true)
          this.total = `Search in ${this.sellers.length} seller warning`
        },
        filter_region: function (){
          if (!this.region){
            return false
          }
          this.get_seller()
          // this.sellers = this.all.filter(seller => seller._type == this.region && seller.status == "active" && this.projects.includes(seller.project))
          this.total = `Search in ${this.sellers.length} seller by ${this.region}`
          this.sellers.forEach(s => {
              this.total_balance = this.total_balance + parseFloat(s.total_balance.replace('$', '').replace(',', '')) 
              this.total_balance = Math.ceil(this.total_balance)
          })
        },
        filter_project: function (){
          if (!this.region) {
            this.sellers = this.all.filter(seller => seller.status == "active" && this.projects.includes(seller.project))
            this.total = `Search in ${this.sellers.length} seller`
          }else{
            this.get_seller()
            this.sellers = this.all.filter(seller => seller._type == this.region && seller.status == "active" && this.projects.includes(seller.project))
            console.log(this.sellers.length)
            this.total = `Search in ${this.sellers.length} seller`
            this.sellers.forEach(s => {
                this.total_balance = this.total_balance + parseFloat(s.total_balance.replace('$', '').replace(',', '')) 
                this.total_balance = Math.ceil(this.total_balance)
            })
          }
        },
        notify(title, message, color){
          this.$vs.notification({
            title: title,
            text: message,
            color: color,
            duration: 3
          })
        },
        updateSeller: function (){
          fsg.put(`/sellers`, this.edit)
            .then(resp => {this.$vs.notification({
              title: `Sent task update seller ${this.edit.name}`,
              text: resp.data.message,
              color: "success"
            })})
            .catch(error => {this.$vs.notification({
              title: `Sent task update seller ${this.edit.name}`,
              text: error.response.data.message,
              color: "danger"
            })})
        },
        updateStatus: function (){

        }
      },
      watch: {
        status: function (){
          this.filter_status()
        },
        region: function (){
          this.filter_region()
        },
        projects: function () {
          this.filter_project()
        },
        warn: function (){
          this.filter_warn()
        },
        issue: function (){
          this.filter_issue()
        }
      }
    }
</script>
<style scope lang="scss">
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
}
.vs-table__tr .vs-table__td:last-child {
  border-right: none;
}
.vs-table__tr.isExpand:not(:first-of-type) .vs-table__td{
  background: wheat !important;
}
.vs-table__tr.isExpand:first-of-type .vs-table__td {
  background: wheat !important;
}
.red-bg {
    background: #FC8F8FE5 !important;
}
</style>
