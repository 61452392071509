<template>
    <div>
      <vs-dialog width="800" not-center v-model="toggle">
        <template #header>
          <h4 class="not-margin">
            Báo cáo công việc
          </h4>
        </template>


        <div>
          <h3>
            Khối lượng công việc: {{ edit.volume_work }}
          </h3>
          <br>
          <h3>
            Đã hoàn thành: {{ edit.processed }}
          </h3>
          <br>
          <h3>
            Vấn đề gặp phải: {{ edit.case }}
          </h3>
          <br>
          <h3>
            Đề xuất: {{ edit.suggest }}
          </h3>
          <br>
          <h3>
            Ngày hôm nay thế nào: {{ edit.feeling }}
          </h3>
        </div>

        <template #footer>
          <div class="con-footer">
            <vs-button @click="toggle=false" transparent>
              Ok
            </vs-button>
          </div>
        </template>
      </vs-dialog>
      <vs-table>
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th style="width: 10%" sort @click="issues = $vs.sortData($event ,issues, 'date')">
              Date
            </vs-th>
            <vs-th>
              Name
            </vs-th>
            <vs-th>
              Team
            </vs-th>
            <vs-th>
              Processed
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage($vs.getSearch(issues, search), page, max)"
            :data="tr"
            open-expand-only-td
            @click="edit = tr"
          >
            <vs-td style="width: 30%;">
              <h3>{{ tr.date }}</h3>
            </vs-td>
            <vs-td style="width: 30%;">
              <h3>{{ tr.name }}</h3>
            </vs-td>
            <vs-td style="width: 30%;">
              <h3>{{ tr.team }}</h3>
            </vs-td>
            <vs-td style="width: 70%;">
              <p style="overflow: hidden; text-overflow: ellipsis; width: 100%; display: inline-block">{{ tr.processed }}</p>
            </vs-td>

            <template #expand>
            </template>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(issues, search), max)" />
        </template>
      </vs-table>
    </div>
  </template>
<script>
import fsg from "@/api/base";
  export default {
    data: () => ({
      editActive: false,
      edit: null,
      editProp: {},
      search: '',
      page: 1,
      max: 8,
      active: 0,
      edit: Object,
      toggle: false,
    }),
    props: {
      issues: Array,
      forward: String
    },
    methods: {
      resloved: function (tr, i) {
        fsg.patch('/mail', tr)
            .then(r => {
              this.issues.splice(i, 1),
              this.$vs.notification({
                title: "Resolved!",
                text: "Make fsg great again!",
                color: "success"
              })
            })
            .catch(err => {
              this.$vs.notification({
                title: "Failed!",
                text: `Error ${err.response.data.message}`,
                color: "danger"
              })
            })
      }
  },
  watch: {
    edit: function () {
      this.toggle = !this.toggle
    }
  } 
  }
</script>
<style lang="scss" scoped>
.collapes{
  display: inline;
}
</style>