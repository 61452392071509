<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 100px;background-size: cover; background-position: center top; background-color: #2dce89">
    </base-header>
    <b-container fluid class="mt--5">
      <div class="flex-container">
        <vs-select v-model="type" placeholder="Rank type">
          <vs-option lable="Day ago" value="day_ago">
            Day ago
          </vs-option>
          <vs-option lable="Week ago" value="week_ago">
            Week ago
          </vs-option>
          <vs-option lable="Month ago" value="month_ago">
            Month ago
          </vs-option>
        </vs-select>
        <vs-button
          warn
          @click="download"
        >
          Download
        </vs-button>
      </div>
      <div>
        <h3>TOTAL {{ total }}</h3>
        <vs-table>
          <template #thead>
            <vs-tr>
              <vs-th class="vs-td-nm">
                Sku
              </vs-th>
              <vs-th class="vs-td-nm">
                Seller
              </vs-th>
              <vs-th class="vs-td-nm">
                Asin
              </vs-th>
              <vs-th class="vs-td-nm">
                Sold
              </vs-th>
              <vs-th class="vs-td-nm">
                Url
              </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr
                :key="i"
                v-for="(tr, i) in $vs.getPage(asins, page, max)"
                :data="tr"
            >
              <vs-td class="vs-td-nm">
                {{ tr.sku }}
              </vs-td>
              <vs-td class="vs-td-nm">
                {{ tr.seller }}
              </vs-td>
              <vs-td class="vs-td-nm">
              <div v-if="tr.MarketplaceId == 'ATVPDKIKX0DER'">
                <a :href="`https://www.amazon.com/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A1AM78C64UM0Y8'">
                <a :href="`https://www.amazon.com.mx/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A2EUQ1WTGCTBG2'">
                <a :href="`https://www.amazon.ca/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A1F83G8C2ARO7P'">
                <a :href="`https://www.amazon.co.uk/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A1PA6795UKMFR9'">
                <a :href="`https://www.amazon.de/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A1RKKUPIHCS9HS'">
                <a :href="`https://www.amazon.es/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A13V1IB3VIYZZH'">
                <a :href="`https://www.amazon.fr/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'APJ6JRA9NG5V4'">
                <a :href="`https://www.amazon.it/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A1805IZSGTT6HS'">
                <a :href="`https://www.amazon.nl/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A2NODRKZP88ZB9'">
                <a :href="`https://www.amazon.se/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A33AVAJ2PDY3EV'">
                <a :href="`https://www.amazon.tr/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A17E79C6D8DWNP'">
                <a :href="`https://www.amazon.sa/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A1C3SOZRARQ6R3'">
                <a :href="`https://www.amazon.pl/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              <div v-else-if="tr.MarketplaceId == 'A21TJRUUN4KGV'">
                <a :href="`https://www.amazon.ie/gp/product/${tr.asin}`" target="_blank">{{ tr.asin }}</a>
              </div>
              </vs-td>
              <vs-td class="vs-td-nm">
                {{ tr.sold }}
              </vs-td>
              <vs-td class="vs-td-nm">
                <img style="width: 100px; height: auto" :src="tr.thumbnail_url" alt="">
              </vs-td>
            </vs-tr>
          </template>
          <template #footer>
            <vs-pagination v-model="page" :length="$vs.getLength(asins, max)" />
          </template>
        </vs-table>
      </div>
    </b-container>
  </div>
</template>

<script>
import fsg from "@/api/base";
import XLSX from "xlsx";
export default {
  name: "Rank",
  data: () => ({
    type: "day_ago",
    asins: [],
    page: 1,
    max: 100,
    total: ''
  }),
  created() {
    fsg.get(`/ranking?key=${this.type}`)
      .then(r => {this.asins = r.data.msg, this.total = r.data.total})
  },
  watch: {
    type: function (){
      const loading = this.$vs.loading({
        target: this.$refs.orders,
        color: '#d5397b'})
      fsg.get(`/ranking?key=${this.type}`)
          .then(r => {this.asins = r.data.msg, this.total = r.data.total, loading.close()})
          .error(err => loading.close())
    }
  },
  methods: {
    download: function (){
      fsg.get(`/ranking?key=${this.type}&download=1`)
        .then(r => {
          const data = XLSX.utils.aoa_to_sheet(r.data.msg)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, 'Sheet')
          XLSX.writeFile(wb, `${this.type}.xlsx`)
        })
    },
  }
}
</script>

<style scoped>

</style>