<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 50px; background-size: cover; background-position: center top; background-color: #2dce89">
            <h2>Designer ranked</h2>
        </base-header>
        <b-container fluid class="mt--7">
        <modal
            :show.sync="confirm"
            body-classes="p-0"
            size="xl"
        >
            <b-card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template slot="header">
                    <h2>Bạn có chắc muốn trừ điểm {{ designer.name }} chứ?</h2>
                </template>
                <template>
                </template>
                <template slot="footer">
                    <b-button @click="decrease">Đừng để nó đẻ trứng</b-button>
                    <b-button @click="confirm = !confirm">Hihi em nhầm</b-button>
                </template>
            </b-card>
            <template slot="footer">
                <p></p>
            </template>
        </modal>
            <b-card no-body>
                <b-card-header class="border-0">
                </b-card-header>

                <el-table class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="designers">
                    <el-table-column
                        width="60px"
                        prop="selected"
                    >
                    </el-table-column>
                    <el-table-column label="Designer"
                                    prop="name"
                                    width="200px"
                                    sortable>
                    </el-table-column>
                    <el-table-column label="Tổng đơn"
                                    prop="total_order"
                                    width="180px"
                                    sortable>
                    </el-table-column>
                    <el-table-column label="Tổng designs"
                                    min-width="180px"
                                    prop="designs"
                                    sortable>
                    </el-table-column>
                    <el-table-column label="Tổng đơn mới"
                                    prop="new_order"
                                    min-width="180px"
                                    sortable>
                    </el-table-column>

                    <el-table-column label="Phạt" prop="decrease" min-width="190px">
                    </el-table-column>

                    <el-table-column label="Điểm"
                                    prop="score"
                                    min-width="150px"
                                    sortable>
                    </el-table-column>
                    <el-table-column
                                    min-width="150px"
                                    sortable>
                        <template v-slot="{row}">
                            <div class="avatar-group">
                                <b-button
                                    v-if="['admin', 'mod'].includes(role)"
                                    @click="designer = row, confirm =! confirm"
                                >
                                    Trừ điểm
                                </b-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </b-card>

        </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import fsg from "@/api/base";
export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    data: () => ({
        designers: [],
        designer: {'name': 'unknow'},
        confirm: false,
        role: ''
    }),
    created: function(){
        this.role = localStorage.getItem('role')
        fsg.get('/bonus')
            .then(r => this.designers = r.data.designers)
    },
    methods: {
        'decrease': function(){
            fsg.post(`/decrease_score/${this.designer._id}`)
                .then(r => {
                    this.$vs.notification({
                        'title': "Đã trừ vỡ mồm nó rồi!"
                    })
                    this.confirm = !this.confirm
                })
        }
    },
}
</script>