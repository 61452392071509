<template>
  <div id="productTitle">
      <h3 @click="doCopy(title_text)">{{ title_text }}</h3>
      <b-badge @click="doCopy(tm)" variant="danger" v-for="(tm, id) in trademarks" :key="id">{{ tm }}</b-badge>
      <b-badge @click="doCopy(bl)" variant="info" v-for="(bl, bid) in block_tags" :key="bid">{{ bl }}</b-badge>
      <b-badge @click="doCopy(rm)" variant="dark" v-for="(rm, rid) in remove_tags" :key="rid">{{ rm }}</b-badge>
  </div>
</template>
<script>
import fsg from "@/api/base";
export default {
    data(){
      return {
        title: '',
        tags: '',
      }
    },
    props: {
      trademarks: Array,
      block_tags: Array,
      remove_tags: Array,
      title_text: String
    },
    watch: function(){
    },
    mounted() {
      // fsg.get(`/uspto?title=${this.title_text}`)
      //   .then(r => {
      //     this.trademarks = r.data.msg.trademarks;
      //   })
    },
    methods: {
    doCopy: function (val){
      this.$copyText(val)
      this.$vs.notification({
        title: `Copied ${val}`,
        color: "success",
      })
    },
    }
}
</script>