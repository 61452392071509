<template>
    <span :class="'priority-label ' + color">{{ label }}</span>
  </template>
  
  <script>
  export default {
    name: "PriorityLabel",
    props: {
      label: {
        type: String,
        required: true,
        default: 'Priority'
      },
      color: {
        type: String,
        default: 'red',
        validator: (value) => ['red', 'orange', 'yellow', 'green', 'blue'].includes(value),
      },
    },
  };
  </script>
  
  <style scoped>
  .priority-label {
    padding: 4px 4px;
    border-radius: 4px;
    max-width: 200px;
    max-height: 50px;
    display: flex;
    justify-content: center;
  }
  
  .red {
    background-color: red;
    color: white;
  }
  
  .orange {
    background-color: orange;
    color: white;
  }
  
  .yellow {
    background-color: yellow;
    color: black;
  }
  
  .green {
    background-color: green;
    color: white;
  }
  
  .blue {
    background-color: blue;
    color: white;
  }
  </style>
  