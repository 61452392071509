import { Line } from "vue-chartjs";
import fsg from "@/api/base";

export default {
  name: "area-chart",
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient1: null,
      gradient2: null,
      label: null,
    };
  },
  props: {
    resp: Object,
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient1 = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient1.addColorStop(0, "#FCF3CF");
    this.gradient1.addColorStop(0.5, "#F9E79F");
    this.gradient1.addColorStop(1, "#F7DC6F");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    fsg.get("/saleprojectmonth")
        .then(resp => {
          this.renderChart(
              {
                labels: resp.data.message.label,
                datasets: [
                  {
                    label: "JSC",
                    borderColor: "#FC2525",
                    pointBackgroundColor: "white",
                    borderWidth: 1,
                    pointBorderColor: "white",
                    backgroundColor: this.gradient,
                    data: resp.data.message.jsc
                  },
                  {
                    label: "FSG",
                    borderColor: "#05CBE1",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.gradient1,
                    data: resp.data.message.fsg
                  },
                  {
                    label: "HTV",
                    borderColor: "#f0ad4e",
                    pointBackgroundColor: "white",
                    pointBorderColor: "white",
                    borderWidth: 1,
                    backgroundColor: this.gradient2,
                    data: resp.data.message.htv
                  }
                ]
              },
              { responsive: true, maintainAspectRatio: false }
          );
        })
  }
};