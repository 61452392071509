<template>
  <div>
    <vs-dialog scroll width="700px" v-model="active">
      <template #header>
        <h4 class="not-margin" align="center">
           <b>Detail skus</b>
        </h4>
      </template>
      <div class="con-content">
        <b-container class="justify-content-center full-width">
          <div>
            <vs-table striped>
              <template #thead>
                <vs-tr>
                  <vs-th>
                    Sku
                  </vs-th>
                  <vs-th>
                    Product
                  </vs-th>
                  <vs-th>
                    time
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                  :key="i"
                  v-for="(tr, i) in skus"
                  :data="tr"
                >
                  <vs-td>
                    {{ tr.sku }}
                  </vs-td>
                  <vs-td>
                    {{ tr.type }}
                  </vs-td>
                  <vs-td>
                  {{ tr.time }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-container>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import fsg from "@/api/base";
export default {
  name: "SkuSale",
  data: () => ({
    skus: [],
    active: false
  }),
  props: {
    show: Boolean,
    seller: Object
  },
  created() {
  },
  mounted() {
    this.active = this.show
  },
  watch: {
    show: function (){
      this.active = this.show
      console.log(this.seller)
      fsg.get(`/sku_sale?seller_id=${this.seller.seller_id}`)
          .then(r => {
            this.skus = r.data.msg
            console.log(this.skus)
          })
    }
  }
}
</script>

<style scoped>

</style>