<template>
  <vs-dialog width="300px" not-center v-model="active">
    <template #header>
      <h4 class="not-margin" align="center">
        <b>Add Profile</b>
      </h4>
    </template>


    <div>
      <b-container>
        <b-row class="justify-content-center">
          <b-col xl="12" lg="5" md="1">
            <b-card no-body class="bg-secondary border-0 mb-0">
              <b-form>
                <base-input alternative
                            class="mb-3"
                            name="Email"
                            :rules="{required: true}"
                            prepend-icon="ni ni-email-83"
                            PLACEHOLDER="Email"
                            v-model="email">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="Password"
                            :rules="{required: true}"
                            prepend-icon="ni ni-key-25"
                            placeholder="Password"
                            v-model="password">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="Otp secret"
                            :rules="{required: true}"
                            prepend-icon="ni ni-lock-circle-open"
                            placeholder="Otp secret"
                            v-model="secret_otp">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="password_email"
                            :rules="{required: true}"
                            prepend-icon="ni ni-key-25"
                            placeholder="Password mail"
                            v-model="password_email">
                </base-input>
                <base-input alternative
                            class="mb-3"
                            name="Name"
                            :rules="{required: true}"
                            prepend-icon="ni ni-single-02"
                            placeholder="Name"
                            v-model="name">
                </base-input>
                <b-row>
                  <b-col xl="6">
                    <vs-button @click="addProfile" transparent>
                      Oke
                    </vs-button>
                  </b-col>
                  <b-col xl="6">
                    <vs-button @click="active = false" transparent>
                      Cancel
                    </vs-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </vs-dialog>
</template>

<script>
import Card from "@/components/Cards/Card";
import fsg from "@/api/base";
export default {
  name: "profile",
  components: {
    Card,
  },
  data: () => ({
    email: '',
    name: '',
    password: '',
    secret_otp: '',
    active: false,
    password_email: '',
  }),
  props: {
    show: Boolean,
  },
  watch: {
    show: function (){
      this.active = true
    }
  },
  methods: {
    addProfile: function (){
      const body = {
        "name": this.name,
        "email": this.email,
        "password": this.password,
        "secret_otp": this.secret_otp,
        "password_email": this.password_email
      }
      fsg.post("/profile", body)
        .then(resp => {
          this.$vs.notification({
            title: "Add profile to database",
            text: resp.data.message,
            color: "success"
          })
        })
      .catch(err =>{
        console.log(err.response.data.message)
        this.$vs.notification({
          title: "Add profile to database",
          text: err.response.data.message,
          color: 'danger',
        })
      })
    }
  },
}
</script>

<style scoped lang="scss">
.justify-content-center {
  display: flex;
  -webkit-box-pack: center !important;
  justify-content: center !important;
  width: 100%;
  margin-left: 0px;
}
</style>