import { Bar } from "vue-chartjs";
import fsg from "@/api/base";

export default {
  name: "bar-chart",
  extends: Bar,
  created() {
    fsg.get("/barchart")
        .then(resp => {
            console.log(resp.data.message.datasets)
          this.renderChart(
              {
                labels: resp.data.message.label,
                datasets: resp.data.message.datasets
              },
              { responsive: true, maintainAspectRatio: false }
          );
        })
  }
};