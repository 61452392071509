<template>
    <div class="item-card">
        <div class='preview'>
            <img v-if="file_name.includes('.png') || file_name.includes('.jpeg') || file_name.includes('.jpg')" @click="preview = !preview" width="100%" height="100%" class="thumbnail-img" :src="thumbnail" alt="">
            <img v-else-if="file_name.includes('.psd')" src="img/psd.png" alt="" width="100%" height="100%" class="thumbnail-img">
            <img v-else-if="file_name.includes('.txt')" src="img/txt.png" alt="" width="100%" height="100%">
        </div>
        <div class='metadata'>
            <vs-tooltip bottom>
                <div class="flex-container">
                    <p class='title-name'>{{ file_name }}</p>
                    <vs-button
                        icon
                        color="warn"
                        gradient
                        :href="url"
                    >
                        <i class='bx bxs-cloud-download'></i>
                    </vs-button>
                </div>
                <template #tooltip>
                    {{ file_name }}
                </template>
            </vs-tooltip>
        </div>
        <vs-dialog width="900px" v-model ="preview">
            <template #header>
            <h4 class="not-margin">
                <b>Preview</b>
            </h4>
            </template>
            <div>
            <img id="img-preview" class="slider" :src="url" alt="">
            </div>
        </vs-dialog>
    </div>
</template>
<script>
export default {
    name: 'item-card',
    data: () =>({
        preview: false
    }),
    props: {
        thumbnail: String,
        file_name: String,
        url: String,
    }
}
</script>
<style lang="scss" scoped>
.item-card {
    margin: 40px 40px 40px 0;
    height: 226px;
    width: 226px;
    overflow: hidden;
    border-radius: 5px;
    border-color: #B3B5BB;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}
.preview {
    align-items: center;
    height: 160px;
    width: 100%;
    background-color: white;
}
.metadata {
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
    border-color: #B3B5BB;
    border-style: solid;
    border-width: 1px;
    border-right: none;
    border-bottom: none;
    border-left: none;
    width: 100%;
    height: auto;
}
img {
    object-fit: cover;
}
.title-name {
    size: 25;
    font-weight: bold;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    padding-right: 40px;
}
.flex-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.slider {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>