import { Pie } from "vue-chartjs";
import fsg from "@/api/base";

export default {
  name: "pie-chart",
  extends: Pie,
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    fsg.get("/saleproduct")
        .then(resp => {
            console.log(resp)
          this.renderChart(
              {
                labels: resp.data.message.label,
                datasets: [
                  {
                    backgroundColor: resp.data.message.colors,
                    data: resp.data.message.data
                  }
                ]
              },
              { responsive: true, maintainAspectRatio: false }
          );

        })
  }
};