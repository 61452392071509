<template>
  <div>

    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <stats-card title="Current Day"
                      type="gradient-red"
                      :sub-title="sale_by_date.current_day.value.toString()"
                      icon="ni ni-spaceship"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ sale_by_date.current_day.growth }} |</span>
              <span class="text-danger mr-2">{{ sale_by_date.current_day._updated }}</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Current Week"
                      type="gradient-orange"
                      :sub-title="sale_by_date.current_week.value.toString()"
                      icon="ni ni-chart-bar-32"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ sale_by_date.current_week.growth}}</span>
              <span class="text-nowrap">Since last week</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Current Month"
                      type="gradient-green"
                      :sub-title="sale_by_date.current_month.value.toString()"
                      icon="ni ni-money-coins"
                      class="mb-4">

            <template slot="footer">
              <span class="text-danger mr-2">{{ sale_by_date.current_month.growth}}</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Day ago"
                      type="gradient-info"
                      :sub-title="sale_by_date.current_dayago.value.toString()"
                      icon="ni ni-trophy"
                      class="mb-4">
            <template slot="footer">
              <span class="text-danger mr-2">🚀 Make fsg GREAT AGAIN 🚀</span>
              <span class="text-nowrap"></span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--7">
      <b-row>
        <b-col xl="12" class="mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-uppercase text-muted ls-1 mb-1">Days</h6>
                <h5 class="h3 text-black mb-0">Sales by project</h5>
              </b-col>
              <b-col xl="12" class="mb-5 mb-xl-0">
                <area-chart-day></area-chart-day>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="mt-5">
      <b-row>
        <b-col xl="8" class="mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-light text-uppercase ls-1 mb-1">Months</h6>
                <h5 class="h3 text-black mb-0">Sales value</h5>
              </b-col>
            </b-row>
            <area-chart></area-chart>
          </card>
        </b-col>

        <b-col xl="4" class="mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-uppercase text-muted ls-1 mb-1">Current month</h6>
                <h5 class="h3 text-black mb-0">Sales current month</h5>
              </b-col>
            </b-row>
            <pie-chart></pie-chart>
          </card>
        </b-col>
      </b-row>
      <!-- End charts-->

      <!--Tables-->
      <b-row class="mt-5">
        <b-col xl="12" class="mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-uppercase text-muted ls-1 mb-1">Year</h6>
                <h5 class="h3 text-black mb-0">Sales by products</h5>
              </b-col>
              <b-col xl="12" class="mb-5 mb-xl-0">
                <bar-chart></bar-chart>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
      <!--End tables-->
    </b-container>
  </div>
</template>
<script>
  // Charts
  import AreaChart from "@/components/Charts/AreaChart";
  import AreaChartDay from "@/components/Charts/AreaChartDay";
  import BarChart from "@/components/Charts/BarChart";
  import PieChart from "@/components/Charts/PieChart";

  // Tables
  import SocialTrafficTable from './Dashboard/SocialTrafficTable';
  import PageVisitsTable from './Dashboard/PageVisitsTable';
  import fsg from "@/api/base";

  export default {
    components: {
      PageVisitsTable,
      SocialTrafficTable,
      AreaChart,
      AreaChartDay,
      BarChart,
      PieChart
    },
    data() {
      return {
        sale_by_date: Object,
        saleproduct: Object,
      }
    },
    methods: {
    },
    created() {
      fsg.get('/salenumber').then(resp => (this.sale_by_date = resp.data.message))
    }
  };
</script>
<style>
.el-table .cell{
  padding-left: 0px;
  padding-right: 0px;
}
</style>
