<template>
  <vs-dialog width="300px" not-center v-model="active">
    <template #header>
      <h4 class="not-margin" align="center">
        <b>Balances</b>
      </h4>
    </template>

    <div class="con-content">
      <b-container>
        <b-row class="justify-content-center">
          <b-col xl="12" lg="5" md="1">
            <b-form>
              <div v-if="seller.US">
                <h3><flag iso="us" /> United State: {{ seller.US }}</h3>
              </div>
              <div v-if="seller.CA">
                <h3><flag iso="ca" /> Canda: {{ seller.CA }}</h3>
              </div>
              <div v-if="seller.MX">
                <h3><flag iso="mx" /> Mexico: {{ seller.MX }}</h3>
              </div>
              <div v-if="seller.DE">
                <h3><flag iso="de" /> Germany: {{ seller.DE }}</h3>
              </div>
              <div v-if="seller.ES">
                <h3><flag iso="es" /> Spain: {{ seller.ES }}</h3>
              </div>
              <div v-if="seller.UK">
                <h3><flag iso="uk" /> United Kingdom {{ seller.UK }}</h3>
              </div>
              <div v-if="seller.FR">
                <h3><flag iso="fr" /> France {{ seller.FR }}</h3>
              </div>
              <div v-if="seller.IT">
                <h3><flag iso="it" /> Italy {{ seller.IT }}</h3>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </vs-dialog>
</template>

<script>
export default {
  name: "balance",
  data: () => ({
    active: false
  }),
  props: {
    seller: Object,
    show: Boolean
  },
  watch: {
    show: function (){
      this.active = !this.show
    }
  }
}
</script>

<style scoped>

</style>