<template>
  <vs-select
      placeholder="Project"
      v-model="project"
  >
    <vs-option v-for="(project, index) in projects" :key="index" :value="project">
      {{ project }}
    </vs-option>
  </vs-select>
</template>

<script>
import fsg from "@/api/base";
export default {
  name: "projects",
  data: () => ({
    project: '',
    projects: []
  }),
  created() {
    fsg.get("/projects").then(resp => {this.projects = resp.data.message})
  },
  watch: {
    project: function (){
      this.$emit('change', this.project)
    }
  },
}
</script>

<style scoped>

</style>