<template>
  <div>
    <vs-dialog width="550px" not-center v-model="delete_node">
    <template #header>
      <h4 class="not-margin">
        <b>Do you want delete it {{ temp.name }}?</b>
      </h4>
    </template>

    <template #footer>
      <div>
        <vs-button @click="_delete" transparent>
          Ok
        </vs-button>
        <vs-button @click="delete_node = false" dark transparent>
          Cancel
        </vs-button>
      </div>
    </template>
  </vs-dialog>

  <vs-dialog v-model="upload">
    <template #header>
      <h4 class="not-margin">
        <b>Upload center</b>
      </h4>
    </template>
    <vue2Dropzone @vdropzone-error="vdropzone_error" @vdropzone-success="vdropzone_success" class="upload" ref="myVueDropzone" id="file_upload" :options="options"></vue2Dropzone>
  </vs-dialog>
  <vs-dialog width="900px" v-model ="preview">
    <template #header>
      <h4 class="not-margin">
        <b>Preview</b>
      </h4>
    </template>
    <div>
      <img id="img-preview" class="slider" :src="url" alt="">
    </div>
  </vs-dialog>
  <vs-dialog width="900px" v-model ="text_preview">
    <template #header>
      <h4 class="not-margin">
        <b>Text view</b>
      </h4>
    </template>
    <div>
      <p v-for="line in content_text">{{ line }}</p>
    </div>
  </vs-dialog>

   <b-container fluid>
     <b-card class="card-container">
       <div>
        <div>
          <div class="flex-container">
            <div>
              <h1 class="logo">FSG</h1>
            </div>
            <div style="margin-left: 20px">
              <vs-input @keypress.enter="find_node" v-model="search" placeholder="Search">
                <template #icon>
                  <i class='bx bx-search'></i>
                </template>
              </vs-input>
            </div>
          </div>
          <hr>
        </div>
        <!-- day la doan folder -->
        <div class="flex-container">
          <div class="folder-container">
            <div>
                <button class="addBt" @click="addNode">
                  <i class="bx bx-plus" style="font-size: 2em;"></i>
                </button>
            </div>
            <div class="folder-fixed">
              <div style="height: 80px">
              </div>
              <vue-tree-list
                @click="onClick"
                @change-name="onChangeName"
                @delete-node="onDel"
                @add-node="onAddNode"
                :model="data"
                default-tree-node-name="New_idea"
                default-leaf-node-name="Upload_item"
                :default-expanded=false
              >
                <template v-slot:leafNameDisplay="slotProps">
                  <vs-tooltip>
                    <!-- <span class="node-name">
                        {{ slotProps.model.name }}
                    </span> -->
                    <h4 class="node-name">
                      {{ slotProps.model.name }}
                    </h4>
                    <template #tooltip>
                      {{ slotProps.model.name }}
                    </template>
                  </vs-tooltip>
                </template>
                <span class="icon" slot="addTreeNodeIcon"><i class="bx bxs-folder-open" style="color: red"></i></span>
                <span class="icon" slot="addLeafNodeIcon"><i class="bx bx-cloud-upload" style="color: green"></i></span>
                <span class="icon" slot="editNodeIcon"><i class="bx bx-edit" style="color: #733e3e"></i></span>
                <span class="icon" slot="delNodeIcon"><i class="bx bx-trash-alt"></i></span>
                <template v-slot:leafNodeIcon="slotProps">
                  <span v-if="slotProps.model.is_image" class="icon" slot="leafNodeIcon"><i class="bx bx-image-alt" style='color: #cb3838'></i></span>
                  <span v-else-if="!slotProps.model.is_image" class="icon" slot="leafNodeIcon"><i class='bx bxs-file-txt' style='color:#733e3e'  ></i></span>
                </template>
                <span class="icon" slot="treeNodeIcon"><i class="bx bx-folder" style='color: #d8ba79'></i></span>
              </vue-tree-list>
            </div>
          </div>
          <div class="body-container">
            <div class="preview" v-if="['admin', 'mod', 'leader'].includes(role)">
              <b-dropdown id="actions" :text="text" class="m-md-2">
                <b-dropdown-item @click="get_tree('pink')">Pink</b-dropdown-item>
                <b-dropdown-item @click="get_tree('black')">Black</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="preview-container" :v-model="images">
              <div v-for="(node, idx) in images" :key="idx">
                <div>
                  <ItemCard :url="node.url" :thumbnail="node.thumbnail" :file_name="node.name"></ItemCard>
                </div>
              </div>
            </div>
          </div>
        </div>

       </div>

     </b-card>
   </b-container> 
   </div>
</template>
<script>

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
import fsg from "@/api/base";
import ItemCard from './ItemCard.vue';
export default {
  name: "fsg-store",
  components: {
    ItemCard,
    vue2Dropzone,
  },
  data: function(){
    return {
      node: null,
      parent: null,
      images: [],
      team: ['admin', 'mod', 'leader'].includes(localStorage.getItem('role')) ?  'pink': localStorage.getItem('team'),
      role: localStorage.getItem('role'),
      text: 'Change Team',
      content_text: '',
      upload: false,
      preview: false,
      text_preview: false,
      delete_node: false,
      url: '',
      user: localStorage.getItem('name'),
      options: {
        url: 'https://api.fsggroup.io/fsgstore',
        // url: 'http://0.0.0.0:5001/fsgstore',
        thumbnailWidth: 150,
        maxFilesize: 100,
        acceptedFiles: '*',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        uploadMultiple: true,
        parallelUploads: 10000,
        dictDefaultMessage: 'Drop file here'
      },
      // msg: null,
      data: new Tree([]),
      // data: Tree()
      newTree: {},
      temp: Object(),
      search: ''
    }
  },
  methods: {
      getText(url){
        fetch(url).then((r)=>{r.text().then((d)=>{this.content_text = d.split("\n")})})
      },
      _delete(){
        this.temp.remove()
        this.to_json()
        fsg.post(`/items?team=${this.team}`, this.newTree).then(r => console.log('Deleted'), this.delete_node = false)
      },
      onDel(node) {
        if (!['mod', 'admin', 'leader'].includes(this.role) && !node.isLeaf){
          this.$vs.notification({
            title: "Không có quyền xóa!",
            color: "warn"
          })
        }else{
          console.log(node)
          this.temp = node
          this.delete_node = true
        }
      },

      onChangeName(params) {
        this.to_json()
        fsg.post(`/items?team=${this.team}`, this.newTree).then(r => console.log('Name changed'))
      },

      onAddNode(node) {
        node.team = this.team
        if (node.isLeaf){
          this.upload = true
          this.node = Object.assign({}, node)
          node.remove()
          this.parent = node.parent
        }else{
          this.to_json()
          fsg.post(`/items?team=${this.team}`, this.newTree).then(r => console.log('added bucket'))
        }
      },

      onClick(params) {
        var results = []
        function _dfs(node) {
          if(node.is_image == true) {
            results.push(node)
          }

          if (node.children && node.children.length > 0) {
            for (var i = 0, len = node.children.length; i < len; i++) {
              _dfs(node.children[i])
            }
          }
          return results
        }
        if(params.isLeaf && (params.name.includes('.png') || params.name.includes('jpg') || params.name.includes('jpeg'))){
          this.preview = !this.preview
          this.url = params.url
        }else if (params.name.includes('.txt')){
          this.text_preview = !this.text_preview
          this.getText(params.url)
        }
        else if (!params.isLeaf && params.parent.name != 'root'){
          params.toggle()
          this.images = _dfs(params)
        }
        else{
          if(params.isLeaf) this.images.push(params)
        }
      },

      addNode() {
        var node = new TreeNode({ name: 'Bucket', isLeaf: false, id: Date.now(), team: this.team})
        if (!this.data.children) this.data.children = []
        this.data.addChildren(node)
        this.to_json()
        fsg.post(`/items?team=${this.team}`, this.newTree).then(r => console.log('added'))

      },

      to_json(){
        var vm = this
        function _dfs(oldNode) {
          var newNode = {}

          for (var k in oldNode) {
            if (k !== 'children' && k !== 'parent') {
              newNode[k] = oldNode[k]
            }
          }

          if (oldNode.children && oldNode.children.length > 0) {
            newNode.children = []
            for (var i = 0, len = oldNode.children.length; i < len; i++) {
              newNode.children.push(_dfs(oldNode.children[i]))
            }
          }
          return newNode
        }
        vm.newTree = _dfs(vm.data)
      },
      vdropzone_success: function(file, resp){
        var now = Date.now()
        var count = 0
        resp.msg.items.forEach(item => {
          if (item.name === file.name){
            var new_node = Object.assign({}, this.node)
            new_node.url = item.url
            new_node.name = file.name
            new_node.is_image = item.is_image
            new_node.thumbnail = item.thumbnail
            new_node.id = now + count
            this.parent.addChildren(new_node)
            count ++
          }
        });
        this.to_json()
        fsg.post(`/items?team=${this.team}`, this.newTree).then(r => console.log('added'))
        this.upload = false
        this.get_images()
      },
      vdropzone_error: function(file){
        this.node.remove()
        this.upload = false
      },
      open_preview: function(){
        $("#img-preview").ezPlus({
          zoomType: 'lens',
          lensShape: 'round',
          lensSize: 600
        })
      },
      get_images: function(){
        var vm = this
        var results = []
        function _dfs(node) {
          if(node.is_image == true) {
            results.push(node)
          }

          if (node.children && node.children.length > 0) {
            for (var i = 0, len = node.children.length; i < len; i++) {
              _dfs(node.children[i])
            }
          }
          return results
        }
        vm.images = _dfs(vm.data)
      },
      get_tree(team){
        this.team = team
        this.text = team
        fsg.get(`/items?team=${team}`)
        .then(r => {this.data = new Tree(r.data.msg)})
        .catch(r => {this.data = new Tree([])})
      },
      find_note(){
      }
    },
  created() {
    fsg.get(`/items?team=${this.team}`)
      .then(r => {this.data = new Tree(r.data.msg)})
      .catch(r => {this.data = new Tree()})
  },
  updated(){
  }
}
</script>
<style>
.addBt{
  width: 50%;
  align-items: flex-start;
  top: 0px;
  position: absolute;
  /* justify-content: flex-start; */
  background-color: white;
  margin: 0px 25% 20px 25%;
  padding: 15px 80px 15px 80px;
  font-size: 16px;
  border-radius: 40px;
  display: flex;
  border-width: 0px;
  border-style: solid;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 0.2);
}
.vtl-node-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 0 5px 1rem;
    line-height: 15px;
    height: 30px;
}
.vtl-node-main:hover {
  background-color: yellow;
}
</style>
<style lang="scss">
  .vtl {
    .vtl-drag-disabled {
      background-color: #d0cfcf;
      width: 350px;
      &:hover {
        background-color: #d0cfcf;
        height: 15px;
      }
    }
    .vtl-disabled {
      background-color: #d0cfcf;
      width: 350px;
    }
    .vtl-node-main{
      &:hover{
        background-color: #e6f0fe;
        border-radius: 0 50px 50px 0;
        height: 30px;
      }
      &:active{
        background-color: #e6f0fe;
        border-radius: 0 50px 50px 0;
      }
      &:visited{
        background-color: #e6f0fe;
        border-radius: 0 50px 50px 0;
      }
    }
  }
</style> 
 
<style lang="scss" scoped>
.upload {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 200px;
  padding: 10px;
  border: 1px;
  /* margin: 30px; */
  border-style: dashed;
  border-radius: 10px;
  overflow: hidden;
  min-width: 100% !important;
}
.img-transparent {
  background-image: 'img/transparent.png';
  background-size: contain;
  background-repeat: no-repeat;
}
.flex-container {
  display: flex;
  line-height: 30px;
  // width: 100%;
  justify-content: flex-start;
  flex-direction: row;
}
.preview {
  align-items: flex-start;
}
.folder-container {
  display: flex;
  width: 350px;
  flex-direction: row;
  height: 90%;
  overflow: auto;
  position: relative;
  overflow-x: hidden;
}
.body-container {
  margin-left: 20px;
  display: flex;
  width: calc(100% - 370px);
}
.folder-fixed {
  height: 800px;
  width: 100%;
}
.card-container {
  display: flex;
  width: 100%;
  height: 1000px;
  flex-direction: column;
}
.images {
  width: 100%;
  display: flex;
  flex-wrap: row wrap;
}
.logo {
  width: 350px;
}
.node-name {
  width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  padding-left: 10px;
  margin-right: 10px;
}
.preview-container {
  margin-top: 40px;
  display: flex;
  position: absolute;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-bottom: 100px;
  height: 800px;
  overflow: auto;
}
.slider {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
  .icon {
    &:hover {
      cursor: pointer;
    }
  }
 
  .muted {
    color: gray;
    font-size: 80%;
  }
  .icon i, .icon svg {
    font-size: 1.5rem;
}
</style> 
<style lang="scss" scoped>
.vs-input {
    border: 2px solid transparent;
    background: rgba(var(--vs-gray-2), 1);
    color: rgba(var(--vs-text), 1);
    padding: 7px 13px;
    border-radius: inherit;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    padding-left: 10px;
    width: 700px !important;
}
  
</style>
