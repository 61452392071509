<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 50px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top; background-color: #2dce89">
        </base-header>
        <modal
            :show.sync="modals.slide_template" 
            size="lg"
            body-classes="p-0">
            <!-- <vue-images
                :imgs="imgs"
                :showclosebutton="true"
                :showcaption="false"
                :showimagecount="false"
                :modalclose="true"
                :mousescroll="true"
            >
            </vue-images> -->
            <div>
                <VueSlickCarousel :arrows="true" :dots="true">
                    <div v-for="(url, idx) in imgs">
                        <b-img thumbnail fluid :src="url"></b-img>
                        <div class="test">
                            <b-button variant="success" @click="current_img = idx">remove</b-button>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
            <template slot="footer">
                <h1></h1>
                <!-- <b-button variant="success" @click="update_idea">Update idea</b-button> -->
            </template>

        </modal>
        <modal
            :show.sync="modals.des_detail"
            body-classes="p-0"
            size="xl"
        >
            <b-card
                type="secondary"
                header-classes="bg-transperent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template slot="header">
                    Description
                </template>
                <template>
                    <base-input
                        v-model="edit.name"
                        class="font-weight-600 name mb-0 text-sm"
                        placeholder="Name"
                        prepend-icon="bx bx-rename">
                    </base-input>
                    <b-form-textarea
                        v-model="edit.description"
                        placeholder="Enter something..."
                        rows="20"
                        max-rows="100" 
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <vue2Dropzone ref="myVueDropzone" @vdropzone-sending="vdropzone_sending" id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
                </template>
            </b-card>
            <template slot="footer">
                <b-button variant="success" @click="update_idea">Update</b-button>
                <b-button @click="modals.des_detail = false">cancel</b-button>
            </template>
        </modal>
        <modal
            :show.sync="modals.form"
            body-classes="p-0"
            size="xl"
        >
            <b-card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template slot="header">
                    <h2>New idea</h2>
                </template>
                <template>
                    <base-input alternative
                        v-model="idea.name"
                        class="mb-3"
                        placeholder="Name"
                        prepend-icon="bx bx-rename">
                    </base-input>
                    <base-input alternative
                        v-model="idea.title"
                        class="mb-3"
                        placeholder="Title"
                        prepend-icon="bx bx-heading">
                    </base-input>
                    <b-form-textarea
                        v-model="idea.description"
                        placeholder="Enter something..."
                        rows="5"
                        max-rows="100"
                    >
                    </b-form-textarea>
                    <hr>
                    <vue2Dropzone ref="myVueDropzone" @vdropzone-sending="vdropzone_sending" id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
                </template>
                <template slot="footer">
                    <b-button @click="add_idea">Confirm</b-button>
                </template>
            </b-card>
            <template slot="footer">
                <p></p>
            </template>
        </modal>
        <b-container fluid class="mt--7">
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-button variant="primary" @click="before_add_idea">Add</b-button>
                    <b-button variant="success" @click="confirm_idea" siez="lg">Confirm</b-button>
                    <PriorityLabel></PriorityLabel>
                </b-card-header>

                <el-table class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="ideas">
                    <el-table-column
                        width="60px"
                        prop="selected"
                    >
                        <template v-slot="{row}">
                            <b-form-checkbox v-model="row.selected" size="lg"></b-form-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column label="created"
                                    prop="_created"
                                    width="150px"
                                    sortable>
                    </el-table-column>
                    <el-table-column label="SKU"
                                    prop="fsg_sku"
                                    width="150px"
                                    sortable>
                    </el-table-column>
                    <el-table-column label="Name"
                                    min-width="250px"
                                    prop="name"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <!-- <a href="#" class="avatar rounded-circle mr-3">
                                    <img alt="Image placeholder" src="https://ih1.redbubble.net/image.819803303.4430/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg">
                                </a> -->
                                <b-media-body>
                                    <span class="font-weight-600 name mb-0 text-sm">{{ row.name }}</span>
                                </b-media-body>
                            </b-media>
                        </template>
                    </el-table-column>
                    <el-table-column label="Description"
                                    prop="description"
                                    min-width="400px"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-media-body>
                                    <span @click="modals.des_detail = true, edit=row" class="font-weight-600 name mb-0 text-sm truncate">{{ row.description }}</span>
                                </b-media-body>
                            </b-media>
                        </template>
                        
                    </el-table-column>

                    <el-table-column label="Users" min-width="190px">
                        <template v-slot="{row}">
                            <div class="avatar-group">
                                <!-- <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                data-original-title="Ryan Tompson">
                                    <img alt="Image placeholder" src="img/theme/team-1.jpg">
                                </a> -->
                                <span class="font-weight-600 name mb-0 text-sm truncate text-danger">{{ row.employee }}</span>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Image"
                                    prop="urls"
                                    min-width="100px"
                                    sortable>
                        <template v-slot="{row}">
                            <div class="d-flex align-items-center">
                                <img @click="slide_on(row)" class="thumbnail-fsg" :src="row.templates[0]" alt="">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="60px">
                        <template v-slot="{row}">
                            <el-dropdown trigger="click" class="dropdown">
                            <span class="btn btn-sm btn-icon-only text-light">
                            <i class="fas fa-ellipsis-v mt-2"></i>
                            </span>
                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                    <b-dropdown-item @click="modals.des_detail = true, edit=row, idea = row">Edit</b-dropdown-item>
                                    <b-dropdown-item @click="delete_idea(row.id)">Delete</b-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <b-card-footer class="py-4 d-flex justify-content-end">
                    <base-pagination v-model="currentPage" :per-page="1" :total="Math.floor(ideas.length/1)"></base-pagination>
                </b-card-footer> -->
            </b-card>
        </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
import fsg from "@/api/base";
import vueImages from 'vue-images/dist/vue-images'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import PriorityLabel from '../../../components/Label.vue';

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        vue2Dropzone,
        vueImages,
        PriorityLabel,
        VueSlickCarousel
    },
    data(){
        return {
            edit: Object,
            modals: {
                form: false,
                des_detail: false,
                slide_template: false
            },
            current_img: null,
            imgs: [],
            nick_id: '',
            employee_id: '',
            idea: {
                title: '',
                description: '',
                name: '',
            },
            ideas: [],
            dropzoneOptions: {
                url: `https://api.fsggroup.io/ideas`,
                // url: `http://0.0.0.0:5001/ideas`,
                thumbnailWidth: 150,
                maxFilesize: 50,
                acceptedFiles: 'image/*',
                init: function() {
                    var myDropzone = this;
                },
                headers: {
                    "fsg-token": localStorage.getItem("fsg-token"),
                    "Access-Control-Allow-Origin": "*",
                },
                dictCancelUpload: true,
                dictUploadCanceled: true,
                dictInvalidFileType: true,
                uploadMultiple: true,
                parallelUploads: 10000,
                autoProcessQueue: false,
                addRemoveLinks: true,
            },
            currentPage: 1
      };
    },
    methods: {
        checkUploadFile: function(filename) {
                //do some input checking here, if you want
            return true;
        },
        clipboard_to_img: function(){
            $(document).ready(function () {
                FileReaderJS.setupClipboard(document.body, {
                    accept: {
                        'image/*': 'DataURL'
                    },
                    on: {
                        load: function(e, file) {
                        this.$refs.myVueDropzone.addFile(file);
                        }
                    }
                });
            });
        },
        add_idea: function(){
            this.idea.employee_id = this.employee_id
            this.idea.nick_id = this.nick_id
            if (this.$refs.myVueDropzone.getQueuedFiles().length){
                this.$refs.myVueDropzone.processQueue()
            }else {
                fsg.post('/ideas', this.idea)
                    .then(r => {
                        this.$vs.notification({
                            title: "Added",
                            color: "success"
                        })
                        this.ideas.push(this.idea)
                        this.modals.form = false
                        this.idea = {
                            title: '',
                            description: '',
                            name: '',
                        }
                    })
            }
        },
        before_add_idea: function(){
            if(this.nick_id == 'all'){
                this.$vs.notification({
                    title: "Vào phần nick chọn nick hoặc tạo nick sau đó click vào nick đó rồi mới tạo idea, không tạo trực tiếp từ trang này",
                    color: "danger"
                })
            }else{
                this.modals.form = true
                this.idea = {
                    title: '',
                    description: '',
                    name: '',
                }
            }
        },
        vdropzone_sending: function(file, xhr, formData){
            formData.append('title', this.idea.title)
            formData.append('description', this.idea.description)
            formData.append('nick_id', this.nick_id)
            formData.append('name', this.idea.name)
            formData.append('employee_id', this.employee_id)
            formData.append('id', this.idea.id)
        },
        confirm_idea: function(){
            let selected = this.ideas.filter((e) => e.selected == true)
            var data = []
            selected.forEach(e => data.push(e.id))
            fsg.put(`/ideas?confirm=oke`, {'data': data})
                .then(r => {
                    this.$vs.notification({
                        'title': 'Pushed',
                        'color': 'success'
                    })
                }
            )
        },
        update_idea: function(){
            if (this.$refs.myVueDropzone.getQueuedFiles().length){
                this.idea = this.edit
                this.$refs.myVueDropzone.processQueue()
            } else {
                fsg.put(`/ideas?idea_id=${this.edit.id}`, {
                    'name': this.edit.name,
                    'description': this.edit.description,
                })
                    .then(r => {
                        this.$vs.notification({
                            title: 'Upated',
                            color: 'success'
                        })
                    })
            }
        },
        delete_idea: function(id){
            fsg.delete(`/ideas?idea_id=${id}`)
                .then(r => {
                    this.$vs.notification({
                        title: "Deleted",
                        color: "success"
                    })
                })
        },
        slide_on: function(row){
            this.imgs = row.templates
            this.idea = row
            this.edit = this.idea
            this.modals.slide_template = true
        }

    },
    mounted: function(){
        this.nick_id = this.$route.params.nick_id
        this.employee_id = localStorage.getItem('id')
    },
    created: function(){
        $('#myslide').slick({
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000,
                dots: true,
            });
            fsg.get(`/ideas?nick_id=${this.$route.params.nick_id}`)
                .then(r => {this.ideas = r.data.msg})
    },
    watch: {
        current_img: function(){
            if (this.current_img === null){

            }else {
                this.imgs.splice(this.current_img, 1)
                this.idea.templates = this.imgs
                this.edit = this.idea
                fsg.put(`/ideas?idea_id=${this.edit.id}`, {
                    'name': this.edit.name,
                    'description': this.edit.description,
                    'templates': this.edit.templates
                })
                    .then(r => {
                        this.$vs.notification({
                            title: 'Upated',
                            color: 'success'
                        })
                    })
            }
        },
    }
}
</script>
<style lang="scss" scope>
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 250px;
  width: auto;
  min-width: 150px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
#dropzone2 {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 250px;
  width: auto;
  min-width: 150px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Images';
}
.truncate {
  width: auto;
  max-height: 50px;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fancybox {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    height: calc(100% - 80px);
    text-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 50px !important;
}
.test {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100px;
}
</style>
