<template>
  <div>
    <b-card>
      <b-container fluid>
        <div class="imgs-container">
          <div class='img-flex' v-for="(asin, index) in paginatedAsins" :key="index">
            <vs-card type="6">
              <template #title>
                <h3>SKU: {{ asin.sku }}</h3>
                <h5>QUANTITY: {{ asin.quantity }}</h5>
              </template>
              <template #img>
                <a :href="asin.url" target="_blank">
                  <img :src="asin.thumbnail != none ? asin.thumbnail: asin.url" alt="">
                </a>
              </template>
              <template #text>
                <router-link target="_blank" :to="{path: 'designs', query: {sku: asin.sku}}">
                  <strong>Detail</strong>
                </router-link>
              </template>
            </vs-card>
          </div>
        </div>
        <vs-pagination v-model="currentPage" :length="totalPages"></vs-pagination>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import fsg from "@/api/base";
export default {
  components: {},
  name: "ImageView",
  data() {
    return {
      asins: [
      ],
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  props: {},
  computed: {
    totalPages() {
      return Math.ceil(this.asins.length / this.itemsPerPage);
    },
    paginatedAsins() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      var results = []
      var foo = this.asins
      return foo.slice(start, end)
    },
  },
  methods: {
    updatePagination(page) {
      this.currentPage = page;
    },
    // Other methods...
  },
  created () {
    fsg.get('/inventories')
      .then(r => this.asins = r.data.asins)
  }
};
</script>
<style scoped>
/* Your style code */
.imgs-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.vs-card__img img{
  height: auto !important;
}
.img-flex {
  padding: 20px;
}
</style>
