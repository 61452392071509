<template>
<div>
  <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 50px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top; background-color: #2dce89">
    <vs-button
        @click="toggle_report = !toggle_report"
      >
        Add
    </vs-button>
    <vs-dialog width="100%" not-center v-model="toggle_report">
      <template #header>
        <h4 class="not-margin">
          Báo cáo công việc
        </h4>
      </template>
        <b-card>
            <b-row>
                <b-col xl="12">
                    <vs-input icon-after success v-model="report.volume_work" placeholder="Khối lượng công việc đã triển khai">
                    </vs-input>
                </b-col>
                <hr>
                <b-col xl="12">
                    <vs-input icon-after success v-model="report.processed" placeholder="Đã hoàn thành bao nhiêu">
                    </vs-input>
                </b-col>
                <hr>
                <b-col xl="12">
                    <vs-input icon-after success v-model="report.case" placeholder="Vấn đề cần cải thiện">
                    </vs-input>
                </b-col>
                <hr>
                <b-col xl="12">
                    <vs-input icon-after success v-model="report.suggest" placeholder="Đề xuất điều gì đó">
                    </vs-input>
                </b-col>
                <hr>
                <vs-select placeholder="Feeling" v-model="report.feeling">
                    <vs-option label="Tuyệt vời" value="Tuyệt vời">
                      Tuyệt vời
                    </vs-option>
                    <vs-option label="Bình thường" value="Bình thường">
                      Bình thường
                    </vs-option>
                    <vs-option label="Mệt mỏi" value="Mệt mỏi">
                      Mệt mỏi
                    </vs-option>
                </vs-select>
                <hr>
            </b-row>
            <vs-button
                @click="add_report"
                color="success"
                icon
            >
                okeeeeeeee
            </vs-button>
        </b-card>
      <template #footer>
        <div class="con-footer">
          <vs-button @click="add_report" transparent>
            Ok
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </base-header>

  <b-container fluid class="mt--7" v-if="['admin', 'mod'].includes(role)">
    <b-row>
      <b-col xl="4" class="order-xl-1">
        <b-card>
          <!-- <base-select required @change="selectedmail" :selected="forward_selected" id="" :options="options" textlabel="Email"></base-select> -->
          Employees
          <hr>
          <b-list-group>
            <b-list-group-item :active="w.id === selected" @click="change_employee(w.id)" v-for="w in employees" :key=w.id class="d-flex justify-content-between align-items-center">
              {{ w.name }}
              <!-- <b-badge variant="primary" pill>{{ value.length }}</b-badge> -->
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col xl="8" class="order-xl-1">
        <b-card>
          <b-row>
            <b-col xl="4">
              <vs-input
                type="date"
                v-model="point"
                label="Date"
              />
            </b-col>
          </b-row>
          <hr>
          <issue-table :issues="issues" :forward="forward_selected"></issue-table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import IssueTable from "@/views/Pages/Work/IssueTable";
import fsg from "@/api/base";
import {BaseSelect} from "@/components";
export default {
  name: "Gmail",
  components: {
    IssueTable,
    BaseSelect
  },
  data: () => ({
    resp: Object,
    issues: [],
    selected: '',
    forward_selected: 'forwardhoptac@gmail.com',
    employees: [],
    options: [
    ],
    role: localStorage.getItem('role'),
    toggle_report: false,
    report: {feeling: ''},
    point: '',
    works: []
  }),
  created() {
    fsg.get("/works")
      .then(r => {
        this.issues = r.data.works
        this.works = r.data.works
    })
    fsg.get("/employees?team=all")
      .then(r => {
        this.employees = r.data.message
      })
  },
  methods: {
    change_employee: function (employee_id){
      fsg.get(`/works?employee_id=${employee_id}`)
        .then(r => {
          this.issues = r.data.works
          this.works = r.data.works
          this.selected = employee_id
      })
    },
    selectedmail: function (val){
      this.forward_selected = val
    },
    add_report: function () {
      var body = {
        'name': localStorage.getItem('name'),
        'role': localStorage.getItem('role'),
        'team': localStorage.getItem('team'),
        'suggest': this.report.suggest,
        'processed': this.report.processed,
        'volume_work': this.report.volume_work,
        'feeling': this.report.feeling,
        'case': this.report.case
      }
      fsg.post(`/works`, body)
        .then(r => {
          location.reload()
        })
    }
  },
  watch: {
    point: function () {
      this.issues = this.works.filter(w => w.date === this.point)
    }
  }
}
</script>

<style scoped>

</style>