<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Welcome!</h1>
              <p class="text-lead text-white">If you have an idea, please tell us!</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pb-5"  >
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="model.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <b-form-checkbox v-model="model.rememberMe">Remember me</b-form-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import {EventBus} from "@/util";
  export default {
    data() {
      return {
        model: {
          email: '',
          password: '',
          rememberMe: false,
          errorMessage: ''
        },
      };
    },
    methods: {
      onComplete(){
        location.reload()
      },
      onSubmit() {
        this.$store.dispatch('authentication/login', this.model)
          .then(r => {
            if(localStorage.getItem('role') == 'admin') {
              this.$router.push("/dashboard", this.onComplete)
            }else if (localStorage.getItem('role') == 'crawler'){
              this.$router.push("/recontent", this.onComplete)
            }else if (localStorage.getItem('role') == 'cskh'){
              this.$router.push("/place", this.onComplete)
            }else if (localStorage.getItem('role') == 'designer'){
              this.$router.push("/redesign", this.onComplete)
            } else if (localStorage.getItem('role') == 'idea'){
              this.$router.push("/fsg-nicks", this.onComplete)
            } else if (localStorage.getItem('role') == 'leader'){
              this.$router.push("/fsg-nicks", this.onComplete)
            } else if (localStorage.getItem('role') == 'listing'){
              this.$router.push("/rbstore", this.onComplete)
            } else if (localStorage.getItem('role') == 'mod'){
              this.$router.push("/redesign", this.onComplete)
            } else if (localStorage.getItem('role') == 'free'){
              this.$router.push("/tiktoklisting", this.onComplete)
            }
          })
          // .then(r => {setTimeout(function (){location.reload()}, 500)})
      },
    },
    mounted() {
      EventBus.$on("failedAuthentication", (msg) => {
      })
    },
    beforeDestroy() {
      EventBus.$off('failedAuthentication')
    },
    computed: {
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      },
    }
  }
</script>
