<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 50px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top; background-color: #2dce89">
            <h1>Total {{ total }}</h1>
            <!-- <b-dropdown id="product" :text="product" class="m-md-2">
                <b-dropdown-item @click="product = p" v-for="(p, index) in products" :key="index">{{ p }}</b-dropdown-item>
            </b-dropdown> -->
        </base-header>
        <Label color="red" label="Ưu tiên"></Label>
        <modal
            :show.sync="modals.slide" 
            size="xl"
            body-classes="p-0">
            
            <div>
                <VueSlickCarousel :arrows="true" :dots="true">
                    <div v-for="url in imgs">
                        <b-img thumbnail fluid :src="url"></b-img>
                    </div>
                </VueSlickCarousel>
            </div>

        </modal>
        <modal
            :show.sync="modals.detail"
            body-classes="p-0"
            size="xl"
        >
            <b-card
                type="secondary"
                header-classes="bg-transperent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template slot="header">
                    Description
                </template>
                <template>
                    <base-input
                        v-model="edit.name"
                        class="font-weight-600 name mb-0 text-sm"
                        placeholder="Name"
                        prepend-icon="bx bx-rename">
                    </base-input>
                    <b-form-textarea
                        v-model="edit.description"
                        placeholder="Enter something..."
                        rows="20"
                        max-rows="100" 
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                </template>
            </b-card>
            <template slot="footer">
                <!-- <b-button variant="success" @click="update_idea">Update</b-button>
                <b-button @click="modals.des_detail = false">cancel</b-button> -->
                <hr>
            </template>
        </modal>
        <modal
            :show.sync="modals.upload"
            size="lg"
            body-classes="p-0"
        >
            <b-card
                type="secondary"
                header-classes="bg-transperent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template slot="header">
                    Upload images
                </template>
                <template>
                    <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
                </template>
            </b-card>
            <template slot="footer">
                <h3></h3>
            </template>
        </modal>
        <b-container fluid class="mt--7">
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-button variant="primary" @click="push_to_content">Oke</b-button>
                    <b-button variant="primary" @click="modals.upload = true" >Upload images</b-button>
                    <b-dropdown id="employee" text="Employee" class="m-md-2">
                        <b-dropdown-item @click="employee = e.name" v-for="(e, index) in employees" :key="index">{{ e.name }}</b-dropdown-item>
                    </b-dropdown>
                </b-card-header>

                <el-table class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="designs">
                    <el-table-column
                        width="60px"
                        prop="selected"
                    >
                        <template v-slot="{row}">
                            <b-form-checkbox v-model="row.selected" size="lg"></b-form-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column label="created"
                                    prop="_created"
                                    width="150px"
                                    sortable>
                        <template v-slot="{row}">
                            <span class="font-weight-600 name mb-0 text-sm">{{ row._created }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="SKU"
                                    prop="fsg_sku"
                                    width="150px"
                                    sortable>
                        <template v-slot="{row}">
                            <span @click="doCopy(row.fsg_sku)" class="font-weight-600 name mb-0 text-sm">{{ row.fsg_sku }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Name"
                                    min-width="200px"
                                    prop="name"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <!-- <a href="#" class="avatar rounded-circle mr-3">
                                    <img alt="Image placeholder" src="https://ih1.redbubble.net/image.819803303.4430/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg">
                                </a> -->
                                <b-media-body>
                                    <span class="font-weight-600 name mb-0 text-sm">{{ row.name }}</span>
                                </b-media-body>
                            </b-media>
                        </template>
                    </el-table-column>
                    <el-table-column label="Description"
                                    prop="description"
                                    min-width="250px"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-media-body>
                                    <span @click="modals.detail = true, edit=row" class="font-weight-600 name mb-0 text-sm truncate">{{ row.description }}</span>
                                </b-media-body>
                            </b-media>
                        </template>
                        
                    </el-table-column>

                    <el-table-column label="Users" width="150px">
                        <template v-slot="{row}">
                            <div class="avatar-group">
                                <!-- <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                data-original-title="Ryan Tompson">
                                    <img alt="Image placeholder" src="img/theme/team-1.jpg">
                                </a> -->
                                <span class="font-weight-600 name mb-0 text-sm truncate text-danger">{{ row.employee }}</span>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Image"
                                    prop="thumbnail_url"
                                    min-width="100px">
                        <template v-slot="{row}">
                            <div class="d-flex align-items-center">
                                <a :href="`#/designs?sku=${row.fsg_sku}`" target="_blank">
                                    <img :src="row.thumbnail_url" alt="" style="width: 100px; height: auto">
                                </a>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Template"
                                    prop="urls"
                                    min-width="100px"
                                    sortable>
                        <template v-slot="{row}">
                            <div class="d-flex align-items-center">
                                <img @click="slide_on(row)" class="thumbnail-fsg" :src="row.templates[0]" alt="">
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column min-width="60px">
                        <template>
                            <el-dropdown trigger="click" class="dropdown">
                            <span class="btn btn-sm btn-icon-only text-light">
                            <i class="fas fa-ellipsis-v mt-2"></i>
                            </span>
                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                    <b-dropdown-item @click="modals.des_detail = true">upload</b-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <b-card-footer class="py-4 d-flex justify-content-end">
                    <base-pagination v-model="currentPage" :per-page="1" :total="Math.floor(design.length/1)"></base-pagination>
                </b-card-footer> -->
            </b-card>
        </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
import fsg from "@/api/base";
import vueImages from 'vue-images/dist/vue-images'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Label from '../../../components/Label.vue';

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        vue2Dropzone,
        vueImages,
        VueSlickCarousel
    },
    data(){
        return {
            edit: Object,
            total: '',
            imgs: [],
            employees: [],
            products: [],
            product: 'FESOGO-TS',
            employee: '',
            modals: {
                upload: false,
                detail: false,
                slide: false,
            },
            employee_id: '',
            designs: [],
            dropzoneOptions: {
                url: `https://api.fsggroup.io/designs?by_fsg=oke`,
                // url: `http://0.0.0.0:5001/designs?by_fsg=oke`,
                thumbnailWidth: 150,
                maxFilesize: 50,
                acceptedFiles: 'image/*',
                headers: {
                    "fsg-token": localStorage.getItem("fsg-token"),
                    "Access-Control-Allow-Origin": "*",
                },
                dictCancelUpload: true,
                dictUploadCanceled: true,
                dictInvalidFileType: true,
                uploadMultiple: true,
                parallelUploads: 10000,
            },
            currentPage: 1
      };
    },
    methods: {
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })},
        push_to_content: function(){
            let selected = this.designs.filter((e) => e.selected == true)
            var data = []
            selected.forEach(e => data.push(e.fsg_sku))
            fsg.put(`/fsg-designs?confirm=oke`, {'skus': data})
                .then(r => {
                    this.$vs.notification({
                        'title': 'Pushed',
                        'color': 'success'
                    })
                })

        },
        slide_on: function(row){
            this.imgs = []
            row.templates.forEach(url => this.imgs.push(url))
            this.modals.slide_template = true
        },
    },
    mounted: function(){
        this.employee_id = localStorage.getItem('id')
    },
    created: function(){
        fsg.get(`/fsg-designs?product=FESOGO-TS`)
            .then(r => {this.designs = r.data.msg, this.total = r.data.total})
        fsg.get('/employees?role=designer')
            .then(r => {this.employees = r.data.message})
        fsg.get('/fesogo_products')
            .then(r => {this.products = r.data.msg})
    },
    watch: {
        employee: function(){
            var selected = []
            this.designs.forEach(e => {
                if(e.selected){
                    selected.push(e.id)
                }
            })
            fsg.put(`/fsg-designs?action=assign`, {'name': this.employee, 'ids': selected})
                .then(r => {this.$vs.notification({
                    title: 'Assigned',
                    color: 'success'
                })})
            this.employee = ''
        },
        product: function(){
            this.dropzoneOptions.url = `https://api.fsggroup.io/designs?type=${this.product}&by_fsg=oke`
            fsg.get(`/fsg-designs?product=${this.product}`)
                .then(r => {this.designs = r.data.msg})
        }
    }
}
</script>
<style lang="scss" scope>
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 250px;
  width: auto;
  min-width: 150px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Images';
}
.truncate {
  width: auto;
  max-height: 500px;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fancybox {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    height: calc(100% - 80px);
    text-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 100px !important;
    margin-right: 100px !important;
    margin-bottom: 100px !important;
}
</style>
