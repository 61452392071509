<template>
    <div>
        <vs-dialog width="600px" v-model="add_toggle">
            <template #header>
                <h4 class="not-margin">
                    <b>Get asin {{ product }}</b>
                </h4>
            </template>
            <b-container>
                <b-card>
                    <b-row>
                        <b-col xl="12">
                            <vs-input icon-after success v-model="asin" placeholder="Asin">
                                <template #icon>
                                    <i class='bx bxl-amazon'></i>
                                </template>
                            </vs-input>
                        </b-col>
                        <hr>
                        <b-col xl="12">
                            <products label="Select product" @change="update_dropzone" class="flex-item"></products>
                        </b-col>
                        <hr>
                        <b-col v-if="allow_dropzone == true">
                            <vue2Dropzone class="upload" id="duplicate" :options="duplicate"></vue2Dropzone>
                        </b-col>

                    </b-row>
                    <vs-button
                            :loading="downloading"
                        @click="add_asin"
                        color="warn"
                        icon
                    >
                        Add
                    </vs-button>
                </b-card>
            </b-container>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="editActive">
            <template #header>
            <h4 class="not-margin">
                <b>Edit note</b>
            </h4>
            </template>
            <base-input @keypress.enter="update_note" v-model="edit_note" />
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="delete_confirm">
            <template #header>
            <h4 class="not-margin">
                <b>Do you want delete it {{ edit.asin }}?</b>
            </h4>
            </template>

            <template #footer>
            <div>
                <vs-button @click="delete_asin" transparent>
                    Ok
                </vs-button>
                <vs-button @click="delete_confirm = false" dark transparent>
                    Cancel
                </vs-button>
            </div>
            </template>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload">
          <b-container fluid="">
            <h3 align="center">Upload design</h3>
            <products label="Select product" @change="update_dropzone" class="flex-item"></products>
            <div v-if="allow_dropzone">
              <p style="color: darkred">Mỗi lượt up từng file cho từng asin</p>
              <hr size="3">
              <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>
        <b-container fluid>
            <b-card>
                <b-row>
                    <b-col xl="2">
                        <vs-button
                             :loading="downloading"
                            @click="add_toggle = !add_toggle"
                            color="success"

                            icon
                        >
                           Add 
                        </vs-button>
                    </b-col>
                    <b-col xl="2">
                        <vs-button
                            @click="push_to_recontent"
                            color="warn"
                            icon
                        >
                           Push to recontent 
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-select placeholder="Employee" v-model="selected_employee">
                            <vs-option v-for="(e, idx) in employees" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                            {{ e.name }}
                            </vs-option>
                        </vs-select>

                    </b-col>
                    <b-col>
                        <vs-input @keypress.enter="search_asin" v-model="search" placeholder="Search asin" />
                    </b-col>
                    <hr>
                    <b-col xl="12">
                        <vs-table>
                            <template #thead>
                            <vs-tr>
                                <vs-th>
                                    <b-form-checkbox
                                        v-model="check_all"
                                        >
                                    </b-form-checkbox>
                                </vs-th>
                                <vs-th class='vs-td-ssx'>
                                    Upload
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Amazon
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Image
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Note
                                </vs-th>
                            </vs-tr>
                            </template>
                            <template #tbody>
                            <vs-tr
                                :key="i"
                                v-for="(tr, i) in asins"
                                :data="tr"
                            >
                                <vs-td class="vs-td-basic">
                                    <b-form-checkbox
                                        v-model="tr.selected"
                                        >
                                    </b-form-checkbox>
                                </vs-td>
                                <vs-td class='vs-td-ssx'>
                                    <vs-button
                                        warn
                                        gradient
                                        @click="upload = true"
                                        icon
                                    >
                                        <i class='bx bx-upload' ></i>
                                    </vs-button>
                                    <vs-button
                                        danger
                                        gradient
                                        @click="edit = tr, delete_confirm = true"
                                        icon
                                    >
                                        <i class='bx bx-trash-alt' ></i>
                                    </vs-button>
                                </vs-td>
                                <vs-td class="vs-td-sx">
                                    <vs-td class="fix-border" style="width: 2% !important">
                                        <a :href="tr.secondary_url" target="_blank">
                                            <img style="width: 100px; height: auto" :src="tr.secondary_url" alt="">
                                        </a>
                                        <div v-if="tr.is_amazon === true">
                                            <a :href="`https://www.amazon.com/gp/product/${tr.origin_asin}`" target="_blank">
                                                <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <a :href="tr.secondary_url" target="_blank">
                                                <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                            </a>
                                        </div>
                                        
                                    </vs-td>
                                    <vs-td class="fix-border">
                                            <div style="display: inline">
                                                <strong class="a-green">{{ tr.date_assign }} </strong>
                                            </div>
                                            <div>
                                                <strong class="a-orange">{{ tr.employee }}</strong>
                                            </div>

                                    </vs-td>
                                </vs-td>
                                <vs-td class='vs-td-ssx'>
                                    <vs-td class="fix-border">
                                        <div>
                                            <router-link  target="_blank" :to="{path: 'designs', query: {sku: tr.asin}}">
                                                <img style="width: 100px; height: auto" :src="tr.thumbnail_url" alt="">
                                            </router-link>
                                            <h3 style="color:darkorange" v-if="tr.team == 'designer'">{{ tr.employee_name}}</h3>
                                        </div>
                                    </vs-td>
                                    <vs-td class="fix-border">
                                        <div>
                                            <strong class="a-yellow">{{ tr.date_upload }}</strong>
                                        </div>
                                    </vs-td>
                                </vs-td>
                                <vs-td edit @click="edit = tr, editActive = true, edit_note = edit.note, editProp = 'note'" class='vs-td-sx'>
                                    <strong @click="doCopy(tr.note)">{{ tr.note }}</strong>
                                </vs-td>
                            </vs-tr>
                            </template>
                        </vs-table>
                    </b-col>
                <hr>
                </b-row>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import fsg from "@/api/base";
import Products from '../../../components/Products.vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
export default {
  name: "redesign",
  components: {
    Products,
    vue2Dropzone
  },
    data: () => ({
        edit_asin: '',
        edit_note: '',
        search: '',
        url: '',
        title: '',
        employee_id: localStorage.getItem('id'),
        role: localStorage.getItem('role'),
        note: '',
        product: '',
        add_toggle: false,
        editing: false,
        selected_employee: '',
        check_all: false,
        scopes: ['mod', 'admin'],
        asin: '',
        employees: [],
        editActive: false,
        editProp: '',
        asins: [],
        delete_confirm: false,
        edit: Object,
        downloading: false,
        allow_dropzone: false,
        upload: false,
        dropzoneOptions: {
            url: `https://api.fsggroup.io/designs?redesign=oke`,
            // url: `http://0.0.0.0:5001/designs?redesign=oke`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'image/*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: true,
            dictUploadCanceled: true,
            dictInvalidFileType: true,
            uploadMultiple: true,
            parallelUploads: 10000,
        },
        dropzonecontent: {
            url: `https://api.fsggroup.io/redesign/upload_content`,
            // url: `http://0.0.0.0:5001/redesign/upload_content`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'text/csv',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: true,
            dictUploadCanceled: true,
            dictInvalidFileType: true,
            maxFiles: 1,
        },
        duplicate: {
            url: 'https://api.fsggroup.io/hijack',
            // url: 'http://0.0.0.0:5001/hijack',
            thumbnailWidth: 150,
            maxFilesize: 30,
            acceptedFiles: '*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                // "type": "sku-asin",
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            maxFiles: 1,
            dictDefaultMessage: 'Upload asin'
        },

    }),
    methods: {
        search_asin: function(){
            fsg.get(`redesign?asin=${this.search}`)
                .then(r => {this.asins = r.data.msg})
        },
        push_to_recontent: function(){
            let selected = this.asins.filter((e) => e.selected == true)
            var data = []
            selected.forEach(e => data.push(e.asin))
            fsg.put(`/redesign?confirm_design=oke`, {'data': data})
                .then(r => {
                    this.$vs.notification({
                        'title': 'Push to redesign',
                        'color': 'success'
                    })
                })
        },
        update_product: function(){
            fsg.put('/update_product', this.edit)
                .then(r => {
                    console.log("hihi")
                })
        },
        add_asin: function(){
            this.downloading = true
            fsg.post(`/hijack?asin=${this.asin}&type=${this.product}`)
                .then(r => {
                    this.asins.unshift.apply(this.asins, r.data.msg),
                    this.downloading = false
                    this.upload = false
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        update_dropzone: function (val){
            this.product = val
            this.dropzoneOptions.url = `https://api.fsggroup.io/designs?type=${val}`
            this.duplicate.url = `https://api.fsggroup.io/hijack?type=${val}`
            // this.duplicate.url = `http://0.0.0.0:5001/hijack?type=${val}`
            this.allow_dropzone = !this.allow_dropzone
            // this.allow_dropzone = true
        },
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })
        },
        delete_asin: function(){
            fsg.delete(`/redesign?asin=${this.edit.asin}`)
                .then(r => {
                    this.delete_confirm = !this.delete_confirm
                    this.$vs.notification({
                        title: 'Deleted',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Failed',
                        color: 'danger'
                    })

                })
        },
        update_note: function(){
            this.edit.note = this.edit_note
            fsg.put(`/redesign?asin=${this.edit.asin}&note=${this.edit.note}`)
                .then(r => {
                    this.$vs.notification({
                        title: 'Note updated',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Note update failed',
                        color: 'success'
                    })
                })
        }
    },
    beforeDestroy() {
        this.sockets.unsubscribe('assign_designer')
    },
    created() {
        fsg.get('/hijack')
            .then(r => { this.asins = r.data.products;  if (this.role == 'designer'){this.asins = this.asins.filter(e => e.employee_id == this.employee_id)}})
        fsg.get('/employees?team=designer')
            .then(r => {this.employees = r.data.message})
    },
    watch: {
        check_all: function(){
            this.asins.forEach(e => e.selected = !e.selected)
        },
        add_toggle: function () {
            if (this.add_toggle == false) {
                this.allow_dropzone = false
            }
        },
        selected_employee: function(){
            if (this.selected_employee == ''){
                return
            }
            let selected = this.asins.filter((e) => e.selected == true)
            let data = []
            selected.forEach(e => data.push(e.asin))
            fsg.put(`/redesign?action=update_employee`, {'asins': data, 'employee': this.selected_employee})
                .then(r => {
                    this.$vs.notification({
                        title: 'Updated employee',
                        color: 'success'
                    })
                })
        }
    }
}
</script>
<style scoped>

</style>
<style lang="scss" scope>
.vs-td-sx {
  width: 2%;
}
.vs-td-ssx {
  width: 1%;
}
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
  align-content: center;
}
.vs-table__tr .vs-table__td:last-child {
  width: 2%;
  border-right: none;
}
.vs-table__td:first-child {
    border-radius: 15px 0px 0px 15px;
    width: 1%;
}
.vs-table__td:last-child {
  width: 5%;
  border-radius: 15px 0px 0px 15px;
}
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 500px;
  padding: 40px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.fix-border {
    border: none !important
}
</style>
