<template>
  <div>
    <vs-dialog :loading="requesting" full-screen scroll v-model="active" style="overflow-y: scroll;">
      <template #header>
        <h4 align="center">
          Inventory
        </h4>
      </template>
      <div class="con-content">
        <b-container class="justify-content-center full-width">
          <div>
                <vs-table
                v-model="selected"
                >
                <template #header>
                <vs-input v-model="search" border placeholder="Search" />
                </template>
                <template #thead>
                <vs-tr>
                <vs-th>
                <vs-checkbox
                :indeterminate="selected.length == asins.length" v-model="allCheck"
                @change="selected = $vs.checkAll(selected, asins)"
                />
                </vs-th>
                <vs-th sort @click="asins = $vs.sortData($event ,asins, 'item-name')">
                Item-Name
                </vs-th>
                <vs-th sort @click="asins = $vs.sortData($event ,asins, 'asin1')">
                Asin1
                </vs-th>
                <vs-th sort @click="asins = $vs.sortData($event ,asins, 'seller-sku')">
                Seller-sku
                </vs-th>
                <vs-th sort @click="asins = $vs.sortData($event ,asins, 'quantity')">
                Quantity
                </vs-th>
                <vs-th sort @click="asins = $vs.sortData($event ,asins, 'price')">
                Price
                </vs-th>
                </vs-tr>
                </template>
                <template #tbody>
                <vs-tr
                :key="i"
                v-for="(tr, i) in $vs.getPage($vs.getSearch(asins, search), page, max)"
                :data="tr"
                :is-selected="!!selected.includes(tr)"
                not-click-selected
                open-expand-only-td
                >
                <vs-td checkbox>
                <vs-checkbox :val="tr" v-model="selected" />
                </vs-td>
                <vs-td>
                {{ tr["item-name"] }}
                </vs-td>
                <vs-td>
                {{ tr.asin1 }}
                </vs-td>
                <vs-td>
                {{ tr["seller-sku"] }}
                </vs-td>
                <vs-td>
                {{ tr.quantity }}
                </vs-td>
                <vs-td>
                {{ tr.price }}
                </vs-td>

                <template #expand>
                <div class="con-content">
                <div>
                <p>
                {{ tr.name }}
                </p>
                </div>
                <div>
                <vs-button flat icon>
                <i class='bx bx-lock-open-alt' ></i>
                </vs-button>
                <vs-button flat icon>
                Remove asin
                </vs-button>
                <vs-button border danger>
                Remove option
                </vs-button>
                </div>
                </div>
                </template>
                </vs-tr>
                </template>
                <template #footer>
                <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(asins, search), max)" />
                </template>
                </vs-table>
                </div>
        </b-container>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import fsg from "@/api/base";
export default {
  name: "inventory",
  data: () => ({
    active: false,
    edit: null,
    search: '',
    asins: null,
    page: 1,
    max: 100,
    selected: [],
    allCheck: false,
    requesting: true
  }),
  props: {
    show: Boolean,
    seller: Object
  },
  watch: {
    show: function (){
      this.active = true
    },
    seller: function (){
      fsg.get(`/inventory?seller_id=${this.seller.seller_id}`)
          .then(r => {this.asins = r.data.msg, this.requesting = false})
    },
    active: function (){
      this.asins = null
      this.requesting = true
    }
  },
}
</script>
<style scoped>
.con-content, .con-content>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
}
</style>