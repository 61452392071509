<template>
    <b-container fluid="">
    <h3 align="center">Upload design</h3>
    <div >
        <p style="color: darkred">Upload images below</p>
        <vs-select placeholder="Mockup type" v-model="mockup_type">
            <vs-option value="poster">
            Poster
            </vs-option>
            <vs-option value="tote">
            Tote 
            </vs-option>
            <vs-option value="u-shower-curtain">
            Shower-curtain
            </vs-option>
            <vs-option value="pillow">
            Pillow
            </vs-option>
            <vs-option value="phone-case">
            Phone case
            </vs-option>
            <vs-option value="poster">
            Poster
            </vs-option>
        </vs-select>
        <hr size="3">
        <vue2Dropzone  v-if=allow id="dropzone" :options="dropzoneOptions" @vdropzone-success-multiple="vdropzone_success"></vue2Dropzone>
    </div>
    </b-container>
</template>
<script>
import fsg from "@/api/base";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
export default {
  name: "redesign",
  components: {
    vue2Dropzone
  },
    data: () => ({
        mockup_type: '',
        allow: false,
        results: [],
        dropzoneOptions: {
            url: `https://api.fsggroup.io/gen_mockups?mockup_type=""`,
            // url: `http://0.0.0.0:5001/gen_mockups?mockup_type=""`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'image/*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            uploadMultiple: true,
            parallelUploads: 10000,
            maxFiles: 20,
        },
    }),
    methods: {
        vdropzone_error: function(file){
            this.$vs.notification({
                title: `Upload file ${file.name} error`
            })
        },
        vdropzone_success: function(file, resp){
            this.results = resp.msg
            this.export_csv()
        },
        make_sku: function () {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 8; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            this.doCopy(result)
        },
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })
        },
        export_csv: function(){
            let content = ''
            this.results.forEach((e) => {
                content += e.join(",")
                content += "\n"
            })
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
            anchor.target = '_blank';
            anchor.download = 'result.csv';
            anchor.click();
        },
    },
    created() {
    },
    watch: {
        mockup_type: function (val){
            this.dropzoneOptions.url = `https://api.fsggroup.io/gen_mockups?mockup_type=${this.mockup_type}`
            // this.dropzoneOptions.url = `http://localhost:5001/gen_mockups?mockup_type=${this.mockup_type}`
            this.allow = true
        },
    }
}
</script>
<style scoped>

</style>
<style lang="scss" scope>
.tags {

    background-color: #ffd600;
    padding: 2px 5px 2px 5px;
    border-radius: 2px;
    box-shadow: #777;
    border: 1px;
    margin-block: 5px;
}
.vs-td-sx {
  width: 2%;
}
.vs-td-ssx {
  width: 1%;
}
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
  align-content: center;
}
.vs-table__tr .vs-table__td:last-child {
  width: 2%;
  border-right: none;
}
.vs-table__td:first-child {
    border-radius: 15px 0px 0px 15px;
    width: 1%;
}
.vs-table__td:last-child {
  width: 5%;
  border-radius: 15px 0px 0px 15px;
}
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 500px;
  padding: 40px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.fix-border {
    border: none !important
}
.form-control {
    // width: auto;
    margin: auto;
}
.image-card {
    width: 100%;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
    margin-top: 10px;
    background-color: rgb(238, 238, 238);
    border: 0px;
    border-radius: 5px;
}
.image-card-detail-item {
    margin-left: 10px;
}
</style>