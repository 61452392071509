<template>
  <div>
    <b-container fluid>
    <vs-dialog not-center width="600px" v-model="upload">
      <b-container fluid="">
        <h3 align="center">Upload design</h3>
        <products label="Select product" @change="update_dropzone" class="flex-item"></products>
        <div v-if="allow_dropzone">
          <p style="color: darkred">Chú ý upload tối đa 1 lượt 20 file, file dưới 50Mb</p>
          <hr size="3">
          <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
        </div>
      </b-container>
    </vs-dialog>
    <vs-dialog not-center scroll width="600px" v-model="editActive">
      <template #header>
          Change Prop {{ editProp }}
      </template>
      <div v-if="editProp == 'AmazonOrderId' && scopes.includes(role)" class="con-content">
        <b-container class="address-dialog">
          <b-row class="justify-content-center">
            <b-col class="fix-col" xl="12" lg="5" md="1">
              <b-card no-body class="bg-secondary border-0 mb-1">
                <vs-select placeholder="Select" v-model="detail.Status">
                  <vs-option label="Unshipped" value="Unshipped">
                    Unshipped
                  </vs-option>
                  <vs-option label="Processed" value="Processed">
                    Processed
                  </vs-option>
                  <vs-option label="error" value="error">
                    error
                  </vs-option>
                  <vs-option label="in_queue" value="in_queue">
                    in_queue
                  </vs-option>
                  <vs-option label="manually" value="manually">
                    manually
                  </vs-option>
                  <vs-option label="cancel" value="cancel">
                    cancel
                  </vs-option>
                  <vs-option label="deactive" value="deactive">
                    deactive
                  </vs-option>
                </vs-select>
                <base-input alternative
                            v-model="detail.Printer"
                            label="Printer"
                >
                </base-input>
                <base-input alternative
                            name="Name"
                            v-model="detail.Name"
                            label="Name"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.order_fulfill_id"
                            label="Order fulfill id"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.Tracking"
                            label="Tracking"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.AddressLine1"
                            label="Address1"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.AddressLine2"
                            label="Address2"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.City"
                            label="City"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.StateOrRegion"
                            label="State Or Region"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.Phone"
                            label="Phone"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.Zip"
                            label="Zip code"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.CountryCode"
                            label="Country code"
                >
                </base-input>
                <base-input alternative
                            v-model="detail.total"
                            label="total"
                >
                </base-input>
                <base-button @click="update_order">Update</base-button>
                <hr>
                <base-button @click="replace_order">Replace new order</base-button>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </vs-dialog>
    <b-card>
      <slot name="header">
        <div class="flex-container">
          <div class="flex-item">
            <vs-select placeholder="Change to" v-model="more">
              <vs-option
                  label="Miss Tracking"
                  value="miss_tracking"
              >
                Miss tracking
              </vs-option>
            </vs-select>
          </div>
          <div class="flex-item">
            <vs-select
                placeholder="Printer"
                color="warn"
                v-model="printer"
            >
              <vs-option label="Auto" value="auto">
                Auto
              </vs-option>
              <vs-option label="Printify" value="printify">
                Printify
              </vs-option>
              <vs-option label="Scalable" value="scalable">
                Scalable
              </vs-option>
              <vs-option label="Customcat" value="customcat">
                Customcat
              </vs-option>
            </vs-select>
          </div>
          <div>
            <vs-button
                danger
                active
                border
                @click="upload = true"
                class="flex-item"
            >
              Upload design
            </vs-button>
            <vs-button
              @click="place_orders"
              warn
            >
              Place
            </vs-button>
          </div>
          <hr>
        </div>
      </slot>
      <div>
      <vs-table ref="orders">
        <template #header>
          <vs-input @keypress.enter="search_orders" v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              <vs-checkbox
                :indeterminate="selected.length == orders.length" v-model="allCheck"
                @change="selected = $vs.checkAll(selected, orders)"
              />
            </vs-th>
            <vs-th>
              AmazonOrderId
            </vs-th>
            <vs-th>
              Processed
            </vs-th>
            <vs-th>
              Seller
            </vs-th>
            <vs-th>
              Owner
            </vs-th>
            <vs-th>Order fulfill id</vs-th>
            <vs-th>Printer</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Error log</vs-th>
            <vs-th>Product</vs-th>
            <vs-th>Sku</vs-th>
            <vs-th>Asin</vs-th>
            <vs-th>Thumbnail</vs-th>
            <vs-th>Options</vs-th>
            <vs-th>Quantity</vs-th>
            <vs-th>Total</vs-th>
            <vs-th>Charged</vs-th>
            <vs-th>Tracking</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(o, i) in $vs.getPage($vs.getSearch(orders, search), page, max)"
            :data="o"
          >
            <vs-td checkbox>
              <vs-checkbox :val="o" v-model="selected" />
            </vs-td>
            <vs-td :class="{'background-warn': o.many_option_in_sku == true, 'background-red': o.ShipmentServiceLevelCategory == 'Expedited'}" edit @click="AmazonOrderId = o.AmazonOrderId, edit = o, editProp = 'AmazonOrderId', editActive = true">
              {{ o.AmazonOrderId }}
            </vs-td>
            <vs-td>
            {{ o.processed_at }}
            </vs-td>
            <vs-td :class="{'background-green': o.is_hijack == true}">
              {{ o.seller_name }}
            </vs-td>
            <vs-td>
              {{ o.author }}
            </vs-td>
            <vs-td v-if="o.Printer == 'printify'">
              <a target="_blank" :href="`https://printify.com/app/order/${o.order_fulfill_id}`">{{ o.order_fulfill_id }}</a>
            </vs-td>
            <vs-td v-else-if="o.Printer == 'scalable'">
              <a target="_blank" :href="`https://scalablepress.com/manager/v2/orders/${o.order_fulfill_id}`">{{ o.order_fulfill_id }}</a>
            </vs-td>
            <vs-td v-else-if="o.Printer == 'customcat'">
              <a target="_blank" href="https://customcat-beta.mylocker.net/?t=d.dashboard&shopify_id=94920">{{ o.order_fulfill_id }}</a>
            </vs-td>
            <vs-td v-else>
              {{ o.order_fulfill_id }}
            </vs-td>
            <vs-td>
              {{ o.Printer }}
            </vs-td>
            <vs-td :class="{'background-red': o.Status == 'error', 'background-warn': o.Status == 'in_queue'}">{{ o.Status }}</vs-td>
            <vs-td>{{ o.error }}</vs-td>
            <vs-td>{{ o.product }}</vs-td>
            <vs-td>{{ o.sku }}</vs-td>
            <vs-td class="vs-td-nm-fixed">
              <div v-if="o.MarketplaceId == 'ATVPDKIKX0DER'">
                <a :href="`https://www.amazon.com/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto" :src="o.secondary_url" alt="None"></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A1AM78C64UM0Y8'">
                <a :href="`https://www.amazon.com.mx/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A2EUQ1WTGCTBG2'">
                <a :href="`https://www.amazon.ca/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A1F83G8C2ARO7P'">
                <a :href="`https://www.amazon.co.uk/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A1PA6795UKMFR9'">
                <a :href="`https://www.amazon.de/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A1RKKUPIHCS9HS'">
                <a :href="`https://www.amazon.es/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A13V1IB3VIYZZH'">
                <a :href="`https://www.amazon.fr/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'APJ6JRA9NG5V4'">
                <a :href="`https://www.amazon.it/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A1805IZSGTT6HS'">
                <a :href="`https://www.amazon.nl/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A2NODRKZP88ZB9'">
                <a :href="`https://www.amazon.se/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A33AVAJ2PDY3EV'">
                <a :href="`https://www.amazon.tr/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A17E79C6D8DWNP'">
                <a :href="`https://www.amazon.sa/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A1C3SOZRARQ6R3'">
                <a :href="`https://www.amazon.pl/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else-if="o.MarketplaceId == 'A21TJRUUN4KGV'">
                <a :href="`https://www.amazon.ie/gp/product/${o.asin}`" target="_blank"><img style="width: 100px; height: auto"  :src="o.secondary_url" alt=""></a>
              </div>
              <div v-else>
                <a :href="o.secondary_url" target="_blank"><img style="width: 100px; height: auto" :src="o.secondary_url" alt="None"></a>
              </div>
            </vs-td>
            <vs-td class="no-radius">
              <div>
                <router-link  target="_blank" :to="{path: 'designs', query: {sku: o.sku, AmazonOrderID: o.AmazonOrderId}}">
                  <img style="width: 100px; height: auto" :src="o.thumbnail_url" :alt="o.thumbnail_url">
                </router-link>
              </div>
            </vs-td>
            <vs-td>
              <p v-for="(opt, opt_id) in o.options" :key="opt_id">{{ opt }}</p>
            </vs-td>
            <vs-td>{{ o.quantity }}</vs-td>
            <vs-td>{{ o.total }}</vs-td>
            <vs-td>{{ o.charged }}</vs-td>
            <vs-td>
              <a :href="`https://t.17track.net/en#nums=${o.Tracking}`" target="_blank">{{ o.Tracking }}</a>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(orders, search), max)" />
        </template>
      </vs-table>
      </div>
    </b-card>
    </b-container>
  </div>
</template>

<script>
import fsg from '../../../api/base'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
import Products from "@/components/Products";
export default {
  components: {
    Products,
    vue2Dropzone,
    Products
  },
  name: "History",
  data: () => ({
    more: '',
    upload: false,
    printer: 'auto',
    selected: [],
    page: 1,
    max: 100,
    orders: [],
    search: '',
    allow_dropzone: false,
    allCheck: '',
    role: localStorage.getItem('role'),
    scopes: ['admin', 'role', 'cskh', 'mod'],
    AmazonOrderId: '',
    detail: Object,
    edit: {},
    editProp: '',
    editActive: false,
    dropzoneOptions: {
      url: `https://api.fsggroup.io/designs`,
      thumbnailWidth: 150,
      maxFilesize: 50,
      acceptedFiles: 'image/*',
      headers: {
        "fsg-token": localStorage.getItem("fsg-token"),
        "Access-Control-Allow-Origin": "*",
      },
      dictCancelUpload: false,
      dictUploadCanceled: false,
      dictInvalidFileType: false,
      maxFiles: 20,
    }
  }),
  created: function(){
    const loading = this.$vs.loading({
      target: this.$refs.orders,
      color: '#d5397b'})
    fsg.get('/history')
      .then(r => {this.orders = r.data.msg, loading.close()})
  },
  methods: {
    place_orders: function (){
      let order_ids = []
      this.selected.forEach(o => {
        order_ids.push(o.AmazonOrderId)
        this.orders.splice(this.orders.findIndex(order => order.AmazonOrderId == o.AmazonOrderId), 1)
      })
      this.selected = []
      fsg.post('/orders/place', {order_ids: order_ids, printer: this.printer})
        .then(r => this.$vs.notification({
          title: 'Orders in queue',
          color: 'success'
        }))
    },
    replace_order: function (){
      fsg.post(`/replace-order?AmazonOrderId=${this.edit.AmazonOrderId}`)
        .then(r => {
          this.$vs.notification({
            title: "Placed",
            color: "success"
          })
        })
    },
    update_dropzone: function (val){
      this.dropzoneOptions.url = `https://api.fsggroup.io/designs?type=${val}`
      this.allow_dropzone = true
    },
    update_order: function (){
      fsg.put(`/orders/${this.AmazonOrderId}`, this.detail)
        .then(r => this.$vs.notification({
          title: "Updated"
        }))
    },
    search_orders: function(){
      const loading = this.$vs.loading({
        target: this.$refs.orders,
        color: '#d5397b'})
      fsg.get(`/history?AmazonOrderId=${this.search}`)
        .then(r => {this.search = '', this.orders = r.data.msg, loading.close()})
    },
    get_misstracking: function(){
      this.page = 1
      const loading = this.$vs.loading({
        target: this.$refs.orders,
        color: '#d5397b'})
      fsg.get(`/history?tracking=missing`)
        .then(r => {this.orders = r.data.msg, loading.close()})
    }
  },
  watch: {
    more: function(){
      if (this.more == 'miss_tracking'){
        this.get_misstracking()
      }
    },
    AmazonOrderId: function () {
      fsg.get(`/orders/${this.AmazonOrderId}`)
          .then(r => {
            this.detail = r.data.msg
          })
    },
  }
}
</script>

<style scope>
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.flex-container > div {
  padding: 10px;
  display: flex;
}
.vs-select__options__content {
    max-height: 400px !important;
    overflow: auto;
    height: auto;
    z-index: 100;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    position: relative;
    scroll-behavior: smooth;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
  font-size: 13px;
  font-weight: inherit !important;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.vs-table__tr .vs-table__td:last-child {
  border-right: none;
}
.vs-table__td:first-child {
    width: 2%;
    border-radius: 15px 0px 0px 15px;
}
.vs-table__td:last-child {
  width: 1%;
  border-radius: 15px 0px 0px 15px;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
  width: 100%;
  margin-left: 0px;
}
body {
  font-size: 13px !important;
  font-weight: bolder !important;
}
.no-radius {
  border-radius: 0px !important;
  background-image: url('https://fsg-products.s3-ap-southeast-1.amazonaws.com/png-transparent-background-1-1.png') !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  background-color: transparent !important;
  align-items: center;
}
.fix-col {
  padding-right: -15px;
  margin-right: 0px;
}
.vs-checkbox-con {
  width: 50px;
  height: 50px;
  border-radius: 9px;
  position: relative;
  z-index: 1;
}
</style>
