<template>
  <vs-dialog scroll width="1000px" not-center v-model="sactive">
    <template #header>
      <h4 class="not-margin" align="center">
        <b>Update seller</b>
      </h4>
    </template>


    <div class="con-content">
      <b-container>
        <b-row class="justify-content-center">
          <b-col class="fix-col" xl="12" lg="5" md="1">
            <b-card no-body class="bg-secondary border-0 mb-1">
<!--              <b-form>-->
                <base-input alternative
                            name="Seller ID"
                            prepend-icon="bx bx-id-card"
                            placeholder="Seller id"
                            v-model="seller.seller_id"
                            label="Seller ID"
                >
                </base-input>

                <base-input alternative
                            name="Seller name"
                            prepend-icon="bx bx-rename"
                            placeholder="Seller name"
                            v-model="seller.name"
                            v-if="scopes.includes(role)"
                            label="Seller name"
                >
                </base-input>

                <base-input alternative
                            name="URL"
                            prepend-icon="bx bx-link-alt"
                            placeholder="URL"
                            v-model="seller.health_url"
                            v-if="scopes.includes(role)"
                            label="URL"
                >
                </base-input>

                <base-input alternative
                            prepend-icon="bx bx-cookie"
                            placeholder="LWA APP ID"
                            v-model="seller.lwa_app_id"
                            v-if="scopes.includes(role)"
                            label="LWA APP ID"
                >
                </base-input>
                <base-input alternative
                            prepend-icon="bx bx-cookie"
                            placeholder="LWA CLIENT SECRET"
                            v-model="seller.lwa_client_secret"
                            v-if="scopes.includes(role)"
                            label="LWA CLIENT SECRET"
                >
                </base-input>
                <base-input alternative
                            prepend-icon="bx bx-cookie"
                            placeholder="REFRESH TOKEN"
                            v-model="seller.refresh_token"
                            v-if="scopes.includes(role)"
                            label="REFRESH TOKEN"
                >
                </base-input>
                <base-input alternative
                            prepend-icon="bx bx-cookie"
                            placeholder="OTP"
                            v-model="seller.secret_otp"
                            v-if="scopes.includes(role)"
                            label="OTP"
                >
                </base-input>
                <base-input alternative
                            prepend-icon="bx bx-cookie"
                            placeholder="EMAIL"
                            v-model="seller.email"
                            v-if="scopes.includes(role)"
                            label="EMAIL"
                >
                </base-input>
                <base-input alternative
                            prepend-icon="bx bx-cookie"
                            placeholder="PASSWORD"
                            v-model="seller.password"
                            v-if="scopes.includes(role)"
                            label="PASSWORD"
                >
                </base-input>

                <base-input alternative
                            name="Keywords"
                            prepend-icon="ni ni-single-02"
                            placeholder="Keywords"
                            v-model="seller.keywords"
                            label="Keywords"
                >
                </base-input>
                <base-input alternative
                            name="Notes"
                            prepend-icon="bx bx-notepad"
                            placeholder="Notes"
                            v-model="seller.note"
                            label="Notes"
                >
                </base-input>
                <b-row>
                  <b-col xl="3">
                    <vs-select
                        multiple
                        placeholder="products"
                        v-model="seller.product"
                    >
                      <vs-option :label="p.label" :value="p.label" v-for="(p, pid) in products" :key="pid">
                        {{ p.text }}
                      </vs-option>
                    </vs-select>
                  </b-col>
                  <b-col xl="2" v-if="scopes.includes(role)">
                    <vs-select
                        placeholder="Status"
                        v-model="seller.status"
                    >
                      <vs-option label="active" value="active">
                        active
                      </vs-option>
                      <vs-option label="closed" value="closed">
                        closed
                      </vs-option>
                    </vs-select>
                  </b-col>
                  <b-col v-if="scopes.includes(role)" xl="2">
                    <vs-select
                        placeholder="Not profile"
                        v-model="seller.profile_name"
                    >
                      <vs-option :label="p" :value="p" v-for="(p, id) in profiles" :key="id">
                        {{ p }}
                      </vs-option>
                    </vs-select>
                  </b-col>
                  <b-col v-if="scopes.includes(role)" xl="3">
                    <vs-select
                        placeholder="Not employee"
                        v-model="seller.employee_name"
                    >
                      <vs-option :label="e.name" :value="e.name" v-for="(e, eid) in employees" :key="eid">
                        {{ e.name }}
                      </vs-option>
                    </vs-select>
                  </b-col>
                  <b-col xl="2">
                    <vs-switch v-model="seller.is_hijack" color="#2dce89">
                      <template #off>
                        Normal
                      </template>
                      <template #on>
                        Hijack
                      </template>
                    </vs-switch>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col xl="4">
                    <vs-button @click="updateSeller" transparent>
                      Oke
                    </vs-button>
                  </b-col>
                  <b-col xl="3">
                    <vs-button @click="sactive = false" transparent>
                      Cancel
                    </vs-button>
                  </b-col>
                </b-row>
<!--              </b-form>-->
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </vs-dialog>
</template>

<script>
import Card from "@/components/Cards/Card";
import fsg from "@/api/base";
import {get_role} from "@/util";

export default {
  name: "edit-seller",
  components: {
    Card,
  },
  data: () => ({
    sactive: false,
    role: get_role(),
    scopes: ['admin'],
    profiles: [],
    profile_name: '',
    products: [],
    employees: []
  }),
  props: {
    show: Boolean,
    seller: Object
  },
  watch: {
    show: function (){
      this.sactive = true
    },
    seller_name: function (){
      this.seller['profile_name'] = this.profile_name
    }
  },
  methods: {
    updateSeller: function (){
      this.sactive = !this.sactive
      fsg.put(`/sellers`, this.seller)
          .then(resp => {this.$vs.notification({
            title: `Sent task update seller ${this.seller.name}`,
            text: resp.data.message,
            color: "success"
          })})
          .catch(error => {this.$vs.notification({
            title: `Sent task update seller ${this.seller.name}`,
            text: error.response.data.message,
            color: "danger"
          })})
    },
  },
  created() {
    fsg.get("/profile")
      .then(resp => {
        this.profiles = resp.data.message
      })
    fsg.get('/products?scope=all')
        .then(resp => {
          this.products = resp.data.message
        })
    fsg.get('/employees')
      .then(r => {this.employees = r.data.message})
  },
}
</script>

<style scoped>
.justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
  width: 100%;
  margin-left: 0px;
}
.fix-col {
  padding-right: -15px;
  margin-right: 0px;
}
</style>