<template>
  <b-container fluid="xl" class="mt--6">
    <div>
      <audio id="ringSuccess">
        <source src="audio/success.mp3" type="audio/mpeg">
      </audio>
      <audio id="ringError">
        <source src="audio/error.mp3" type="audio/mpeg">
      </audio>
    </div>
    <b-row>
      <b-col xl="12" class="order-xl-2 mb-5">
        <card>
          <b-row align-v="center" slot="header" >
            <b-col cols="10">
              <h3 class="mb-0">Redbubble</h3>
            </b-col>
          </b-row>

          <b-form @submit.prevent="crawl">
            <h6 class="heading-small text-muted mb-4">Keywords</h6>

            <div class="pl-lg-4">
              <b-row>
              </b-row>
              <b-row >
                <b-col lg="12">
                  <base-input
                    type="text"
                    label="Token"
                    placeholder="9Mockup token"
                    v-model="keywords.token"
                    id="token"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col lg="3">
                  <base-select @change="selectedSearch" required id="search_type" :options="search_type" textlabel="Select search"></base-select>
                </b-col>
                <b-col lg="3">
                  <product @change="selectedProduct" :selected="keywords.product" id="product" textlabel="products"></product>
                </b-col>
                <b-col lg="3">
                  <base-select @change="selectedDimension" :selected="keywords.dimension" id="dimension" :options="dimention" textlabel="Dimension"></base-select>
                </b-col>
                <b-col lg="2">
                  <base-input
                    label="Number page"
                    type="text"
                    placeholder="Number page"
                    id="numberPage"
                    v-model="keywords.numberPage"
                    required
                  >
                  </base-input>
                </b-col>
                <b-col>
                  <base-input
                    label="Start page"
                    type="text"
                    placeholder="Start page"
                    id="startPage"
                    v-model="keywords.startPage"
                    required
                  >
                  </base-input>

                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <base-input
                    type="text"
                    label="Keyword"
                    placeholder="Keyword do not contains specify character"
                    v-model="keywords.keyword"
                    id="keyword"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <!-- <b-col cols="6" class="text-left">
                  <div>
                    <label class="form-control-label">
                      Seller
                    </label>
                    <b-form-input required v-model="keywords.seller_name" list="sellerList"></b-form-input>
                    <b-form-datalist id="sellerList" :options="sellers"></b-form-datalist>
                  </div>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col lg="6">
                  <b-form-group label="Extend title" label-class="form-control-label" class="mb-0" label-for="about-form-textaria">
                    <base-input
                      type="text"
                      placeholder="Extend title. maximum 200"
                      id="extendTitle"
                      v-model="keywords.extendTitle"
                      :rules="{required: true}"
                    >
                    </base-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group label="Search term" label-class="form-control-label" class="mb-0" label-for="about-form-textaria">
                    <base-input
                      type="text"
                      placeholder="Search term 248"
                      id="searchTerm"
                      v-model="keywords.searchTerm"
                      :rules="{required: true}"
                    >
                    </base-input>
                  </b-form-group>
                </b-col>
                <b-col lg="1">
                  <vs-switch v-model="keywords.keep_all" />
                </b-col>
                <hr>
              </b-row>
            </div>
            <!-- <hr class="my-4">
            <h6 class="heading-small text-muted mb-4">Discovery</h6> -->

            <div class="pl-lg-4">
              <!-- <b-row>
                <b-col>
                  <base-input
                    type="text"
                    label="Brand name"
                    placeholder="Brand name"
                    id="brandName"
                    v-model="keywords.brandName"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col>
                  <base-input
                    type="text"
                    label="Price"
                    placeholder="Price. exp: 12.45"
                    id="price"
                    v-model="keywords.price"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col>
                  <base-input
                    type="text"
                    label="Fulfillment latency"
                    placeholder="Fulfillment latency number"
                    id="Fulfillment Latency"
                    v-model="keywords.fulfillment_latency"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col>
                  <label class="form-control-label">
                    Update delete
                  </label>
                  <b-form-select v-model="keywords.update_delete" :options="update_options"></b-form-select>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Product description"
                    placeholder="product description"
                    id="productDescription"
                    v-model="keywords.product_description"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Manufacturer"
                    placeholder="manufacturer"
                    id="manufacturer"
                    v-model="keywords.manufacturer"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Feed product type"
                    placeholder="Feed product type"
                    id="feedProductType"
                    v-model="keywords.feed_product_type"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Item type"
                    placeholder="Item type"
                    id="itemType"
                    v-model="keywords.item_type"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Merchant shipping group name"
                    placeholder="Item shipping group name"
                    id="merchantShippingGroupName"
                    v-model="keywords.merchant_shipping_group_name"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Recommended Browse Nodes"
                    placeholder="Recommended Browse Nodes"
                    id="recommendedBrowseNodes"
                    v-model="keywords.recommended_browse_nodes"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Bullet point1"
                    placeholder="Bullet point. maximum 250"
                    id="bulletPoint1"
                    v-model="keywords.bulletPoint1"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Bullet point2"
                    placeholder="Bullet point. maximum 250"
                    id="bulletPoint2"
                    v-model="keywords.bulletPoint2"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Bullet point3"
                    placeholder="Bullet point. maximum 250"
                    id="bulletPoint3"
                    v-model="keywords.bulletPoint3"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Bullet point4"
                    placeholder="Bullet point. maximum 250"
                    id="bulletPoint4"
                    v-model="keywords.bulletPoint4"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="12">
                  <base-input
                    type="text"
                    label="Bullet point5"
                    placeholder="Bullet point. maximum 250"
                    id="bulletPoint5"
                    v-model="keywords.bulletPoint5"
                    :rules="{required: true}"
                  >
                  </base-input>
                </b-col>
                <b-col cols="1">
                  <vs-switch v-model="showImage">
                    <template #on>
                      Show
                    </template>
                    <template #off>
                      Hide
                    </template>
                  </vs-switch>
                </b-col>
              </b-row> -->
            </div>

            <!-- <hr class="my-4">
            <h6 class="heading-small text-muted mb-4">Controll</h6> -->

            <div class="pl-lg-4">
              <b-row>
                <b-col cols="2">
                  <base-button icon native-type="submit" type="primary" :loading="isCrawling" size="xl">
                    <span class="btn-inner--icon"><i class="ni ni-send"></i></span>
                    <span class="btn-inner--text">Crawl</span>
                  </base-button>
                </b-col>
                <b-col lg="2">
                </b-col>
              </b-row>
            </div>
            <hr class="my-4">
          </b-form>
        </card>
      </b-col>
      <div v-if="readyDownload && showImage">
        <vs-dialog scroll full-screen v-model="readyDownload">
          <div>
            <b-row>
              <b-col xl="2">
                <base-button icon type="primary" @click="download">
                  <span class="btn-inner--icon"><i class="ni ni-cloud-download-95"></i></span>
                  <span class="btn-inner--text">Download</span>
                </base-button>
              </b-col>
              <b-col xl="2">
                <base-button icon type="danger" @click="select_all">
                  <span class="btn-inner--icon"><i class="ni ni-cloud-download-95"></i></span>
                  <span class="btn-inner--text">Phang hết</span>
                </base-button>
              </b-col>
              <b-col xl="8">
              </b-col>

            </b-row>
            <div>
              <div>
                <ImageView :crawl-data="data"
                           :main_url_key="main_url_key"
                           :title_key="title_key"
                           :item_sku_key="item_sku_key"
                           :indexs="indexs"
                           :product="keywords.product"
                           :keep_all="keywords.keep_all"
                           :token="keywords.token"
                           :seller_id="keywords.seller_id"
                           @removeProduct="removeProduct">
                </ImageView>
              </div>
              <vs-pagination color="success" v-model="page" :length="Math.floor(data.length/perpage) + 1"/>
            </div>
          </div>
        </vs-dialog>
        </div>
    </b-row>
  </b-container>
</template>
<script>
import Product from "@/components/Product";
import BaseSelect from "@/components/BaseSelect";
import ImageView from "@/views/ImageView";
import fsg from "@/api/base";
import XLSX from 'xlsx';

export default {
  data() {
    return {
      update_options: [
          { value: '', text: 'Please select an option' },
          { value: 'update', text: 'Update' },
          { value: 'delete', text: 'Delete' },
          { value: 'PartialUpdate', text: 'PartialUpdate' },
      ],
      works: [],
      page: 0,
      perpage: 24,
      data: [],
      indexs: [],
      readyDownload: false,
      showImage: true,
      isCrawling: false,
      header: [],
      main_url_key: '',
      searchType: '',
      title_key: '',
      item_sku_key: '',
      keywords: {
        keep_all: false,
        keyword: '',
        extendTitle: '',
        searchTerm: '',
        product: '',
        marketPlace: '',
        numberPage: '10',
        startPage: '1',
        dimension: '',
        brandName: '',
        price: '',
        bulletPoint1: '',
        bulletPoint2: '',
        bulletPoint3: '',
        bulletPoint4: '',
        bulletPoint5: '',
        seller_name: null,
        manufacturer: '',
        product_description: '',
        fulfillment_latency: '',
        feed_product_type: '',
        item_type: '',
        merchant_shipping_group_name: '',
        recommended_browse_nodes: '',
        token: '',
      },
      sellers: [],
      pages: [
        {value: 10, text: "10"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      search_type: [
        {
          label: "Top selling",
          options: [
            {value: 'top selling', text: "Top selling"},
          ]
        },
        {
          label: "Relevant",
          options: [
            {value: 'relevant', text: "relevant"},
          ]
        },
        {
          label: "Newest",
          options: [
            {value: 'newest', text: "newest"},
          ]
        },
        {
          label: "Trending",
          options: [
            {value: 'trending', text: "trending"},
          ]
        },
      ],
      marketplaces: [
        {
          label: "NA",
          options: [
            {value: 'US', text: "US"},
          ]
        },
        {
          label: "EU",
          options: [
            {value: 'UK', text: "UK"},
            // {value: 'DE', text: "DE"},
            // {value: 'FR', text: "FR"},
            // {value: 'IT', text: "IT"},
            // {value: 'ES', text: "ES"},
          ]
        }
      ],
      dimention: [
        {value: null, text: "select dimension"},
        {value: 'doc', text: "rectangle"},
        {value: 'vuong', text: "square"},
      ]
    }
  },
  components: {
    ImageView,
    Product,
    BaseSelect,
    XLSX,
  },
  methods: {
    select_all: function () {
      this.data.forEach(e => e.checked = !e.checked)
    },
    selectedSeller: function (val) {
      this.keywords.seller_name = val
    },
    selectedDimension: function (val) {
      this.keywords.dimension = val
    },
    selectedProduct: function (val) {
      this.keywords.product = val
      this.loadTemplate(val)
    },
    selectedSearch: function (val) {
      this.keywords.searchType = val
      this.loadTemplate(val)
    },
    selectedMarketplace: function (val) {
      this.keywords.marketPlace = val
    },
    updateTemplate: function () {
      const key = this.keywords.product + this.keywords.marketPlace
      const cached = {
        extendTitle: this.keywords.extendTitle,
        searchTerm: this.keywords.searchTerm,
        price: this.keywords.price,
        fulfillment_latency: this.keywords.fulfillment_latency,
        product_description: this.keywords.product_description,
        manufacturer: this.keywords.manufacturer,
        feed_product_type: this.keywords.feed_product_type,
        item_type: this.keywords.item_type,
        merchant_shipping_group_name: this.keywords.merchant_shipping_group_name,
        bulletPoint1: this.keywords.bulletPoint1,
        bulletPoint2: this.keywords.bulletPoint2,
        bulletPoint3: this.keywords.bulletPoint3,
        bulletPoint4: this.keywords.bulletPoint4,
        bulletPoint5: this.keywords.bulletPoint5,
      }
      localStorage.setItem(key, JSON.stringify(cached))
    },
    loadTemplate: function (product) {
      const key = this.keywords.product + this.keywords.marketPlace
      if(localStorage.getItem(key)){
        const cached = JSON.parse(localStorage.getItem(key))
        for(const [key, value] of Object.entries(cached)){
          this.keywords[key] = value
        }
      }
    },
    genFileName: function (){
      return this.keywords.seller_name + "--" + this.keywords.product + "--" + this.keywords.keyword + ".xlsm"
    },
    download: function (){
      this.data.forEach(e => {
        delete e.trademarks
        delete e.block_tags
        delete e.remove_tags
      });
      this.data = this.data.filter(e => e.checked)
      fsg.post(`/save_product?product=${this.keywords.product}`, {'asins': this.data, '9mockup-token': this.keywords.token})
        .then(r => {
          this.$vs.notification({
            title: "Saved product",
            color: "success"
          })
        })
      const hello = XLSX.utils.json_to_sheet(this.data, {header: this.header})
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
      XLSX.writeFile(wb, this.genFileName())
      this.data = []
    },
    saveData: function (resp){
      this.isCrawling = false
      this.ringSucess()
      this.page = 1
      this.data = resp.data.message
      this.data.forEach((e, i) => {
        if(this.works.includes(e.workId)){
          this.data[i].checked = true
        }
      })
      this.readyDownload = true
      // this.header = resp.data.header
      // this.main_url_key = resp.data.main_url_key
      // this.title_key = resp.data.title_key
      // this.item_sku_key = resp.data.item_sku_key
    },
    crawl: function () {
      if (this.keywords.redubble_url){
        fsg.post('/save_redbubble_product')
        .then(r => {
          this.notify('Save Redbubble Product', '')
          this.keywords.redubble_url = ''
      })
      }
      this.notify(`🤖 Downloading ${this.keywords.keyword} of ${this.keywords.product}`,
                  'Đang download, chờ xí nhé &#128536;\n&#128536;\n&#128536;\n')
      this.isCrawling = !this.isCrawling
      this.updateTemplate()
      fsg.post('/redbubble', this.keywords)
        .then(resp => (this.saveData(resp)))
        .catch(error => (this.notify(error, error.response.data.msg, 'botton-left', 'danger', 'error'),
                         this.isCrawling = !this.isCrawling))
    },
    removeProduct: function (index){
      this.loadTemplate()
      this.data.splice(index, 1)

      let cached = []
      const start = (this.page - 1) * this.perpage
      const end = this.page * this.perpage
      for (let i = start; i < end; i++) {
        cached.push(i)
      }
      this.indexs = cached
    },
    notify: function (title, text, position = 'botton-left', color = 'danger', status = 'normal') {
      const noti = this.$vs.notification({
        color,
        position,
        title: title,
        text: text,
        time: 30 * 1000,
        type: "gradient"
      })
      if (status == 'success'){
        this.ringring()
      }else if(status == "failed"){
        this.ringring()
      }
    },
    ringSucess: function (){
      let audio = document.getElementById('ringSuccess')
      audio.play()
    },
    ringError: function (){
      let audio = document.getElementById('ringError')
      audio.play()
    },
  },
  mounted() {
    fsg.get('/sellerinfo').then(resp => (this.sellers = resp.data.message))
  },
  watch: {
    page() {
      let cached = []
      const start = (this.page - 1) * this.perpage
      const end = this.page * this.perpage
      for (let i = start; i < end; i++) {
        cached.push(i)
      }
      this.indexs = cached
    }
  },
  created() {
    fsg.get('/redbubble')
    .then(r => {
      this.works = r.data.data
    })
  },
}
</script>
<style scoped lang="scss">
</style>
