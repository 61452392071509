<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style="min-height: 600px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top;">
    </base-header>
    <b-container fluid class="mt--3">
      <b-row>
        <b-col xl="12" class="order-xl-1">
          <product-form></product-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProductForm from './Redbubble/ProductFrom.vue';

export default {
  components: {
    ProductForm,
  }
}
</script>

<style scoped>

</style>
