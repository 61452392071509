<template>
  <div>
    <vs-dialog width="550px" not-center v-model="view">
        <template #header>
        </template>

        <template #footer>
        <div>
          <img style="width: 400px; height: auto; display: block; margin-left: auto; margin-right: auto" :src="url" alt="">
          <h3>{{ title }}</h3>
        </div>
        </template>
    </vs-dialog>
    <b-card>
      <products label="Select product" @change="filter_product" class="flex-item"></products>
      <hr>
      <b-container fluid>
          <div class="imgs-container">
            <div class='img-flex' v-for="(as, id) in asins" :key="id">
              <vs-card type="6">
                <template #title>
                  <h3>as.title</h3>
                </template>
                <template #img>
                  <img @click="view = !view, selected=as" style="width: 100px; height: auto" :src="as.thumbnail_url" alt="">
                </template>
                <template #interactions>
                </template>
              </vs-card>
            </div>
          </div>
      </b-container>
    </b-card>
  </div>

</template>

<script>
import fsg from "@/api/base";
import Products from "@/components/Products";
export default {
  components: {
    Products
  },
  name: "ImageView",
  data() {
    return {
        asins: [],
        all: [],
        type: 'month_ago',
        view: false,
        selected: null,
        url: '',
        title: ''
    }
  },
  methods: {
    filter_product: function (val) {
      if (val == 'all') {
        this.asins = this.all
      } else {
        this.asins = this.all.filter(e => e.type == val)
      }
    }
  },
  created(){
    fsg.get(`/ranking?key=${this.type}`)
      .then(r => {this.all = r.data.msg, this.asins = this.all})
  },
  watch: {
    selected: function(){
      fsg.get(`/ranking?sku=${this.selected.sku}`)
        .then(r => {this.url = r.data.url, this.title = r.data.title})
    },
    view: function(){
      this.url = ""
    }
  }
}
</script>
<style scoped>
.imgs-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.img-flex {
  padding: 20px;
}
</style>