<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 100px; height: 150px; background-size: cover; background-position: center top; background-color: #2dce89">
    </base-header>
    <b-container fluid class="mt--7">
      <b-row>
        <b-col xl="4">
          <b-card>
            <h3>Metadata</h3>
            <hr size="10">
            <b-row>
              <b-col xl="6">
                <vs-input v-model="sku" placeholder="Sku" />
              </b-col>
              <b-col xl="6">
                <vs-input v-model="AmazonOrderID" placeholder="AmazonOrderId" />
              </b-col>
              <b-col xl="6">
                <p>{{ metadata.title }}</p>
              </b-col>
              <b-col xl="12">
                <h3>size</h3>
                <p>{{ metadata.size }}</p>
              </b-col>
              <b-col xl="12">
                <h3>Designer</h3>
                <p>{{ metadata.employee }}</p>
              </b-col>
              <b-col xl="12">
                <h3>Original link</h3>
                <a :href="metadata.original" target="_blank">Click here</a>
              </b-col>
              <b-col xl="12">
                <h3>URL</h3>
                <p>{{ url }}</p>
              </b-col>
              <b-col xl="12">
                <h3>Note</h3>
                <vs-input @keypress.enter="update_note" v-model="metadata.note" placeholder="Note" />
              </b-col>
              <b-col xl="12">
                <h3>Options</h3>
                <vs-input @keypress.enter="update_options" v-model="options" placeholder="Options" />
              </b-col>
              <b-col xl="12">
                <h3>Sku</h3>
                <vs-input @keypress.enter="update_sku" v-model="sku_copy" placeholder="sku" />
              </b-col>
              <b-col>
                <vs-button
                    warn
                    gradient
                    :active="active"
                    @click="get_sku"
                >
                  view
                </vs-button>
              </b-col>
              <b-col>
                  <vs-button
                      warn
                      gradient
                      @click="download_image(url, sku)"
                  >
                    Download
                  </vs-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col xl="8">
          <b-card v-if="sku" class="flex-container">
            <div class="img-transparent">
              <img @contextmenu="handler($event)" id="img-preview" :src="url" :data-zoom-image="url" alt="" style="width: 100%; height: auto">
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import fsg from "@/api/base";
import axios from "axios";
export default {
  name: "ImagePreview",
  components: {
  },
  data: function (){
    return {
      sku: this.$route.query.sku,
      sku_copy: this.$route.query.sku,
      options: '',
      AmazonOrderID: this.$route.query.AmazonOrderID,
      url: '',
      active: false,
      metadata: {},
      images: []
    }
  },
  mounted: function () {
    if (this.sku && this.AmazonOrderID){
      fsg.get(`/designs?sku=${this.sku}&AmazonOrderId=${this.AmazonOrderID}`)
          .then(resp => {this.url = resp.data.msg.url, this.metadata = resp.data.msg})
    }else if (this.sku){
      fsg.get(`/designs?sku=${this.sku}`)
          .then(resp => {this.url = resp.data.msg.url, this.metadata = resp.data.msg})
    }
  },
  updated() {
    $("#img-preview").ezPlus({
      zoomType: 'lens',
      lensShape: 'round',
      lensSize: 600
    })
  },
  methods: {
      handler: function(e) {
      //do stuff
      e.preventDefault();
     },
    get_sku: function (){
      this.active = !this.active
      fsg.get(`/designs?sku=${this.sku}&AmazonOrderId=${this.AmazonOrderID}`)
        .then(resp => {this.url = resp.data.msg.url,
                       this.metadata = resp.data.msg,
                       $("#img-preview").ezPlus({
                         zoomType: 'lens',
                         lensShape: 'round',
                         lensSize: 600
                       })
        })
    },
    update_sku: function (){
      fsg.get(`/update_sku?sku=${this.sku}&AmazonOrderId=${this.AmazonOrderID}&new_sku=${this.sku_copy}`)
        .then(r => this.$vs.notification({
          title: `Updated sku`
        }))
    },
    update_options: function (){
      fsg.post(`/update_options?sku=${this.sku}&AmazonOrderId=${this.AmazonOrderID}`, {'options': this.options})
        .then(r => this.$vs.notification({
          title: `Updated`
        }))
    },
    update_note: function (){
      fsg.put(`/designs?sku=${this.sku}&note=${this.metadata.note}`)
        .then(r => this.$vs.notification({
          title: `Updated: ${this.metadata.note}`
        }))
    },
    download_image: function(url, fn) {
        if (url.includes('.jpeg')){
          fn = fn + '.jpeg'
        }else if (url.includes('.png')){
          fn = fn + '.png'
        }else if (url.includes('.jpg')){
          fn = fn + '.jpg'
        }
        fetch(url, {headers: {'Content-Type': 'image/jpeg', 'Access-Control-Allow-Origin': '*'}})
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = fn;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    },
  },
  watch: {
    // images: function () {
    //   this.images.forEach(obj => {this.download_image(obj.url, obj.name), this.notify_download(obj)})
    // },
  }
}
</script>

<style scoped>
.foo {
  margin-left: 300px !important;
}
.img-transparent {
  background-image: url("https://fsg-products.s3-ap-southeast-1.amazonaws.com/png-transparent-background-1-1.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
